import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';


class UserDetail extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,

    }
  }

	render() {
		return (
			<div className="right-ui-block">
      	<div className="scrollspy-example">
        	<div className="rm-content">
          	<div className="row menu-top-block">
            	<div className="col-sm-10 tl-block align-self-center"><h4 className="heading">User Detail</h4></div>
            </div>
          </div>
        </div>
      </div>

    );
	}
}




export default UserDetail;
