import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchBanners, deleteBanner, updateBannerStatus} from '../../../actions/banners';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';

class BannersList extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.page){
			queryParams.page = 1;
		}
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
		this.state = {
			params: queryParams,
			pageUrl: '/admin/banners/list',
			records: null,

		};
  }




	componentDidMount(){
		this.fetchBanners(this.state.params);
	}

	fetchBanners(params){
		this.setState({fetchingRecords: true});
    this.props.fetchBanners(params).then((response) => {
			this.setState({records: response.value.data.responseData});
      this.setState({fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.fetchBanners(params);
		}
	}

  deleteRecord(recordid){
    this.props.deleteBanner({id: recordid}).then((response) => {
      toast.success(response.value.data.message);
      this.fetchBanners(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.flash_message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	updateBannerStatus(bannerId, status){
    this.setState({fetchingRecords: true});
    this.props.updateBannerStatus({id: bannerId, status}).then((response) => {
      this.setState({fetchingRecords: false});
      this.fetchBanners(this.state.params);
			toast.success(response.value.data.message);
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	render() {
		const {records} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/banners/list">Banners</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Banners</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <Link to="/admin/banners/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Banner</Link>
            </div>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Title</th>
                  <th>Web Image</th>
									<th>Mobile Image</th>
									{/* <th>Product</th> */}
									<th>Status</th>
                  <th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.data && records.data.length > 0 &&
							records.data.map((obj, index) => {
                return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
										<td>{obj.title}</td>
                    <td>{(obj.webImage && obj.webImage.filePath)?<img style = {{width: "auto", height: "50px"}} src = {obj.webImage.filePath} />:""}</td>
										<td>{(obj.mobileImage && obj.mobileImage.filePath)?<img style = {{width: "auto", height: "50px"}} src = {obj.mobileImage.filePath} />:""}</td>
                    {/* <td className="nowrap">{obj.products.title}</td> */}
										<td>
											<label className="switch">
												<input type="checkbox" checked = {obj.status == 1?true:false} onChange = {() => this.updateBannerStatus(obj.id, obj.status == 1?0:1, index)} />
											  <span className="slider round"></span>
											</label>
										</td>
										<td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/banners/list/edit/"+obj.id} >Edit</Link></li>

													<li><Confirm className="confirmPopup"
                              onConfirm={() => this.deleteRecord(obj.id)}
                              body="Are you sure you want to delete this?"
                              confirmText="Confirm Delete"
                              title="Delete!">
                              <button className="dropdown-item">Delete</button>
                          </Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.data && this.state.records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			}
			</>

    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
    fetchBanners: (data) => {
      return dispatch(fetchBanners(data));
    },
    deleteBanner: (data) => {
      return dispatch(deleteBanner(data));
    },
		updateBannerStatus: (data) => {
			return dispatch(updateBannerStatus(data));
		}
  }
}


export default withRouter(connect(null, mapDispatchToProps)(BannersList));
