import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchAttributeGroups, deleteAttributeGroup} from '../../../actions/products';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';

class AttributeGroupsList extends Component {
	constructor(props){
    super(props);
		this.state = {
			records: null
		};
  }



	componentDidMount(){
		this.fetchAttributeGroups();
	}

	fetchAttributeGroups(){
		this.setState({fetchingRecords: true});
    this.props.fetchAttributeGroups().then((response) => {
			this.setState({records: response.value.data.responseData.data});
      this.setState({fetchingRecords: false});
		}).catch((error)=>{
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){

	}

  deleteRecord(recordid){
    this.props.deleteAttributeGroup({attributeGroupId: recordid}).then((response) => {
      this.fetchAttributeGroups();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	render() {
		const {records} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/product-management/attribute-groups/list">Attribute Groups</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Attribute Groups</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <Link to="/admin/product-management/attribute-groups/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Attribute Group</Link>
            </div>
          </div>
        </div>
        <div class="custom-table mt-0">
					<div class="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table class="table mb-0" >
							<thead>
								<tr>
									<th>S.No.</th>
									<th>ID</th>
									<th>Name</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.length > 0 &&
							records.map((obj, index) => {
								return(
									<tr key={obj.id}>
										<td>{(index + 1)}</td>
										<td>{obj.id}</td>

										<td>{obj.name}</td>

										<td>
											<span class="dropdown threeDots">
                        <a href="javascript://" class="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/product-management/attribute-groups/list/edit/"+obj.id} >Edit</Link></li>
													<li><Confirm className="confirmPopup"
                              onConfirm={() => this.deleteRecord(obj.id)}
                              body="Are you sure you want to delete this?"
                              confirmText="Confirm Delete"
                              title="Delete!">
                              <button className="dropdown-item">Delete</button>
                          </Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>

			</>

    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
    fetchAttributeGroups: (data) => {
      return dispatch(fetchAttributeGroups(data));
    },
    deleteAttributeGroup: (data) => {
      return dispatch(deleteAttributeGroup(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(AttributeGroupsList));
