import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderField from '../FormFields/renderField';
import {Redirect, Link, withRouter} from 'react-router-dom';
import { forgotPassword } from '../../actions/users';
import {toast} from 'react-toastify';
import { validateEmail} from '../../constants';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.email || values.email.trim() === '') {
    errors.email = 'Enter email';
    hasErrors = true;
  }else{
    var emailId = values.email.trim();
    if(!validateEmail(emailId)){
      errors.email = "Email format is not correct";
      hasErrors = true;
    }
  }

  return hasErrors && errors;
}


//submit login form
const submitForm = (values, dispatch, props) => {

  //dispatch(signInUserSuccess(dummyData));
  return dispatch(forgotPassword(values))
    .then((response) => {
        dispatch(reset("ForgotPasswordForm"));

        props.history.push('/resetpassword');
        toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }

      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  };




class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: (props.user && props.user.status)?  props.user.status : false
    }
  }

  componentDidMount(){
  }



  componentWillReceiveProps(nextProps){
    if(nextProps.user && nextProps.user.status && nextProps.user.status === 'authenticated'){
      this.setState({
        isLoggedIn: true
      });
    }
  }

  render() {
    if (this.state.isLoggedIn === true) {
      return <Redirect to={{
        pathname: '/',
        state: { from: this.props.location }
      }}/>
    }
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <div className="loginForm">
        <h4 className="mb-1">Forgot password?</h4>
        <p className="marginB42">Enter your email and we will send you instructions to reset your password.</p>
        <form onSubmit={handleSubmit(submitForm)} >
          <p className="text-left"></p>
            <Field
              name="email"
              component={renderField}
              type="text"
              label="Email"
              placeholder="Your email address"
            />
            <button type="submit" disabled={submitting} className={`${"btn btn-primary btn-block hvr-shadow"} ${submitting?'btn-loader':''}`}>Submit</button>
          </form>
          <div className="text-center marginT40">
          <Link to="/signin" className="btn-link">Sign in</Link>
        </div>
      </div>
    )
  }
}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm',
  validate
})(ForgotPasswordForm)

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}



export default withRouter(connect(mapStateToProps)(ForgotPasswordForm));
