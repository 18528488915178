import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';

import { fetchProductGallery, updateProductGallery } from '../../../actions/products';
import { uploadImage } from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';


class ProductsManageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      productId: props.match.params.productId ? props.match.params.productId : null,
      arrayLocalImages: [],
      docsToBeUploadedCount: 0,
      docsUploadedCount: 0
    }
    this.onDrop = this.onDrop.bind(this);
    this.myRef = React.createRef();
  }

  submitForm(values, dispatch, props) {

    values = Object.assign(values, { id: this.state.productId });

    return this.props.updateProductGallery(values).then((response) => {
      toast.success(response.value.data.message);
      this.props.history.push('/admin/product-management/products/list');
    }).catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message);
        if (error.response.data.errors) {
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount() {
    this.fetchProductGallery(this.state.productId);
  }



  fetchProductGallery(productId) {
    this.setState({ fetchingRecords: true });
    this.props.fetchProductGallery({ id: productId }).then((response) => {
      let gallery = (response.value.data.responseData.data && response.value.data.responseData.data.images) ? response.value.data.responseData.data.images : [];
      var imageIds = [];
      if (gallery && gallery.length > 0) {
        gallery.forEach((member, memberIndex) => {
          imageIds.push(member.id);
        })
      }
      this.setState({ fetchingRecords: false, arrayLocalImages: gallery });
      this.props.change('imageIds', imageIds);
    }).catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }



  componentWillReceiveProps(nextProps) {
  }

  onDrop(pictures) {
    console.log(pictures);
    var saved_images = this.props.imageIds ? this.props.imageIds : [];
    var objThis = this;

    for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var file_data = pictures[i];
      if (file_data && file_data.size > 10485760) { //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({ imageUploading: true });
      form_data.append("uploadfile", file_data);
      setTimeout(() => {
        this.setState({ docsToBeUploadedCount: this.state.docsToBeUploadedCount + 1 });
      }, 100);

      this.props.uploadImage(form_data, { upload_type: "product_gallery_image" }).then((response) => {
        this.setState({ docsUploadedCount: this.state.docsUploadedCount + 1 });
        var payload = response.value.data.responseData;
        var arrayLocalImages = this.state.arrayLocalImages ? this.state.arrayLocalImages : [];
        if (payload && payload.id) {
          saved_images.push(payload.id);
          arrayLocalImages.push(payload);
          this.setState({ arrayLocalImages: arrayLocalImages });
          this.props.change('imageIds', saved_images);
        }
      }).catch((error) => {
        this.setState({ docsUploadedCount: this.state.docsUploadedCount + 1 });
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
          toast.error("Network error!");
        } else {
          toast.error(error.message);
        }
      });

    }
    this.myRef.current.state.pictures = [];
    this.myRef.current.state.files = [];
  }


  updateImageArray(e, index) {
    if (e) {
      e.preventDefault();
    }
    var arrayLocalImages = this.state.arrayLocalImages;
    var updatedArrayImages = [];
    var updatedArrayImageIds = [];
    if (arrayLocalImages && arrayLocalImages.length > 0) {
      arrayLocalImages.forEach((member, memberIndex) => {
        if (memberIndex != index) {
          updatedArrayImages.push(member);
          updatedArrayImageIds.push(member.id);
        }
      })
    }

    this.setState({ arrayLocalImages: updatedArrayImages });
    this.props.change('imageIds', updatedArrayImageIds);

  }











  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1">
          <li className="breadcrumb-item"><Link to="/admin/product-management/products/list">Products</Link></li>
          <li className="breadcrumb-item active">Manage Image Gallery</li>
        </ol>
        <h5 className="h5 mb-4">Manage Image Gallery</h5>
        <section className="formBg" style={{ maxWidth: "850px" }}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">

              <div className=" col-lg-6">
                <ImageUploader
                  className={(this.state.docsUploadedCount != this.state.docsToBeUploadedCount) ? "loading" : ""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={false}
                  label=""
                  withIcon={false}
                  buttonText='Upload gallery images (800x800)'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
                  ref={this.myRef}
                />
              </div>
              <div className=" col-lg-12">
                <ul className="image-gallery">
                  {
                    this.state.arrayLocalImages && this.state.arrayLocalImages.length > 0 &&
                    this.state.arrayLocalImages.map((obj, index) => {
                      return (
                        <li key={index}>
                          <figure className="img"><img src={obj.filePath} alt="" /></figure>
                          <button className="close-btn" onClick={(e) => this.updateImageArray(e, index)}></button>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>

            </div>




            <div className="form-btn-group">
              <button onClick={(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
              <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProductGallery: (data, apiPath, method) => {
      return dispatch(updateProductGallery(data, apiPath, method));
    },
    fetchProductGallery: (data) => {
      return dispatch(fetchProductGallery(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    }

  }
}
ProductsManageGallery = reduxForm({
  form: 'ProductsManageGallery',
  //validate,
  //enableReinitialize: true
})(ProductsManageGallery);
const selector = formValueSelector('ProductsManageGallery');
const mapStatesToProps = (state, ownProps) => {
  const imageIds = selector(state, 'imageIds');
  return {
    imageIds: imageIds
  }
}

ProductsManageGallery = connect(mapStatesToProps, mapDispatchToProps)(ProductsManageGallery);



export default withRouter(ProductsManageGallery);
