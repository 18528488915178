import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_CURRENCY = 'FETCH_CURRENCY';
export const ADD_CURRENCY = 'ADD_CURRENCY';
export const DELETE_CURRENCY = 'DELETE_CURRENCY';


let token = null;
export function fetchCurrencies(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCurrencies`
  });
  return {
    type: FETCH_CURRENCIES,
    payload: request
  };
}

export function fetchCurrency(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCurrencyWithId`
  });
  return {
    type: FETCH_CURRENCY,
    payload: request
  };
}

export function addCurrency(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addCurrency";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_CURRENCY,
    payload: request
  };
}





export function deleteCurrency(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteCurrency`
  });
  return {
    type: DELETE_CURRENCY,
    payload: request
  };
}
