import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderCKEditor from '../../FormFields/renderCKEditor';
import renderTextarea from '../../FormFields/renderTextarea';
import {fetchCategoryTree, fetchAttributes} from '../../../actions/categories';
import {fetchCurrencies} from '../../../actions/currencies';
import queryString from 'query-string';
import {fetchProduct, addProduct, fetchPcComponents} from '../../../actions/products';
import {fetchAllBrands} from '../../../actions/brands';
import {uploadImage} from '../../../actions/common';
import {getCategoryLevelDashes, normalizeNumber, normalizeAmountField} from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter product name";
    hasErrors = true;
  }
  if (!values.weight || values.weight === '') {
    errors.weight = "Enter weight";
    hasErrors = true;
  }
  if (!values.descriptionHtml || values.descriptionHtml.trim() === '') {
    errors.descriptionHtml = "Enter product highlights";
    hasErrors = true;
  }
  if (!values.categoryId || values.categoryId === '') {
    errors.categoryId = "Select category";
    hasErrors = true;
  }
  if (!values.featuredImage || values.featuredImage === '') {
    errors.featuredImage = "Upload product featured image";
    hasErrors = true;
  }
  if (!values.brandId || values.brandId === '') {
    errors.brandId = "Select brand";
    hasErrors = true;
  }

  //base price validations
  if(!values.basePrice || !values.basePrice.length){
    errors.basePrice = {_error: 'Enter base price'};
    hasErrors = true;
  }else{
    const basePriceArrayErrors = [];
    values.basePrice.forEach((member, memberIndex) => {
      const basePriceErrors = {};
      if (!member || !member.value) {
        basePriceErrors.value = 'Required';
        basePriceArrayErrors[memberIndex] = basePriceErrors;
        hasErrors = true;
      }
    })
    if(basePriceArrayErrors.length) {
      errors.basePrice = basePriceArrayErrors
    }
  }

  if(values.attributeSet && values.attributeSet.length > 0){
    const attributeSetErrors = [];
    values.attributeSet.forEach((member, memberIndex) => {
      attributeSetErrors[memberIndex] = {data: []};
      const attributeErrors = {};
      member.data.forEach((childMember, childMemberIndex) => {
        if (!childMember || !childMember.value) {
          attributeErrors.value = 'Required';
          attributeSetErrors[memberIndex].data[childMemberIndex] = attributeErrors;
          hasErrors = true;
        }
        const costErrors = {};
        if(childMember && childMember.cost && childMember.cost.length > 0){
          var costErrorsArray = [];
          childMember.cost.forEach((costMember, costMemberIndex) => {
            if(!costMember || !costMember.value){
              costErrorsArray.push({value: 'Required'});
            }else{
              costErrorsArray.push({});
            }
          })
          if(costErrorsArray && costErrorsArray.length){
            /*console.log('lllllkb5', costErrorsArray);
            console.log('lllllkb6', attributeSetErrors[memberIndex]);
            attributeSetErrors[memberIndex].data[childMemberIndex] = Object.assign(attributeSetErrors[memberIndex].data[childMemberIndex], {cost: costErrorsArray});
            hasErrors = true;*/
          }
        }
      })
    })
    if(attributeSetErrors.length) {
      errors.attributeSet = attributeSetErrors
    }
  }
  return hasErrors && errors;
}

class ProductsAdd extends Component {
	constructor(props){
    super(props);
    var params = queryString.parse(props.location.search);
    this.state = {
      brands: [],
      dataLoaded: false,
      productId: props.match.params.productId?props.match.params.productId:null,
      arrayLocalImages: [],
      categoryTree: [],
      currencies: [],
      components: [],
      categoryId: params.cat_id?parseInt(params.cat_id):'',
    }
    this.onDrop = this.onDrop.bind(this);
    this.fetchAttributes = this.fetchAttributes.bind(this);
    this.renderAttributes = this.renderAttributes.bind(this);
    this.renderAttributePrices = this.renderAttributePrices.bind(this);
    this.renderAttributeData = this.renderAttributeData.bind(this);

  }

  submitForm(values, dispatch, props){

    //console.log('vvvvv', values); return;
    let apiPath = "/admin/addProducts";
    let method = "POST";
    if(this.state.productId){
      apiPath = "/admin/editCategory";
      method = "PATCH";
    }
    return this.props.addProduct(values, apiPath, method).then((response) => {
      /*if(this.state.productId){

      }else{
        this.props.reset();
        this.setState({arrayLocalImages: []});
      }*/
      this.props.history.push("/admin/product-management/products/list");

      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchCurrencies(this.state.params);
    this.fetchAllBrands();
    this.fetchCategoryTree();
    this.fetchPcComponents();
    if(this.state.categoryId){
      this.fetchAttributes({id:this.state.categoryId});
      this.props.change('categoryId', this.state.categoryId);
    }


  }

  fetchPcComponents(params){
    this.props.fetchPcComponents().then((response) => {
      let components = response.value.data.responseData;
      this.setState({components: components});

		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchCurrencies(params){
    this.props.fetchCurrencies().then((response) => {
      let currencies = response.value.data.responseData.data;
      this.setState({currencies: currencies});
			this.props.change('basePrice', currencies);
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchAllBrands(params){
    this.props.fetchAllBrands().then((response) => {
      let brands = response.value.data.responseData.data;
      this.setState({brands: brands});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  fetchCategoryTree(){
	  this.setState({fetchingRecords: true});
    this.props.fetchCategoryTree().then((response) => {
      let records = response.value.data.responseData;
      let categoryTree = [];
      if(records){
				for(var i=0; i < records.length; i++){
          categoryTree.push({id: records[i].id, categoryName: records[i].name, isDisabled: true});
          for(var k=0; k < records[i].children.length; k++){
            categoryTree.push({id: records[i].children[k].id, categoryName: records[i].children[k].name, isDisabled: false});
          }
        }
      }
      this.setState({categoryTree});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("uploadfile", file_data);
      this.props.uploadImage(form_data, {upload_type:"category_image"}).then((response) => {
        this.setState({imageUploading: false});
        var payload = response.value.data.responseData;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					var idArray = [payload.id];
          this.props.change('featuredImage', idArray[0]);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }

  fetchAttributes(obj){
    this.props.fetchAttributes({categoryId: obj.id}).then((response) => {
      let attributesData = response.value.data.responseData.data;
      for(var i = 0; i < attributesData.length; i++){
        var data = Object.assign({}, attributesData[i]);
        if(data.addOns == 1){
          data.cost = this.state.currencies;
        }
        attributesData[i].data = [data];
      }
      this.props.change('attributeSet', attributesData);

      /*for(var i = 0; i < attributesData.length; i++){
        if(attributesData[i].addOns == 1){
          attributesData[i].cost = this.state.currencies;
        }
        if(attributesData[i].isVariation == 1){
          attributesData[i].showAddButton = 'yes';
        }
      }
      this.props.change('attributeSet', attributesData);*/
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
  }

  renderPrices({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }) {

    return (<div>

      {
        fields.map((member, index) =>

          <div className='row' key={index}>
            <div className='col-md-6'>
              <Field name={`${member}.currencyCode`} readOnly = {true} component={renderField} label="Currency Code" />
            </div>
            <div className='col-md-6'>
              <Field name={`${member}.value`} component={renderField} label="Base Price" normalize={normalizeAmountField}/>
            </div>

          </div>

        )
      }


        {submitFailed && error && <label className="error">{error}</label>}

    </div>)
  }

  renderAttributePrices({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }) {

    return (<div>

      {
        fields.map((member, index) =>

          <div className='row' key={index}>
            <div className='col-md-6'>
              <Field name={`${member}.currencyCode`} readOnly = {true} component={renderField} label="Currency Code" />
            </div>
            <div className='col-md-6'>
              <Field name={`${member}.value`} component={renderField} label="Base Price" />
            </div>

          </div>

        )
      }


        {submitFailed && error && <label className="error">{error}</label>}

    </div>)
  }

  renderAttributes({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;

    return (
      <div>
      {
        fields.map((member, index) =>{
          var isVariation = fields.get(index).isVariation;
          console.log('kkkkkkl', index, fields.get(index).showAddButton);
          var addOns = fields.get(index).addOns;
          return(
            <fieldset key = {index}>
              <legend>{fields.get(index).name}</legend>
              <FieldArray component = {this.renderAttributeData} name={`${member}.data`} />
            </fieldset>
        )}

        )
      }

    </div>)
  }

  renderAttributeData({
    fields,
    meta: {
      touched,
      error,
      submitFailed
    }
  }){
    var objThis = this;

    return (
      <div>
      {
        fields.map((member, index) =>{
          var isVariation = fields.get(index).isVariation;
          var addOns = fields.get(index).addOns;
          return(
            <div className='row' key={index}>
              <div className='col-md-3'>
                <Field name={`${member}.name`} readOnly = {true} component={renderField} label="Name" />
              </div>
              <div className='col-md-9'>
                <Field name={`${member}.value`} component={renderField} label="Value" />
              </div>
              {
                fields.get(index).addOns == 1 && <div className='col-md-4'><FieldArray component={objThis.renderAttributePrices} name={`${member}.cost`}/></div>
              }
              {
                index > 0 && <div className='col-md-2'><div className="form-group"><button style = {{marginTop: "25px"}} type="button" className="btn btn-secondary hvr-shadow" onClick={() => fields.remove(index)}>Remove</button></div></div>
              }

            </div>
          )}
        )
      }
        {
          fields.get(0).isVariation == 1 && <button style = {{marginTop: "25px"}} type="button" className="btn btn-secondary hvr-shadow" onClick={() => fields.push(fields.get(0))}>Add</button>
        }
      </div>
    )
  }


  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1">
        <li className="breadcrumb-item"><Link to="/admin/products/list">Products</Link></li>
        <li className="breadcrumb-item active">{this.state.productId?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.productId?"Edit":"Add"} Product</h5>
        <section className="formBg" >
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
          <div className="row">
            <div className=" col-lg-7">
              <Field
                label="Product Category"
                name='categoryId'
                optionLabel='categoryName'
                optionValue='id'
                options={this.state.categoryTree}
                component={renderReactSelect}
                placeholder="Select"
                multi={false}
                parentCallback={this.fetchAttributes}
              />
              <Field
                name="name"
                component={renderField}
                type="text"
                label="Product Name"
              />
              <Field
                label="Product Brand"
                name='brandId'
                optionLabel='name'
                optionValue='id'
                options={this.state.brands}
                component={renderReactSelect}
                placeholder="Select"
                multi={false}

              />
              <Field
                label="Build PC Component"
                name='componentId'
                optionLabel='name'
                optionValue='id'
                options={this.state.components}
                component={renderReactSelect}
                placeholder="Select"
                multi={false}
                isClearable={true}

              />
              <Field
                name="wattage"
                component={renderField}
                type="text"
                label="Wattage"
                normalize={normalizeNumber}
              />
              <Field
                name="weight"
                component={renderField}
                type="text"
                label="Weight (KG)"
                normalize={normalizeAmountField}
              />
              <FieldArray component={this.renderPrices} name="basePrice"/>

            </div>
            <div className=" col-lg-5">
              <ImageUploader
								className={this.state.imageUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText='Add Featured Image (800x800)'
                onChange={this.onDrop}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="featuredImage"
                component={renderField}
                type="hidden"
                label=""
              />
              {
                this.state.arrayLocalImages.length > 0 &&
                this.state.arrayLocalImages.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                      <figure className="img"><img src={obj.filePath} alt=""/></figure>
  									</div>
                  )
                })
              }
            </div>

          </div>

          <div className="row">
            <div className="col-lg-12">
              <Field
                name="descriptionHtml"
                component={renderTextarea}
                label="Product Overview"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Field
                name="longDescriptionHtml"
                component={renderCKEditor}
                label="Description"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <strong>Attributes</strong>
              </div>

            </div>
          </div>
          <FieldArray component={this.renderAttributes} name="attributeSet"/>
          <div className="form-btn-group">
            <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
          </div>
        </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (data, apiPath, method) => {
      return dispatch(addProduct(data, apiPath, method));
    },
    fetchProduct: (data) => {
      return dispatch(fetchProduct(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    },
    fetchCategoryTree: (data) => {
      return dispatch(fetchCategoryTree(data));
    },
    fetchAttributes: (data) => {
      return dispatch(fetchAttributes(data));
    },
    fetchCurrencies: (data) => {
      return dispatch(fetchCurrencies(data));
    },
    fetchAllBrands: (data) => {
      return dispatch(fetchAllBrands(data));
    },
    fetchPcComponents: (data) => {
      return dispatch(fetchPcComponents(data));
    }

  }
}

ProductsAdd = connect(null, mapDispatchToProps)(ProductsAdd);

ProductsAdd = reduxForm({
  form: 'ProductsAdd',
  validate,
  //enableReinitialize: true
})(ProductsAdd);

export default withRouter(ProductsAdd);
