import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import { addBanner, fetchBanner } from '../../../actions/banners';
import { uploadImage } from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';
import { API_ROOT } from '../../../constants';

import { toast } from 'react-toastify';
import Select from 'react-select';

const promiseProductOptions = (inputValue) => {
  return (new Promise(resolve => {
    if (inputValue.length < 3) {
      resolve([]);
      return;
    }
    axios.get(`${API_ROOT}/admin/getProducts?name=${inputValue}`).then((response) => {
      resolve(response.data.responseData.data);
      console.log("_______________________________", resolve)
    })
  }))
}

const typeOptions = [{ title: 'Product', type: 'product' }, { title: 'Web URL', type: 'webUrl' }]


//Client side validation
function validate(values, type) {
  console.log('*******************', values)
  var errors = {};
  var hasErrors = false;
  if (!values.title || values.title.trim() === '') {
    errors.title = "Enter banner title";
    hasErrors = true;
  }

  // if (!values.type || values.type === '') {
  //   errors.type = "Select type";
  //   hasErrors = true;
  // }

  if (!values.productId || values.productId === '') {
    errors.productId = "Select product";
    hasErrors = true;
  }


  if (!values.webURL || values.webURL === '' || !values.webURL.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
    errors.webURL = "Enter valid web URL";
    hasErrors = true;
  }



  if (!values.webImage || values.webImage === '') {
    errors.webImage = "Upload banner image for web";
    hasErrors = true;
  }

  if (!values.mobileImage || values.mobileImage === '') {
    errors.mobileImage = "Upload banner image for app";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class BannersAdd extends Component {
  constructor(props) {
    super(props);
    var lang = localStorage.getItem('lang');
    if (!lang) {
      lang = 'en';
    }
    this.state = {
      dataLoaded: false,
      bannerId: props.match.params.bannerId ? props.match.params.bannerId : null,
      arrayLocalImages: [],
      arrayLocalImagesMobile: [],
      typeOptions: [{ title: 'Product', type: '1' }, { title: 'Web URL', type: '2' }],
      type: '1',
      webURLError: false
    }
    this.onDrop = this.onDrop.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.selectType = this.selectType.bind(this);
  }

  submitForm(values, dispatch, props) {
    values['type'] = this.state.type
    let apiPath = "/admin/addProductBanner";
    let method = "POST";
    if (this.state.bannerId) {
      apiPath = "/admin/editProductBanner";
      method = "PATCH";

    }
    console.log("values submitted____________", values)

    if (!this.state.webURLError) {
      return this.props.addBanner(values, apiPath, method).then((response) => {

        toast.success(response.value.data.message);
        window.history.back();
      }).catch(function (error) {
        if (error.response) {
          toast.error(error.response.data.message);
          if (error.response.data.errors) {
            throw new SubmissionError(error.response.data.errors);
          }
        } else if (error.request) {
          toast.error("Network error!");
        } else {
          toast.error(error.message);
        }
      });
    }
  }

  componentDidMount() {
    this.props.initialize({});
    if (this.state.bannerId) {
      this.fetchBanner(this.state.bannerId);
    }
  }

  fetchBanner(bannerId) {
    this.setState({ fetchingRecords: true });
    this.props.fetchBanner({ id: bannerId }).then((response) => {
      let bannerData = response.value.data.responseData.data;
      let formData = {};
      let arrayLocalImages = [];
      let arrayLocalImagesMobile = [];
      let selectedProduct = null;
      if (bannerData) {
        formData = {
          id: bannerData.id,
          title: bannerData.title,
          webImage: (bannerData.webImage && bannerData.webImage.id) ? bannerData.webImage.id : 0,
          mobileImage: (bannerData.mobileImage && bannerData.mobileImage.id) ? bannerData.mobileImage.id : 0,
          productId: bannerData.productId,
          type: bannerData.type,
          webURL: bannerData.webURL
        }
        if (bannerData.webImage && bannerData.webImage.id) {
          arrayLocalImages.push(bannerData.webImage);
        }
        if (bannerData.mobileImage && bannerData.mobileImage.id) {
          arrayLocalImagesMobile.push(bannerData.mobileImage);
        }
        if (bannerData && bannerData.products) {
          selectedProduct = bannerData.products;
        }
      }
      this.props.initialize(formData);
      this.setState({ fetchingRecords: false, arrayLocalImages, arrayLocalImagesMobile, selectedProduct, type: bannerData.type });
      //this.props.initialize(categoryData);
    }).catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }



  componentWillReceiveProps(nextProps) {
  }

  onDrop(picture, type) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
    var form_data = new FormData();
    var length = picture.length;
    var file_data = picture[length - 1];
    if (file_data && file_data.size > 10485760) { //10485760
      alert("Max file size allowed is 10MB");
      return;
    }

    this.setState({ [type]: true });
    form_data.append("uploadfile", file_data);
    this.props.uploadImage(form_data, { upload_type: "banner_image" }).then((response) => {
      this.setState({ [type]: false });
      var payload = response.value.data.responseData;
      var arrayLocalImages = [];
      if (payload && payload.id) {
        arrayLocalImages.push(payload);
        var idArray = [payload.id];
        if (type == 'webImageUploading') {
          this.setState({ arrayLocalImages: arrayLocalImages });
          this.props.change('webImage', idArray[0]);
        } else {
          this.setState({ arrayLocalImagesMobile: arrayLocalImages });
          this.props.change('mobileImage', idArray[0]);
        }

      }
    }).catch((error) => {
      this.setState({ [type]: false });
      if (error.response) {
        //toast.error(error.response.data.message);
      } else if (error.request) {
        //toast.error("Network error!");
      } else {
        //toast.error(error.message);
      }
    });

    //}
  }

  selectProduct(obj,) {
    let selectedProduct = obj;
    this.props.change("productId", obj.productId);
    this.setState({ selectedProduct });
  }

  selectType(event,) {
    let selectedType = { [event.target.name]: event.target.value };
    this.props.change("type", event.target.value);
    this.setState({ selectedType });
    this.setState({ [event.target.name]: event.target.value });
    console.log("tye______________", this.state)
  }

  isUrlValid = (userInput) => {
    console.log("url", userInput.target.value, userInput)
    var res = userInput.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    console.log("valid url___", res);
    if (res == null) {
      this.state.webURLError = true;
      this.setState({ webURLError: true })
    }
    else {
      this.state.webURLError = false;
      this.setState({ webURLError: false })
    }

  };

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    console.log("options_______________", promiseProductOptions, this.state)
    return (
      <>
        <div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/banners/list">Product Launch Banners</Link></li>
          <li className="breadcrumb-item active">{this.state.bannerId ? "Edit" : "Add"}</li>
        </ol>
        <h5 className="h5 mb-4">{this.state.bannerId ? "Edit" : "Add"} Product Launch Banner</h5>
        <section className="formBg" style={{ maxWidth: "850px" }}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-7">
                <Field
                  name="title"
                  component={renderField}
                  type="text"
                  label="Title"
                />

              </div>
            </div>

            <div className="row">

              <div className=" col-lg-7">
                <div className="form-group">
                  <label>Type</label>

                  <select className="form-control" name="type" value={this.state.type}
                    onChange={(event) => this.selectType(event)} style={{paddingLeft: '8px'}}>
                    <option value="1" selected>Product</option>
                    <option value="2">Web URL</option>
                  </select>

                  <Field
                    name="type"
                    component={renderField}
                    type="hidden"
                    label=""
                  />

                </div>

              </div>
              <div className=" col-lg-7">
                {
                  this.state.type == '1' ?
                    <div className="form-group">
                      <label>Search Product</label>

                      <AsyncSelect
                        getOptionLabel={opt => opt['title']}
                        getOptionValue={opt => opt['productId']}
                        value={this.state.defaultProduct}
                        loadOptions={promiseProductOptions}
                        onChange={(record) => this.selectProduct(record)}
                        placeholder="Search.."
                      />
                      <Field
                        name="productId"
                        component={renderField}
                        type="hidden"
                        label=""
                      />

                    </div>
                    :
                    <Field
                      name="webURL"
                      component={renderField}
                      type="text"
                      label="Web URL"
                    // onChange={(record) => this.isUrlValid(record)}
                    />

                }
                {
                  this.state.webURLError ? <span>Enter valid web URL</span> : null
                }


              </div>
            </div>
            {
              this.state.type == '1' ?
                <div className="row">
                  <div className=" col-lg-12">
                    <ul className="ol-items">
                      {
                        this.state.selectedProduct &&
                        <li><span className="itemName">{this.state.selectedProduct.name}</span></li>
                      }
                    </ul>
                  </div>
                </div> : null
            }
            <div className="row">
              <div className=" col-lg-7 marginB20">
                <ImageUploader
                  className={this.state.webImageUploading ? "loading" : ""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText={this.state.bannerId ? 'Update Web Banner Image (1400x250)' : 'Upload Web Banner Image (1400x250)'}
                  onChange={(e) => this.onDrop(e, 'webImageUploading')}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
                />
                <Field
                  name="webImage"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayLocalImages.length > 0 &&
                  this.state.arrayLocalImages.map((obj, index) => {
                    return (
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.filePath} alt="" /></figure>
                        {/*<span className="pro-img-del icon-close" onClick = {(e) => this.deleteLocalImage(e, obj.id)}></span>*/}
                      </div>
                    )
                  })
                }
              </div>
              <div className=" col-lg-7">
                <ImageUploader
                  className={this.state.mobileImageUploading ? "loading" : ""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText={this.state.bannerId ? 'Update App Banner Image (767x350)' : 'Upload App Banner Image (767x350)'}
                  onChange={(e) => this.onDrop(e, 'mobileImageUploading')}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
                />

                <Field
                  name="mobileImage"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayLocalImagesMobile.length > 0 &&
                  this.state.arrayLocalImagesMobile.map((obj, index) => {
                    return (
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.filePath} alt="" /></figure>
                        {/*<span className="pro-img-del icon-close" onClick = {(e) => this.deleteLocalImage(e, obj.id)}></span>*/}
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="form-btn-group">
              <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBanner: (data, apiPath, method) => {
      return dispatch(addBanner(data, apiPath, method));
    },
    fetchBanner: (data) => {
      return dispatch(fetchBanner(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    }
  }
}

BannersAdd = connect(null, mapDispatchToProps)(BannersAdd);

BannersAdd = reduxForm({
  form: 'BannersAdd',
  validate,
  //enableReinitialize: true
})(BannersAdd);

export default withRouter(BannersAdd);
