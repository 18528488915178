import axios from 'axios';

import { API_ROOT } from '../constants';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SUBMIT_CONTACT_US_FORM = 'SUBMIT_CONTACT_US_FORM';
export const FETCH_PUBLIC_PAGE = 'FETCH_PUBLIC_PAGE';
export const FETCH_CITIES = 'FETCH_CITIES';
export const UPDATE_CITY_STATUS = 'UPDATE_CITY_STATUS';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_PAGE_DETAIL = 'FETCH_PAGE_DETAIL';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const FETCH_PRICE_GRIDS = "FETCH_PRICE_GRIDS";
export const FETCH_PRICE_GRID = 'FETCH_PRICE_GRID';
export const ADD_PRICE_GRID = 'ADD_PRICE_GRID';
export const EDIT_PRICE_GRID = 'EDIT_PRICE_GRID';
export const DELETE_PRICE_GRID = 'DELETE_PRICE_GRID';
export const FETCH_DASHBOARD_ANALYTICS = 'FETCH_DASHBOARD_ANALYTICS';
export const FETCH_GRAPHICAL_ANALYTICS = 'FETCH_GRAPHICAL_ANALYTICS';
export const FETCH_GRAPHICAL_ANALYTICS_CONSIGNMENT_REPORT = 'FETCH_GRAPHICAL_ANALYTICS_CONSIGNMENT_REPORT';
export const FETCH_GRAPHICAL_ANALYTICS_STATE_WISE_REPORT = 'FETCH_GRAPHICAL_ANALYTICS_STATE_WISE_REPORT';

let token = null;

export function uploadImage(formValues, queryParams) {
  const request = axios({
    method: 'POST',
    params: queryParams,
    data: formValues,
    url: `${API_ROOT}/attachment/create`
  });
  return {
    type: UPLOAD_IMAGE,
    payload: request
  };
}

export function submitContactUsForm(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/contactUs`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: SUBMIT_CONTACT_US_FORM,
    payload: request
  };
}


export function updateSettings(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/updateSettings`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_SETTINGS,
    payload: request
  };
}

export function fetchSettings(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getSettings`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: FETCH_SETTINGS,
    payload: request
  };
}

export function fetchPublicPage(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/wp/wp-json/wp/v2/pages`
  });
  return {
    type: FETCH_PUBLIC_PAGE,
    payload: request
  };
}

export function fetchCities(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/listCities`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: FETCH_CITIES,
    payload: request
  };
}

export function updateCityStatus(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/updateCityStatus`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_CITY_STATUS,
    payload: request
  };
}


export function fetchTransactions(formValues){
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/transactions/list`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: FETCH_TRANSACTIONS,
    payload: request
  };
}

export function fetchPageDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/getPageDetail`
  });
  return {
    type: FETCH_PAGE_DETAIL,
    payload: request
  };
}

export function updatePage(formValues) {
  token = localStorage.getItem('jwtToken');
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/updatePageDetail`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return {
    type: UPDATE_PAGE,
    payload: request
  };
}

export function fetchPriceGrids(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/getPriceGrids`,

  });
  return {
    type: FETCH_PRICE_GRIDS,
    payload: request
  };
}

export function fetchPriceGrid(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/getPriceGridWithId`,
  });
  return {
    type: FETCH_PRICE_GRID,
    payload: request
  };
}

export function addPriceGrid(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/addPriceGrid`,
  });
  return {
    type: ADD_PRICE_GRID,
    payload: request
  };
}

export function editPriceGrid(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/editPriceGrid`,
  });
  return {
    type: EDIT_PRICE_GRID,
    payload: request
  };
}

export function deletePriceGrid(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deletePriceGrid`,
  });
  return {
    type: DELETE_PRICE_GRID,
    payload: request
  };
}

export function fetchDashboardAnalytics(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/getDashboardAnalytics`,
  });
  return {
    type: FETCH_DASHBOARD_ANALYTICS,
    payload: request
  };
}

export function fetchGraphicalAnalytics(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/getGraphicalAnalytics`,
  });
  return {
    type: FETCH_GRAPHICAL_ANALYTICS,
    payload: request
  };
}

export function fetchGraphicalAnalyticsConsignment(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getGraphicalAnalyticsConsignment`
  });
  return {
    type: FETCH_GRAPHICAL_ANALYTICS_CONSIGNMENT_REPORT,
    payload: request
  };
}

export function fetchGraphicalStateWiseAnalytics(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getGraphicalAnalyticsStateWise`
  });
  return {
    type: FETCH_GRAPHICAL_ANALYTICS_STATE_WISE_REPORT,
    payload: request
  };
}

export function fetchSalesPlatformAnalytics(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/salesPlatformAnalytics`
  });
  return {
    payload: request
  };
}
