import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_BRAND = 'FETCH_BRAND';
export const ADD_BRAND = 'ADD_BRAND';
export const FETCH_ALL_BRANDS = 'FETCH_ALL_BRANDS';

export const DELETE_BRAND = 'DELETE_BRAND';

export function fetchBrands(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getBrands`
  });
  return {
    type: FETCH_BRANDS,
    payload: request
  };
}



export function fetchBrand(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getBrandWithId`
  });
  return {
    type: FETCH_BRAND,
    payload: request
  };
}

export function addBrand(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addBrand";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_BRAND,
    payload: request
  };
}





export function deleteBrand(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteBrand`
  });
  return {
    type: DELETE_BRAND,
    payload: request
  };
}

export function fetchAllBrands(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getBrandListing`
  });
  return {
    type: FETCH_ALL_BRANDS,
    payload: request
  };
}

export function updateBrandFeaturedStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateBrandFeaturedStatus`
  });
  return {
    payload: request
  };
}
