import axios from 'axios';

import { API_ROOT } from '../constants';

export function fetchLiabilityReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getLiabilityReport`
  });
  return {
    payload: request
  };
}


export function fetchLogisticsReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getLogisticReport`
  });
  return {
    payload: request
  };
}

export function fetchPaymentsReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getPaymentReport`
  });
  return {
    payload: request
  };
}

export function fetchRevenueReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getRevenueReport`
  });
  return {
    payload: request
  };
}

export function fetchRevenueSummaryReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/revenueSummaryReport`
  });
  return {
    payload: request
  };
}

export function fetchProductPerformanceReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/mostPurchasedProducts`
  });
  return {
    payload: request
  };
}

export function fetchGoodsReturnedReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/mostReturnedProducts`
  });
  return {
    payload: request
  };
}

export function fetchStockPerformanceReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getStockPerformanceReport`
  });
  return {
    payload: request
  };
}

export function fetchConsignmentsReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getConsignmentReport`
  });
  return {
    payload: request
  };
}

export function fetchAbandonedCartsReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getAbandonCartReport`
  });
  return {
    payload: request
  };
}

export function fetchNewVsReturingUsersReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getNewVsReturningUser`
  });
  return {
    payload: request
  };
}

export function fetchTopKeywordsReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/mostSearchedKeywords`
  });
  return {
    payload: request
  };
}

export function fetchCustomerReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCustomerReport`
  });
  return {
    payload: request
  };
}

export function fetchSalesByCouponCodeReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCouponUsage`
  });
  return {
    payload: request
  };
}


export function fetchSalesByCouponUserReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCouponsPerUser`
  });
  return {
    payload: request
  };
}

export function fetchSalesByCustomerReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getSalesUserReport`
  });
  return {
    payload: request
  };
}

export function fetchSalesByProductReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/mostPurchasedProducts`
  });
  return {
    payload: request
  };
}

export function fetchSalesByCategoryReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/mostPurchasedCategories`
  });
  return {
    payload: request
  };
}

export function fetchSalesRegionReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getOrderStateWise`
  });
  return {
    payload: request
  };
}

export function fetchSalesReport(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getSalesReport`
  });
  return {
    payload: request
  };
}

export function fetchSalesByPlatform(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getSalesByPlatform`
  });
  return {
    payload: request
  };
}
