export const ROUTES = {
  INDEX: {
    URL: "/",
    PARENT: "Layout"
  },
  DASHBOARD: {
    URL: "/admin/dashboard",
    PARENT: "LeftNavigation"
  },
  USERS_LIST: {
    URL: "/admin/user-management/users/list",
    PARENT: "LeftNavigation"
  },
  USERS_CREATE: {
    URL: "/admin/user-management/users/list/create",
    PARENT: "LeftNavigation"
  },
  USERS_EDIT: {
    URL: "/admin/user-management/users/list/edit/:userId",
    PARENT: "LeftNavigation"
  },
  ROLES_LIST: {
    URL: "/admin/user-management/roles/list",
    PARENT: "LeftNavigation"
  },

  ROLES_CREATE: {
    URL: "/admin/user-management/roles/list/create",
    PARENT: "LeftNavigation"
  },
  ROLES_EDIT: {
    URL: "/admin/user-management/roles/list/edit/:roleId",
    PARENT: "LeftNavigation"
  },
  // CURRENCIES_LIST: {
  //   URL: "/admin/settings/currencies/list",
  //   PARENT: "LeftNavigation"
  // },
  // CURRENCIES_CREATE: {
  //   URL: "/admin/settings/currencies/list/create",
  //   PARENT: "LeftNavigation"
  // },
  CONFIG_SETTINGS: {
    URL: "/admin/manage-settings/configuration",
    PARENT: "LeftNavigation"
  },
  PRICE_GRID_SETTINGS: {
    URL: "/admin/manage-settings/price-grids/list",
    PARENT: "LeftNavigation"
  },
  PRICE_GRID_ADD: {
    URL: "/admin/manage-settings/price-grids/list/create",
    PARENT: "LeftNavigation"
  },
  PRICE_GRID_EDIT: {
    URL: "/admin/manage-settings/price-grids/list/edit/:id",
    PARENT: "LeftNavigation"
  },
  PAGE_SETTINGS: {
    URL: "/admin/manage-settings/page/:slug",
    PARENT: "LeftNavigation"
  },
  CURRENCIES_EDIT: {
    URL: "/admin/settings/currencies/list/edit/:currencyId",
    PARENT: "LeftNavigation"
  },
  CATEGORIES_LIST: {
    URL: "/admin/product-management/categories/list",
    PARENT: "LeftNavigation"
  },
  CATEGORIES_CREATE: {
    URL: "/admin/product-management/categories/list/create",
    PARENT: "LeftNavigation"
  },
  CATEGORIES_EDIT: {
    URL: "/admin/product-management/categories/list/edit/:categoryId",
    PARENT: "LeftNavigation"
  },

  CATEGORY_MANAGE_ATTRIBUTES: {
    URL: "/admin/product-management/categories/list/manageAttributes/:categoryId",
    PARENT: "LeftNavigation"
  },

  PRODUCTS_LIST: {
    URL: "/admin/product-management/products/list",
    PARENT: "LeftNavigation"
  },

  PRODUCTS_CREATE: {
    URL: "/admin/product-management/products/list/create",
    PARENT: "LeftNavigation"
  },

  PRODUCTS_EDIT: {
    URL: "/admin/product-management/products/list/edit/:productId",
    PARENT: "LeftNavigation"
  },

  PRODUCTS_VIEW: {
    URL: "/admin/product-management/products/list/view/:productId",
    PARENT: "LeftNavigation"
  },

  PRODUCTS_MANAGE_GALLERY: {
    URL: "/admin/product-management/products/list/manageGallery/:productId",
    PARENT: "LeftNavigation"
  },

  PRODUCTS_MANAGE_VIDEO: {
    URL: "/admin/product-management/products/list/manageVideo/:productId",
    PARENT: "LeftNavigation"
  },

  COMPATIBLE_PRODUCTS: {
    URL: "/admin/product-management/products/list/compatibleProducts/:productId",
    PARENT: "LeftNavigation"
  },

  BRANDS_LIST: {
    URL: "/admin/product-management/brands/list",
    PARENT: "LeftNavigation"
  },

  BRANDS_CREATE: {
    URL: "/admin/product-management/brands/list/create",
    PARENT: "LeftNavigation"
  },

  BRANDS_EDIT: {
    URL: "/admin/product-management/brands/list/edit/:brandId",
    PARENT: "LeftNavigation"
  },

  BANNERS_LIST: {
    URL: "/admin/banners/list",
    PARENT: "LeftNavigation"
  },

  ORDERS_LIST: {
    URL: "/admin/orders/list",
    PARENT: "LeftNavigation"
  },

  ORDER_DETAIL: {
    URL: "/admin/orders/list/detail/:orderId",
    PARENT: "LeftNavigation"
  },
  TRANSACTIONS_LIST: {
    URL: "/admin/transactions/list",
    PARENT: "LeftNavigation"
  },

  BANNERS_CREATE: {
    URL: "/admin/banners/list/create",
    PARENT: "LeftNavigation"
  },

  BANNERS_EDIT: {
    URL: "/admin/banners/list/edit/:bannerId",
    PARENT: "LeftNavigation"
  },

  ATTTRIBUTE_GROUPS_LIST: {
    URL: "/admin/product-management/attribute-groups/list",
    PARENT: "LeftNavigation"
  },

  ATTTRIBUTE_GROUPS_CREATE: {
    URL: "/admin/product-management/attribute-groups/list/create",
    PARENT: "LeftNavigation"
  },

  ATTTRIBUTE_GROUPS_EDIT: {
    URL: "/admin/product-management/attribute-groups/list/edit/:attributeGroupId",
    PARENT: "LeftNavigation"
  },

  COUPONS_LIST: {
    URL: "/admin/coupons/list",
    PARENT: "LeftNavigation"
  },
  COUPONS_LIST_EDIT: {
    URL: "/admin/coupons/list/edit/:couponRuleId",
    PARENT: "LeftNavigation"
  },

  COUPONS_LIST_COUPON_CODES: {
    URL: "/admin/coupons/list/couponCodes/:couponRuleId",
    PARENT: "LeftNavigation"
  },

  COUPONS_CREATE: {
    URL: "/admin/coupons/list/create",
    PARENT: "LeftNavigation"
  },

  COUPON_RULE_DETAIL: {
    URL: "/admin/coupons/list/detail/:couponRuleId",
    PARENT: "LeftNavigation"
  },

  CHANGE_PASSWORD: {
    URL: "/admin/change-password",
    PARENT: "Layout"
  },

  QUESTIONS_LIST: {
    URL: "/admin/manage-question-answers/questions/list",
    PARENT: "LeftNavigation"
  },
  ANSWERS_LIST: {
    URL: "/admin/manage-question-answers/answers/list",
    PARENT: "LeftNavigation"
  },
  CUSTOMERS_LIST: {
    URL: "/admin/customers/list",
    PARENT: "LeftNavigation"
  },
  CUSTOMER_DETAIL: {
    URL: "/admin/customers/list/detail/:userId",
    PARENT: "LeftNavigation"
  },


  FINANCE_REPORTS_PAYMENTS_REPORT:{
    URL: "/admin/financeReports/paymentsReport/list",
    PARENT: "LeftNavigation"
  },
  FINANCE_REPORTS_LOGISTICS_REPORT:{
    URL: "/admin/financeReports/logisticsReport/list",
    PARENT: "LeftNavigation"
  },
  FINANCE_REPORTS_REVENUE_REPORT:{
    URL: "/admin/financeReports/revenueReport/list",
    PARENT: "LeftNavigation"
  },
  FINANCE_REPORTS_LIABILITY_REPORT:{
    URL: "/admin/financeReports/liabilityReport/list",
    PARENT: "LeftNavigation"
  },


  OPERATIONS_REPORTS_PRODUCT_PERFORMANCE:{
    URL: "/admin/operationReports/productPerformance/list",
    PARENT: "LeftNavigation"
  },
  OPERATIONS_REPORTS_GOODS_RETURNED:{
    URL: "/admin/operationReports/goodsReturned/list",
    PARENT: "LeftNavigation"
  },
  OPERATIONS_REPORTS_STOCK_PERFORMANCE:{
    URL: "/admin/operationReports/stockPerformance/list",
    PARENT: "LeftNavigation"
  },
  OPERATIONS_REPORTS_CONSIGNMENT:{
    URL: "/admin/operationReports/consignments/list",
    PARENT: "LeftNavigation"
  },

  SALES_REPORTS_SALES:{
    URL: "/admin/salesReports/salesReport/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_REGION:{
    URL: "/admin/salesReports/salesRegionReport/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_BY_PRODUCT:{
    URL: "/admin/salesReports/salesByProduct/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_BY_CATEGORY:{
    URL: "/admin/salesReports/salesByCategory/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_BY_CUSTOMERS:{
    URL: "/admin/salesReports/salesByCustomer/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_BY_COUPON_CODE:{
    URL: "/admin/salesReports/salesByCouponCode/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_BY_COUPON_CUSTOMER:{
    URL: "/admin/salesReports/salesByCouponUser/list",
    PARENT: "LeftNavigation"
  },
  SALES_REPORTS_SALES_BY_PLATFORM:{
    URL: "/admin/salesReports/salesByPlatform/list",
    PARENT: "LeftNavigation"
  },

  MARKETING_REPORTS_CUSTOMERS:{
    URL: "/admin/marketingReports/customerReports/list",
    PARENT: "LeftNavigation"
  },
  MARKETING_REPORTS_TOP_KEYWORDS:{
    URL: "/admin/marketingReports/topKeywords/list",
    PARENT: "LeftNavigation"
  },
  MARKETING_REPORTS_NEW_VS_RETURNING_USERS:{
    URL: "/admin/marketingReports/newVsReturingUsers/list",
    PARENT: "LeftNavigation"
  },
  MARKETING_REPORTS_ABANDONED_CARTS:{
    URL: "/admin/marketingReports/abandonedCarts/list",
    PARENT: "LeftNavigation"
  },

  /*REPORTS_COUPON_USAGE_LIST: {
    URL: "/admin/reports/couponUsage/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_USER_COUPON_USAGE_LIST: {
    URL: "/admin/reports/userCouponUsage/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_MOST_PURCHASED_PRODUCTS_LIST: {
    URL: "/admin/reports/mostPurchasedProducts/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_MOST_SEARCHED_PRODUCTS_LIST: {
    URL: "/admin/reports/mostSearchedProducts/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_MOST_RETURNED_PRODUCTS_LIST: {
    URL: "/admin/reports/mostReturnedProducts/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_MOST_VIEWED_PRODUCTS_LIST: {
    URL: "/admin/reports/mostViewedProducts/list",
    PARENT: "LeftNavigation"
  },
  REPORTS_ORDERS_STATE_WISE_LIST: {
    URL: "/admin/reports/stateWiseOrders/list",
    PARENT: "LeftNavigation"
  },*/
  MERCHANT_PRODUCTS_LIST: {
    URL: "/merchant/products/list/",
    PARENT: "LeftNavigation"
  },
  MERCHANT_PRODUCTS_VIEW: {
    URL: "/merchant/products/list/view/:productId",
    PARENT: "LeftNavigation"
  },
  MERCHANT_UPLOADED_FILES_LIST: {
    URL: "/merchant/csv/list/",
    PARENT: "LeftNavigation"
  },
  MERCHANT_UPLOAD_CSV_FILE: {
    URL: "/merchant/csv/upload",
    PARENT: "LeftNavigation"
  },
  LOGOUT: {
    URL:"/logout",
    PARENT: "Layout"
  },
};
