import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset, formValueSelector } from 'redux-form';
import {connect} from 'react-redux';
import renderField from '../../FormFields/renderField';
import {createShipment} from '../../../actions/orders';
import {toast} from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {normalizeAmountField} from '../../../constants';


//Client side validation
function validate(values, props) {

}



class LogisticsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.openPopup,
      data: props.data
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.initialize({consignmentId: this.state.data.obj.consignmentId});
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.closePopup();
  }



  submitForm(values, dispatch, props){

    let packageData = [
      {
        cl_PkgId: "",
        cl_CRDt: "",
        cl_DimH: "",
        cl_DimL: "",
        cl_DimW: "",
        cl_GoodsDesc: "",
        cl_GoodsValue: "",
        cl_PCs: "",
        cl_ActWeight: "",
        cl_Weight: ""
      }
    ]
    values = Object.assign(values, {packageData, isCod: this.state.data.orderData.orderStatus == 4?1:0});



    this.setState({fetchingRecords: true});
    return this.props.createShipment(values).then((response) => {

      this.setState({fetchingRecords: false});
      this.props.closePopup();
      toast.success(response.value.data.message);

    }).catch(function(error){
      if (error.response) {
        throw new SubmissionError(error.response.data.error);
        toast.error(error.response.data.flash_message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

  componentWillReceiveProps(nextProps){

  }



  render() {
    console.log('vvvvvvvv', this.state.data);
    const {handleSubmit, pristine, submitting} = this.props;
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="largePopup">
        <ModalHeader toggle={this.toggle} charCode="&times;">Create Shipment</ModalHeader>
        <div className="container">
          <div className="card">
            <div className="card-body card-padding">
              <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
                <ModalBody>
                  <div className="row">
                    <div className="col-lg-12">
                      <Field
                        name="awbNo"
                        component={renderField}
                        type="text"
                        label="AWB No. (If already generated otherwise leave blank)"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-6 marginB20" style  ={{textAlign: 'right'}}>
                      <strong>Or</strong>
                    </div>
                  </div>
                  <div className="row">
                    {
                      this.state.data.orderData.orderStatus == 4 &&
                      <div className="col-sm-6 col-lg-6">
                        <Field
                          name="codAmount"
                          component={renderField}
                          type="text"
                          label="COD Amount"
                        />
                      </div>
                    }

                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="cl_NoofPieces"
                        component={renderField}
                        type="text"
                        label="No of pieces"
                      />
                    </div>

                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="cl_Weight"
                        component={renderField}
                        type="text"
                        label="Weight"
                      />
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="cl_ActWeight"
                        component={renderField}
                        type="text"
                        label="Actual Weight"
                      />
                    </div>

                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="cl_Dimension"
                        component={renderField}
                        type="text"
                        label="Dimensions"
                      />
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="cl_GoodsDesc"
                        component={renderField}
                        type="text"
                        label="Goods Description"
                      />
                    </div>

                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="cl_ValueOfShipment"
                        component={renderField}
                        type="text"
                        label="Value of shipment"
                      />
                    </div>


                  </div>

                  <div className="form-group">
                    <div className="col-md-4 col-md-offset-4">
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="form-btn-group marginT0">
                    <button className="btn btn-secondary hvr-shadow" onClick={this.toggle}>Cancel</button>
                    <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting}>Submit</button>
                  </div>
                </ModalFooter>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    createShipment: (data) => {
      return dispatch(createShipment(data));
    }

  }
}

LogisticsPopup = reduxForm({
  form: 'LogisticsPopup',
  //validate
})(LogisticsPopup)



export default connect(null, mapDispatchToProps)(LogisticsPopup);
