import React from 'react';
import ReactDOM from 'react-dom';
import { Provider  } from 'react-redux';
import App from './pages/App';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import translations from './translations'
import { IntlProvider } from 'react-redux-multilingual';
import 'react-confirm-alert/src/react-confirm-alert.css';


ReactDOM.render(
  <Provider store = {store}>
    <IntlProvider translations={translations} locale='en'>
    	<PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </IntlProvider>
  </Provider>
  , document.getElementById('root'));
