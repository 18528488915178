import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchBrands, deleteBrand, updateBrandFeaturedStatus} from '../../../actions/brands';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';

class BrandsList extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.page){
			queryParams.page = 1;
		}
		this.state = {
			params: queryParams,
			records: null
		};
  }



	componentDidMount(){
		this.fetchBrands(this.state.params);
	}

	fetchBrands(params){
		this.setState({fetchingRecords: true});
    this.props.fetchBrands(params).then((response) => {
			this.setState({records: response.value.data.responseData});
      this.setState({fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.fetchBrands(params);
		}
	}

  deleteRecord(recordid){
    this.props.deleteBrand({id: recordid}).then((response) => {
      toast.success(response.value.data.message);
      this.fetchBrands(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.flash_message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

	updateBrandFeaturedStatus(brandId, status, index){
		var records = this.state.records;
		this.props.updateBrandFeaturedStatus({id: brandId, status}).then((response) => {
			records.data[index].featuredBrand = status;
			this.setState({records: records});
      toast.success(response.value.data.message);
    }).catch((error)=>{
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
	}

	render() {
		const {records} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/brands/list">Brands</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Brands</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <Link to="/admin/product-management/brands/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Brand</Link>
            </div>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
                  <th>Image</th>
									<th>Web Image</th>
									<th>App Image</th>
									<th>Name</th>
									<th>Featured?</th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.data && records.data.length > 0 &&
							records.data.map((obj, index) => {
                return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
                    <td>{(obj.attachment && obj.attachment.filePath)?<img style = {{width: "auto", height: "50px"}} src = {obj.attachment.filePath} />:""}</td>
										<td>{obj.name}</td>
										<td>{(obj.attachmentBannerImageWeb && obj.attachmentBannerImageWeb.filePath)?<img style = {{width: "100px", height: "auto"}} src = {obj.attachmentBannerImageWeb.filePath} />:""}</td>
										<td>{(obj.attachmentBannerImageApp && obj.attachmentBannerImageApp.filePath)?<img style = {{width: "100px", height: "auto"}} src = {obj.attachmentBannerImageApp.filePath} />:""}</td>
										<td><label className="switch">
											<input type="checkbox" checked = {obj.featuredBrand?true:false} onChange = {() => this.updateBrandFeaturedStatus(obj.id, obj.featuredBrand?0:1, index)} />
										  <span className="slider round"></span>
										</label></td>
										<td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link className="dropdown-item" title="Edit" to = {"/admin/product-management/brands/list/edit/"+obj.id} >Edit</Link></li>
													<li><Confirm className="confirmPopup"
                              onConfirm={() => this.deleteRecord(obj.id)}
                              body="Are you sure you want to delete this?"
                              confirmText="Confirm Delete"
                              title="Delete!">
                              <button className="dropdown-item">Delete</button>
                          </Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.data && this.state.records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			}
			</>

    );
	}
}



const mapDispatchToProps = (dispatch) => {
  return {
    fetchBrands: (data) => {
      return dispatch(fetchBrands(data));
    },
    deleteBrand: (data) => {
      return dispatch(deleteBrand(data));
    },
		updateBrandFeaturedStatus: (data) => {
			return dispatch(updateBrandFeaturedStatus(data));
		}
  }
}


export default withRouter(connect(null, mapDispatchToProps)(BrandsList));
