import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const FETCH_CATEGORY_TREE = 'FETCH_CATEGORY_TREE';
export const FETCH_ATTRIBUTES = 'FETCH_ATTRIBUTES';
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const UPDATE_CATEGORY_FEATURED_STATUS = 'UPDATE_CATEGORY_FEATURED_STATUS';
export const UPDATE_CATEGORY_STATUS = 'UPDATE_CATEGORY_STATUS';
export const FETCH_PC_COMPONENTS = 'FETCH_PC_COMPONENTS';

export function fetchCategories(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCategories`
  });
  return {
    type: FETCH_CATEGORIES,
    payload: request
  };
}

export function fetchCategoryTree(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCategoryTree`
  });
  return {
    type: FETCH_CATEGORY_TREE,
    payload: request
  };
}

export function fetchCategory(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCategoryWithId`
  });
  return {
    type: FETCH_CATEGORY,
    payload: request
  };
}

export function addCategory(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addCategory";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_CATEGORY,
    payload: request
  };
}





export function deleteCategory(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteCategory`
  });
  return {
    type: DELETE_CATEGORY,
    payload: request
  };
}


export function fetchAttributes(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getAttributes`
  });
  return {
    type: FETCH_ATTRIBUTES,
    payload: request
  };
}

export function addAttribute(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addAttribute";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_ATTRIBUTE,
    payload: request
  };
}

export function deleteAttribute(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteAttribute`
  });
  return {
    type: DELETE_ATTRIBUTE,
    payload: request
  };
}

export function updateCategoryFeaturedStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateCategoryFeaturedStatus`
  });
  return {
    type: UPDATE_CATEGORY_FEATURED_STATUS,
    payload: request
  };
}

export function updateCategoryStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateCategoryDisplayStatus`
  });
  return {
    type: UPDATE_CATEGORY_STATUS,
    payload: request
  };
}

export function fetchPcComponents(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getPcComponents`
  });
  return {
    type: FETCH_PC_COMPONENTS,
    payload: request
  };
}

export function productsFileUpload(file) {
  console.log(file)
  var fileData = new FormData();
  fileData.append('uploadfile', file);
  console.log("diledata___________", fileData)
  return axios({
    method: 'POST',
    data: fileData,
    headers: { "Content-Type": "multipart/form-data" },
    url: `${API_ROOT}/coupon/excelToJson`,
  });
}
