import React, {Component} from 'react';
import {Field} from "redux-form";
import PropTypes from 'prop-types';

export default class CheckboxGroup2 extends Component {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      //label: PropTypes.string.isRequired,
      //value: PropTypes.string.isRequired
    })).isRequired
  };

  field = ({input, meta, options, id_field, label_field}) => {
    if(!id_field){
      id_field = 'id';
    }
    if(!label_field){
      label_field = 'title';
    }
    const {name, onChange} = input;
    const {initial, touched, error, invalid, warning} = meta;
    const inputValue = input.value;

    console.log('kkkkkinput', inputValue);

    const checkboxes = options.map((obj, index) => {
      const handleChange = (event) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(obj[id_field]);
        } else {
          arr.splice(arr.indexOf(obj[id_field]), 1);
        }
        return onChange(arr);
      };
      const checked = inputValue.includes(obj[id_field]);
      return (
        <li key={index}>
          <div className="checkbox" key={`checkbox-${index}`}>
            <label>
              <input id={"checkbox_"+name+index} type="checkbox" name={`${name}[${index}]`} value={obj[id_field]} checked={checked} onChange={handleChange} />
              <span> {obj[label_field]}</span>
            </label>
            {/*<label htmlFor={"checkbox_"+name+index}>{title}</label>*/}
          </div>
        </li>
      );
    });

    return (
      <div>
        <ul className="inline-checboxes">
          {checkboxes}
        </ul>
        {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
      </div>
    );
  };

  render() {
    return <Field  {...this.props} type="checkbox"  component={this.field} />;
  }
}
