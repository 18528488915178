import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_COUPONS = 'FETCH_COUPONS';
export const FETCH_COUPON = 'FETCH_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';
export const ADD_COUPON = 'ADD_COUPON';
export const UPDATE_COUPON_PUBLISH_STATUS = 'UPDATE_COUPON_PUBLISH_STATUS';
export const FETCH_COUPON_RULE_DETAIL = 'FETCH_COUPON_RULE_DETAIL';
export const FETCH_COUPON_CODES = 'FETCH_COUPON_CODES';
export const DELETE_COUPON_RULE = 'DELETE_COUPON_RULE';
export const UPDATE_COUPON_POSITION = 'UPDATE_COUPON_POSITION';
export const UPDATE_COUPON_RULE_DETAILS = 'UPDATE_COUPON_RULE_DETAILS';


export function fetchCoupons(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCouponRuleLists`
  });
  return {
    type: FETCH_COUPONS,
    payload: request
  };
}



export function deleteCouponRule(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteCouponRule`
  });
  return {
    type: DELETE_COUPON_RULE,
    payload: request
  };
}

export function addCoupon(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/createCoupon`
  });
  return {
    type: ADD_COUPON,
    payload: request
  };
}

export function updateCouponPublishStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/activateDeactivateRuleStatus`
  });
  return {
    type: UPDATE_COUPON_PUBLISH_STATUS,
    payload: request
  };
}

export function fetchCouponRuleDetail(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/getCouponRuleDetail`
  });
  return {
    type: FETCH_COUPON_RULE_DETAIL,
    payload: request
  };
}

export function fetchCouponCodes(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCouponListByRuleId`
  });
  return {
    type: FETCH_COUPON_CODES,
    payload: request
  };
}

export function updateCouponPosition(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updatePromotionPosition`
  });
  return {
    type: UPDATE_COUPON_POSITION,
    payload: request
  };
}

export function updateCouponRuleDetails(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/updateCouponRuleDetails`
  });
  return {
    type: UPDATE_COUPON_RULE_DETAILS,
    payload: request
  };
}
