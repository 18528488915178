import React, {Component} from 'react';
import {Route, Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {COUPON_DISCOUNT_TYPES, COUPON_TYPES, COUPON_STATUS, normalizeCouponField, normalizeAmountField, normalizeNumber, WEEK_DAYS, COUPON_FOR, COUPON_FOR_WITHOUT_CART, COUPON_CODE_FORMATS} from '../../../constants';
import moment from 'moment';
import {addCoupon} from '../../../actions/coupons';
import {toast} from 'react-toastify';




class CouponPreviewPopup extends Component {
  constructor(props) {
    super(props);
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
    this.state = {
      lang: lang,
      couponData: props.couponData,
      modal: props.openPopup,
      couponDiscountTypes: COUPON_DISCOUNT_TYPES[lang],
      couponTypes: COUPON_TYPES[lang],
      couponStatus: COUPON_STATUS[lang],
      weekDays: WEEK_DAYS[lang],
      couponFor: COUPON_FOR[lang],
      couponCodeFormats: COUPON_CODE_FORMATS[lang],
      arrayLocalImages: props.arrayLocalImages,
      arrayLocalImagesWeb: props.arrayLocalImagesWeb

    };
    this.toggle = this.toggle.bind(this);
  }


  toggle(){
    this.setState({
      modal: !this.state.modal
    });
    this.props.closePopup();
  }

  componentDidMount(){

  }

  getCouponDiscountTypes(option){
    for(var i = 0; i < this.state.couponDiscountTypes.length; i++){
      if(this.state.couponDiscountTypes[i].id == option){
        return this.state.couponDiscountTypes[i].name;
      }
    }
	}

  getCouponTypeOption(option){
    for(var i = 0; i < this.state.couponTypes.length; i++){
      if(this.state.couponTypes[i].id == option){
        return this.state.couponTypes[i].name;
      }
    }
	}

  getCouponForOption(option){
    for(var i = 0; i < this.state.couponFor.length; i++){
      if(this.state.couponFor[i].id == option){
        return this.state.couponFor[i].name;
      }
    }
	}

  getWeekDaysOption(option){
    for(var i = 0; i < this.state.weekDays.length; i++){
      if(this.state.weekDays[i].id == option){
        return this.state.weekDays[i].name;
      }
    }
	}

  saveCoupon(e){
    if(e){
      e.preventDefault();
    }
    this.setState({submitting: true});
    let values = this.state.couponData;
    if(values.validFrom && typeof values.validFrom == 'object'){
      values = Object.assign(values, {validFrom: moment(values.validFrom).format("YYYY-MM-DD")});
    }
    if(values.validUpto && typeof values.validUpto == 'object'){
      values = Object.assign(values, {validUpto: moment(values.validUpto).format("YYYY-MM-DD")});
    }

    
    return this.props.addCoupon(values).then((response) => {
      this.setState({submitting: false});
      toast.success(response.value.data.message);
      this.toggle();
      this.props.closePopup();
      this.props.history.push('/admin/coupons/list');
      //window.history.back();
    }).catch((error)=>{
      this.setState({submitting: false});
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){

          //throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }





  render() {
    const {couponData} = {...this.state}
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="sof-ui-block coupon-preview-popup">
        <ModalHeader toggle={this.toggle} charCode="&times;">Coupon Preview</ModalHeader>
        <div className="modal-body">
          <div className="scope-heading">
          </div>
          <div className="card-content-ui">
            <section className="formBg">
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Name</strong></label>
                    <span className="data-span">{couponData.couponRuleName}</span>
                  </div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Coupon Type</strong></label><span className="data-span">{this.getCouponTypeOption(couponData.couponType)}</span></div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Publish Status</strong></label><span className="data-span">{couponData.couponStatus?'Active': 'Inactive'}</span></div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-12">
                  <div className="form-group"><label><strong>Description</strong></label><span className="data-span">{couponData.couponRuleDescription}</span></div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-12">
                  <div className="form-group"><label><strong>Coupon Validity</strong></label><span className="data-span">{moment(couponData.couponStartDate).format("DD MMM, Y")} {couponData.startTime} to {moment(couponData.couponEndDate).format("DD MMM, Y")} {couponData.endTime}</span></div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Coupon Valid for (Users)</strong></label><span className="data-span">{couponData.couponApplicablePlatform == 1?"All Users":couponData.couponApplicablePlatform == 2?"Website Users":couponData.couponApplicablePlatform == 3?"App Users":''}</span></div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Coupon Valid for Days</strong></label>
                    <span className="data-span">
                      {
                        couponData.couponApplicableDays == 1
                        ?
                        "All Days"
                        :
                        <>
                          {
                            couponData.couponWeekdays && couponData.couponWeekdays.length > 0 &&
                            couponData.couponWeekdays.map((obj, index) => {
                              return(
                                <span key = {index}>{index > 0?", "+this.getWeekDaysOption(obj):this.getWeekDaysOption(obj)}</span>
                              )
                            })
                          }
                        </>

                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Priority</strong></label><span className="data-span">{couponData.couponPriority}</span></div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Can be used with promotional offers?</strong></label><span className="data-span">{couponData.couponUsedWithOther?"Yes":"No"}</span></div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Coupon to be applied on</strong></label><span className="data-span">{this.getCouponForOption(couponData.couponFor)}</span></div>
                </div>
                <div className=" col-lg-8">
                  <div className="form-group">
                    <label><strong></strong></label>
                    <span className="data-span">
                      {
                        couponData.selectedItemIds && couponData.selectedItemIds.length > 0 &&
                        couponData.selectedItemIds.map((obj, index) => {
                          return(
                            <span key = {index}>{index > 0?", ":''}{obj.name}</span>
                          )
                        })
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Minimum Subtotal</strong></label><span className="data-span">{couponData.minimumSubTotal}</span></div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Discount Type</strong></label><span className="data-span">{this.getCouponDiscountTypes(couponData.couponDiscountType)}</span></div>
                </div>
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Discount</strong></label><span className="data-span">{couponData.couponDiscount}</span></div>
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-4">
                  <div className="form-group"><label><strong>Maximum Discount</strong></label><span className="data-span">{couponData.couponMaxDiscount}</span></div>
                </div>
              </div>
              <div className="row">
              {
                this.state.arrayLocalImages && this.state.arrayLocalImages.length > 0 &&
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>App Image</strong></label>
                    <img src = {this.state.arrayLocalImages[0].filePath} />
                  </div>
                </div>
              }
              {
                this.state.arrayLocalImagesWeb && this.state.arrayLocalImagesWeb.length > 0 &&
                <div className=" col-lg-4">
                  <div className="form-group">
                    <label><strong>Web Image</strong></label>
                    <img src = {this.state.arrayLocalImagesWeb[0].filePath} />
                  </div>
                </div>
              }
              </div>
              <div className="form-btn-group">
                <a href="javascript:;"
                  className={`${"btn btn-primary hvr-shadow"} ${this.state.submitting?'btn-loader':''}`}
                  disabled = {this.state.submitting?true:false}
                  onClick = {(e) => this.saveCoupon(e)}
                >
                  Save Coupon
                </a>
              </div>
            </section>
          </div>
        </div>
        <div className="pb-4">
          <div className="form-btn-group text-center mt-0">
            {/*<Link to={"/dispatches/assignDriver/"+this.state.dispatch_id} className="btn btn-primary hvr-shadow mr-2">Assign Driver</Link>
            <Link to={"/dispatches/workScopesList/"+this.state.dispatch_id} className="btn btn-secondary hvr-shadow ml-2">Assign Another Carrier</Link>*/}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {

  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCoupon: (data) => {
      return dispatch(addCoupon(data));
    }
  }
}




export default withRouter(connect(null, mapDispatchToProps)(CouponPreviewPopup));
