import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import { getBulkInventoryLogs} from '../../../actions/csv';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import $ from 'jquery';
import moment from 'moment';

class ManageCsvFiles extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		this.state = {
			params: queryParams,
			pageUrl: '/merchant/csv/list',
			records: null,
			filters: [
				
			]
			
		};
	}



	componentDidMount() {

		
		this.getBulkInventoryLogs(this.state.params);
		this.updateFilterForm(this.state.params);

	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}

	
	getBulkInventoryLogs(params) {
		this.setState({ fetchingRecords: true });
		this.props.getBulkInventoryLogs(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchProducts(params);
		}
	}

	submitFilterForm(values, dispatch, props) {
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}


	

	

	

	render() {
		const { records } = { ...this.state }
		return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/product-management/products/list?page=1">Manage Inventory</Link></li>
					<li className="breadcrumb-item active">Uploaded CSV files</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">Files</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
                        <Link to="/merchant/csv/upload" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Upload CSV File</Link>
						</div>
					</div>
				</div>
				
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>File Name</th>
									<th>Failed Products</th>
									<th>Uploaded At</th>
									
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={index}>
												<td className="nowrap"><a target = "_blank" href={obj.attachment.filePath}>{obj && obj.attachment.originalName?obj.attachment.originalName:'Download'}</a></td>
												<td>{obj.failedProducts}</td>
												<td>{moment(obj.createdAt).format("DD-MMM-Y")}</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
			</>

		);
	}
}

ManageCsvFiles = reduxForm({
	form: 'ManageCsvFilesFilter'
})(ManageCsvFiles);

const mapDispatchToProps = (dispatch) => {
	return {
		getBulkInventoryLogs: (data) => {
			return dispatch(getBulkInventoryLogs(data));
		}
	}
}


export default withRouter(connect(null, mapDispatchToProps)(ManageCsvFiles));
