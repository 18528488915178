import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addAttributeGroup, fetchAttributeGroup} from '../../../actions/products';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter attribute group name";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class AttributeGroupsAdd extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
      attributeGroupId: props.match.params.attributeGroupId?props.match.params.attributeGroupId:null
    }
  }

  submitForm(values, dispatch, props){
    let apiPath = "/admin/addAttributeGroup";
    let method = "POST";
    if(this.state.attributeGroupId){
      apiPath = "/admin/updateAttributeGroup";
      method = "PATCH";
    }
    return this.props.addAttributeGroup(values, apiPath, method).then((response) => {
      window.history.back()
      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.attributeGroupId){
      this.fetchAttributeGroup(this.state.attributeGroupId);
    }
  }

  fetchAttributeGroup(attributeGroupId){
		this.setState({fetchingRecords: true});
    this.props.fetchAttributeGroup({id: attributeGroupId}).then((response) => {
      let attributeGroupData = response.value.data.responseData.data;
      this.setState({fetchingRecords: false, attributeGroupId: attributeGroupId});
      this.props.initialize({attributeId: attributeGroupData.id, name: attributeGroupData.name});
    }).catch((error)=>{
      this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/product-management/attribute-groups/list">Attribute Groups</Link></li>
          <li className="breadcrumb-item active">{this.state.attributeGroupId?"Edit":"Add"}</li>
        </ol>
          <h5 className="h5 mb-4">{this.state.attributeGroupId?"Edit":"Add"} Attribute Group</h5>
          <section className="formBg" style={{maxWidth:"850px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-7">
                <Field
                  name="name"
                  component={renderField}
                  type="text"
                  label="Attribute Group Name"
                />
              </div>
            </div>
            <div className="form-btn-group">
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addAttributeGroup: (data, apiPath, method) => {
      return dispatch(addAttributeGroup(data, apiPath, method));
    },
    fetchAttributeGroup: (data) => {
      return dispatch(fetchAttributeGroup(data));
    }
  }
}

AttributeGroupsAdd = connect(null, mapDispatchToProps)(AttributeGroupsAdd);

AttributeGroupsAdd = reduxForm({
  form: 'AttributeGroupsAdd',
  validate,
})(AttributeGroupsAdd);

export default withRouter(AttributeGroupsAdd);
