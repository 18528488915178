import React, {Component}  from 'react';
import {withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

class RenderPagination extends Component {

  constructor(props) {
    super(props);
    this.state = {
      totalRecords: props.totalRecords,
      params: props.params,
      pageSize: props.pageSize,
      current: props.currentPage
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(page){
    var params = this.state.params;
    params = Object.assign(params, {page: page});
    this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
    this.setState({
      current: page,
    });
  }

  componentWillReceiveProps(nextProps){
    if(this.props.currentPage != nextProps.currentPage){
      if(nextProps.currentPage){
        this.setState({current: nextProps.currentPage});
      }else{
        this.setState({current: 1});
      }

    }
    if(this.props.totalRecords != nextProps.totalRecords){
      this.setState({totalRecords: nextProps.totalRecords});
    }
    if(this.props.params != nextProps.params){
      this.setState({params: nextProps.params});
    }
  }

  render() {

    return (
      <Pagination
        onChange={this.onChange}
        current={this.state.current}
        total={this.state.totalRecords}
        pageSize = {this.state.pageSize}
        
      />
    )
  }
}

export default withRouter(RenderPagination);
