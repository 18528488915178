import React, { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import renderTextarea from '../../FormFields/renderTextarea';
import { cancelWholeOrder, cancelConsignment } from '../../../actions/orders';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ORDER_CANCELLATION_REASONS } from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';


function validate(values) {
    var errors = {};
    var hasErrors = false;
    if (!values.cancelReasonId || values.cancelReasonId === '') {
        errors.cancelReasonId = "Select a reason for cancellation";
        hasErrors = true;
    } else if (values.cancelReasonId == 3 && (!values.cancelReasonDescription || values.cancelReasonDescription.trim() === '')) {
        errors.cancelReasonDescription = "Enter the reason";
        hasErrors = true;
    }
    return hasErrors && errors;
}



class CancelOrderPopup extends Component {
    constructor(props) {
        super(props);
        var lang = localStorage.getItem('lang');
        if (!lang) {
            lang = 'en';
        }
        this.state = {
            modal: props.openPopup,
            data: props.data,
            cancellationReasons: ORDER_CANCELLATION_REASONS[lang],
            type: props.type
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        console.log('ddddd', this.state.data);
        //this.props.initialize({ orderId: this.state.data.orderId });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
        this.props.closePopup();
    }



    submitForm(values, dispatch, props) {
        if(this.state.type == 'order'){
            values = Object.assign(values, {orderId: this.state.data.orderId, cancelPaymentMethod: 2});
        }else{
            values = Object.assign(values, {orderId: this.state.data.order_id, cancelPaymentMethod: 2, consignmentId: this.state.data.consignmentId});
        }
        
        console.log('vvvvvv', values);
        this.setState({ fetchingRecords: true });

        if(this.state.type == 'order'){
            return this.props.cancelWholeOrder(values).then((response) => {
                this.setState({ fetchingRecords: false });
                this.props.closePopup();
                toast.success(response.value.data.message);
            }).catch(function (error) {
                if (error.response) {
                    throw new SubmissionError(error.response.data.error);
                    toast.error(error.response.data.flash_message);
                } else if (error.request) {
                    toast.error("Network error!");
                } else {
                    toast.error(error.message);
                }
            });

        }else{
            return this.props.cancelConsignment(values).then((response) => {
                this.setState({ fetchingRecords: false });
                this.props.closePopup();
                toast.success(response.value.data.message);
            }).catch(function (error) {
                if (error.response) {
                    throw new SubmissionError(error.response.data.error);
                    toast.error(error.response.data.flash_message);
                } else if (error.request) {
                    toast.error("Network error!");
                } else {
                    toast.error(error.message);
                }
            });
        }
        
        
    }

    componentWillReceiveProps(nextProps) {

    }



    render() {
        const { handleSubmit, pristine, submitting } = this.props;
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="largePopup">
                <ModalHeader toggle={this.toggle} charCode="&times;">Cancel Order</ModalHeader>
                <div className="container">
                    <div className="">
                        <div className="card-body card-padding">
                            <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12">
                                            <Field
                                                label="Cancellation Reason"
                                                name='cancelReasonId'
                                                optionLabel='name'
                                                optionValue='id'
                                                options={this.state.cancellationReasons}
                                                component={renderReactSelect}
                                                placeholder="Select"
                                                multi={false}
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <Field
                                                name="cancelReasonDescription"
                                                component={renderTextarea}
                                                label="Explain Reason"
                                            />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <div className="form-btn-group marginT0">
                                        <button className="btn btn-secondary hvr-shadow" onClick={this.toggle}>Cancel</button>
                                        <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting}>Submit</button>
                                    </div>
                                </ModalFooter>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        cancelWholeOrder: (data) => {
            return dispatch(cancelWholeOrder(data));
        },
        cancelConsignment: (data) => {
            return dispatch(cancelConsignment(data));
        }

    }
}

CancelOrderPopup = reduxForm({
    form: 'CancelOrderPopup',
    validate
})(CancelOrderPopup)



export default connect(null, mapDispatchToProps)(CancelOrderPopup);
