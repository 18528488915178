import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderDatePicker from '../../FormFields/renderDatePicker';
import renderTimePicker from '../../FormFields/renderTimePicker';
import renderTextarea from '../../FormFields/renderTextarea';
import { addCoupon } from '../../../actions/coupons';
import { uploadImage } from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';
import { fetchAllBrands } from '../../../actions/brands';
import { fetchCategoryTree, productsFileUpload } from '../../../actions/categories';
import { COUPON_DISCOUNT_TYPES, COUPON_TYPES, COUPON_STATUS, normalizeCouponField, normalizeAmountField, normalizeNumber, WEEK_DAYS, COUPON_FOR, COUPON_FOR_WITHOUT_CART, COUPON_CODE_FORMATS } from '../../../constants';
import moment from 'moment';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';
import CouponPreviewPopup from './CouponPreviewPopup';

import { toast } from 'react-toastify';
import { API_ROOT } from '../../../constants';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.couponRuleName || values.couponRuleName.trim() === '') {
    errors.couponRuleName = "Enter rule name";
    hasErrors = true;
  }

  if (!values.couponRuleDescription || values.couponRuleDescription.trim() === '') {
    errors.couponRuleDescription = "Enter rule description";
    hasErrors = true;
  }

  if (!values.couponType) {
    errors.couponType = "Select coupon type";
    hasErrors = true;
  } else if (values.couponType == 1) {
    if (values.couponAutoGenerate && (!values.couponCodeLength || parseInt(values.couponCodeLength) < 4)) {
      errors.couponCodeLength = "Coupon code length must be atleast 4";
      hasErrors = true;
    }
  } else if (values.couponType == 3) {
    if (!values.couponCode || values.couponCode.trim() == "") {
      errors.couponCode = "Enter coupon code";
      hasErrors = true;
    }
  }

  if (!values.couponStatus) {
    errors.couponStatus = 'Select coupon status';
  }

  /*if (!values.validFrom || values.validFrom === '') {
    errors.validFrom = "Select date";
    hasErrors = true;
  }*/

  if (!values.couponStartDate || values.couponStartDate === '') {
    errors.couponStartDate = "Select coupon start date";
    hasErrors = true;
  } else if (values.couponEndDate && values.couponEndDate) {
    var diff = moment(values.couponStartDate).diff(values.couponEndDate, 'hours');
    if (diff > 0) {
      errors.couponEndDate = "Coupon end date can not be less than coupon start date";
      hasErrors = true;
    }
  }

  if (!values.couponEndDate || values.couponEndDate === '') {
    errors.couponEndDate = "Select coupon end date";
    hasErrors = true;
  }

  if (values.couponApplicableDays && values.couponApplicableDays === '2') {
    if (!values.couponWeekdays || values.couponWeekdays.length == 0) {
      errors.couponWeekdays = "Select a day or days";
      hasErrors = true;
    }

  }

  if (!values.couponFor || values.couponFor === '') {
    errors.couponFor = "Select on what, coupon will be applied";
    hasErrors = true;
  } else if (values.couponFor != 4) {
    if (!values.minimumSubTotal || values.minimumSubTotal == '') {
      errors.minimumSubTotal = "Enter minimum subtotal";
      hasErrors = true;
    }
  }

  if (values.couponFor && (values.couponFor == 2 || values.couponFor == 3 || values.couponFor == 4)) {
    if (!values.selectedItemIds || values.selectedItemIds.length <= 0) {
      errors.show_items_error = "Select atleast one";
      hasErrors = true;
    }
  }

  if (!values.couponDiscountType || values.couponDiscountType === '') {
    errors.couponDiscountType = "Select coupon discount type";
    hasErrors = true;
  }

  if (!values.couponDiscount || values.couponDiscount === '') {
    errors.couponDiscount = "Enter coupon discount";
    hasErrors = true;
  }

  if (!values.couponMaxDiscount || values.couponMaxDiscount === '') {
    errors.couponMaxDiscount = "Enter coupon max discount";
    hasErrors = true;
  } else if (isNaN(values.couponMaxDiscount)) {
    errors.couponMaxDiscount = "Enter a valid number";
    hasErrors = true;
  }
  return hasErrors && errors;
}

const promiseProductOptions = (inputValue) => {
  return (new Promise(resolve => {
    if (inputValue.length < 3) {
      resolve([]);
      return;
    }
    axios.get(`${API_ROOT}/admin/getProducts?name=${inputValue}`).then((response) => {
      resolve(response.data.responseData.data);
    })
  }))
}

class CouponsAdd extends Component {
  constructor(props) {
    super(props);
    var lang = localStorage.getItem('lang');
    if (!lang) {
      lang = 'en';
    }
    this.state = {
      lang: lang,
      dataLoaded: false,
      bannerId: props.match.params.bannerId ? props.match.params.bannerId : null,
      arrayLocalImages: [],
      arrayLocalImagesWeb: [],
      couponDiscountTypes: COUPON_DISCOUNT_TYPES[lang],
      couponTypes: COUPON_TYPES[lang],
      minDate: new Date(),
      couponStatus: COUPON_STATUS[lang],
      minValidUptoDate: new Date(),
      weekDays: WEEK_DAYS[lang],
      couponFor: COUPON_FOR[lang],
      couponCodeFormats: COUPON_CODE_FORMATS[lang],
      stateCouponType: props.stateCouponType || null,
      showManageCoupon: props.couponAutoGenerate || false,
      showDaysDropdown: (props.couponApplicableDays && props.couponApplicableDays == '2') || false,
      showMinimumSubtotalField: (props.couponFor && props.couponFor != 4) || false,
      categoryTree: [],
      brands: [],
      itemsArray: [],
      selectedItemIds: [],
      showItemsArraySelection: (props.couponFor && props.couponFor != 1) || false,
      showProductSearchField: (props.couponFor && props.couponFor == 4) || false,
      showProductAutoGenerateCheckbox: (props.stateCouponType && props.stateCouponType == 1) || false,
      openCouponPreviewPopup: false,
      couponData: null

    }
    this.updateMinValidUptoDate = this.updateMinValidUptoDate.bind(this);
    this.updateCoupontype = this.updateCoupontype.bind(this);
    this.updateCouponFor = this.updateCouponFor.bind(this);
    this.updateSelectedItems = this.updateSelectedItems.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.callbackStartTime = this.callbackStartTime.bind(this);
    this.callbackEndTime = this.callbackEndTime.bind(this);

    this.openCouponPreviewPopup = this.openCouponPreviewPopup.bind(this);
    this.closeCouponPreviewPopup = this.closeCouponPreviewPopup.bind(this);
  }

  openCouponPreviewPopup(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openCouponPreviewPopup: true });
  }
  closeCouponPreviewPopup() {
    this.setState({ openCouponPreviewPopup: false });
    //this.getQueryParams(this.state.queryParams);
  }

  onDrop(picture, type) {
    var objThis = this;
    var imageUploading = 'imageUploading' + type;
    //for (var i = 0; i < pictures.length; i++) {
    var form_data = new FormData();
    var length = picture.length;
    var file_data = picture[length - 1];
    if (file_data && file_data.size > 10485760) { //10485760
      alert("Max file size allowed is 10MB");
      return;
    }

    this.setState({ [imageUploading]: true });
    form_data.append("uploadfile", file_data);
    this.props.uploadImage(form_data, { upload_type: "promotion_image" }).then((response) => {
      this.setState({ [imageUploading]: false });
      var payload = response.value.data.responseData;
      var arrayLocalImages = [];
      var arrayLocalImagesWeb = [];
      if (payload && payload.id) {
        if (type == 'App') {
          arrayLocalImages.push(payload);
          this.setState({ arrayLocalImages: arrayLocalImages });
          var idArray = [payload.id];
          this.props.change('promotionImage', idArray[0]);
        } else {
          arrayLocalImagesWeb.push(payload);
          this.setState({ arrayLocalImagesWeb: arrayLocalImagesWeb });
          var idArray = [payload.id];
          this.props.change('promotionImageWeb', idArray[0]);
        }

      }
    }).catch((error) => {
      this.setState({ [imageUploading]: false });
      if (error.response) {
        //toast.error(error.response.data.message);
      } else if (error.request) {
        //toast.error("Network error!");
      } else {
        //toast.error(error.message);
      }
    });

    //}
  }


  submitForm(values, dispatch, props) {

    //console.log(values);

    this.setState({ couponData: values, openCouponPreviewPopup: true });


    return;
    /*return;
    if(values.validFrom && typeof values.validFrom == 'object'){
      values = Object.assign(values, {validFrom: moment(values.validFrom).format("YYYY-MM-DD")});
    }
    if(values.validUpto && typeof values.validUpto == 'object'){
      values = Object.assign(values, {validUpto: moment(values.validUpto).format("YYYY-MM-DD")});
    }
    //values = delete values.minimumSubtotal;


    return this.props.addCoupon(values).then((response) => {
      toast.success(response.value.data.message);
      window.history.back();
    }).catch((error)=>{
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });*/
  }

  componentDidMount() {
    this.fetchAllBrands();
    this.fetchCategoryTree();
    this.props.change("couponApplicableDays", this.props.couponApplicableDays || '1');
    this.props.change("couponApplicablePlatform", this.props.couponApplicablePlatform || '1');
  }

  fetchAllBrands(params) {
    this.props.fetchAllBrands().then((response) => {
      let brands = response.value.data.responseData.data;
      this.setState({ brands: brands });
    }).catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  fetchCategoryTree() {
    this.setState({ fetchingRecords: true });
    this.props.fetchCategoryTree().then((response) => {
      let records = response.value.data.responseData;
      let categoryTree = [];
      if (records) {
        for (var i = 0; i < records.length; i++) {
          categoryTree.push({ id: records[i].id, name: records[i].name, isDisabled: true });
          if (records[i].children && records[i].children.length > 0) {
            for (var k = 0; k < records[i].children.length; k++) {
              categoryTree.push({ id: records[i].children[k].id, name: records[i].children[k].name, isDisabled: false });
            }
          }
        }
      }
      this.setState({ categoryTree });
    }).catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
  }

  updateMinValidUptoDate(date) {
    this.setState({ minValidUptoDate: date });
  }

  updateCoupontype(obj) {
    let couponFor = COUPON_FOR[this.state.lang];
    var showManageCoupon = this.state.showManageCoupon;
    if (obj.id != 1) {
      showManageCoupon = false;
    }
    if (obj.id == 2) {
      couponFor = COUPON_FOR_WITHOUT_CART[this.state.lang];
    }
    this.setState({ stateCouponType: obj.id, showManageCoupon, couponFor });
  }

  showManageCoupon(e) {
    this.setState({ showManageCoupon: e.target.checked });
  }

  toggleDaysDropdown(e) {
    this.setState({ showDaysDropdown: (e.target.value == '2') ? true : false });
  }

  updateCouponFor(obj) {
    var itemsArray = [];
    if (obj.id == 2) {
      itemsArray = this.state.categoryTree;
    } else if (obj.id == 3) {
      itemsArray = this.state.brands;
    }
    this.setState({ showMinimumSubtotalField: obj.id != 4 ? true : false, itemsArray, selectedItemIds: [], showItemsArraySelection: obj.id != 1 ? true : false, showProductSearchField: obj.id == 4 ? true : false });
    this.props.change("selectedItemIds", []);
    this.props.change('items', null);
  }

  updateSelectedItems(obj, type) {
    if (type && type == "product") {
      obj = { id: obj.productId, name: obj.title }
    }
    var selectedItemIds = this.state.selectedItemIds;
    //Check if its not already in the array
    for (var i = 0; i < selectedItemIds.length; i++) {
      if (selectedItemIds[i].id == obj.id) {
        return;
      }
    }
    selectedItemIds.push(obj);
    this.props.change("selectedItemIds", selectedItemIds);
    this.setState({ selectedItemIds });
  }

  deleteItem(index) {
    var selectedItemIds = this.state.selectedItemIds;
    selectedItemIds.splice(index, 1);
    this.props.change("selectedItemIds", selectedItemIds.length <= 0 ? null : selectedItemIds);
    this.setState({ selectedItemIds });
  }
  callbackStartTime(val) {
    this.props.change('startTime', val);
  }
  callbackEndTime(val) {
    this.props.change('endTime', val);
  }

  seclectedFile(e) {
    this.setState({ documentLoader: true });
    console.log("file slected___________", e.target.files[0])
    if (e.target.files[0]) {
      return productsFileUpload(e.target.files[0]).then((response) => {
        console.log("file upload response_____________", response);
        // this.setState({ fileDetail: response.data.responseData[0] })
        // this.state.fileDetail = response.data.responseData[0];
        this.setState({ loader: false });

        var selectedItemIds = this.state.selectedItemIds;
        //Check if its not already in the array
        for (let data of response.data) {
          selectedItemIds.push({ productId: data.productId, name: data.name });
        }
        this.props.change("selectedItemIds", selectedItemIds);
        this.setState({ selectedItemIds });
      }).catch(error => {
        toast.error(error.message);
        this.setState({ documentLoader: false });
      });
    }

  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
        <div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/coupons/list">Promocodes</Link></li>
          <li className="breadcrumb-item active">Add</li>
        </ol>
        <h5 className="h5 mb-4">Add Promocode</h5>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
          <div className="job-info order-info subsection-form">
            <h6 className="h6 marginB0">Basic Information</h6>
            <section className="formBg">
              <div className="row">
                <div className=" col-lg-6">
                  <Field name="couponRuleName" component={renderField} type="text" label="Rule Name" />
                </div>
                <div className=" col-lg-6">
                  <Field name="couponRuleDescription" component={renderField} type="text" label="Rule Description" />
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-6">
                  <Field label="Coupon Type" name='couponType' parentCallback={this.updateCoupontype} optionLabel='name' optionValue='id' options={this.state.couponTypes} component={renderReactSelect} placeholder="Select" multi={false} />
                </div>
              </div>
              {
                this.state.stateCouponType && (this.state.stateCouponType == 1 || this.state.stateCouponType == 3) &&
                <>
                  <div className="row align-end">
                    <div className=" col-lg-6">
                      <Field name="couponCode" component={renderField} type="text" label="Coupon Code" />
                    </div>
                    <div className=" col-lg-6">
                      {
                        this.state.stateCouponType == 1 &&

                        <div className="form-group usecheck">
                          <label>
                            <Field onClick={(e) => this.showManageCoupon(e)} name="couponAutoGenerate" component="input" type="checkbox" />Use Auto Generation (if you select and save the rule you will be able to generate multiple coupon codes)
                          </label>
                        </div>
                      }
                    </div>
                  </div>
                  {
                    this.state.stateCouponType && (this.state.stateCouponType == 1 || this.state.stateCouponType == 3) &&
                    <div className="row">
                      <div className="col-lg-6">
                        <Field name="couponUsesPerUser" component={renderField} type="text" label="Uses Per User" normalize={normalizeNumber} />
                      </div>
                      <div className=" col-lg-6">
                        <Field name="couponUsesPerCoupon" component={renderField} type="text" label="Uses Per Coupon" normalize={normalizeNumber} />
                      </div>
                    </div>
                  }
                  {
                    this.state.showManageCoupon &&
                    <fieldset className="marginB20 manage-coupon-sec">
                      <legend>Manage Coupon</legend>
                      <div className="row">
                        <div className="col-lg-6">
                          <Field name="couponQuantity" component={renderField} type="text" label="Coupon Quantity" normalize={normalizeNumber} />
                        </div>
                        <div className="col-lg-6">
                          <Field name="couponCodeLength" component={renderField} type="text" label="Code Length (excluding prefix, suffix & separator)" normalize={normalizeNumber} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Field label="Code Format" name='couponCodeFormat' optionLabel='name' optionValue='id' options={this.state.couponCodeFormats} component={renderReactSelect} placeholder="Select" multi={false} />
                        </div>
                        <div className="col-lg-6">
                          <Field name="couponCodePrefix" component={renderField} type="text" label="Code Prefix" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Field name="couponCodeSuffix" component={renderField} type="text" label="Coupon Code Suffix" />
                        </div>
                        <div className="col-lg-6">
                          <Field name="couponCodeDash" component={renderField} type="text" label="Dash Every X Characters (if empty no separation)" normalize={normalizeNumber} />
                        </div>
                      </div>
                    </fieldset>
                  }
                </>
              }

              <div className="row">
                <div className=" col-lg-6">
                  <Field label="Status" name='couponStatus' optionLabel='name' optionValue='id' options={this.state.couponStatus} component={renderReactSelect} placeholder="Select" multi={false} />
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-6">
                  <Field name="couponStartDate" component={renderDatePicker} label="Start Date" minDate={this.state.minDate} callback={this.updateMinValidUptoDate} />
                </div>
                <div className=" col-lg-6">
                  <Field name="couponEndDate" component={renderDatePicker} label="End Date" minDate={this.state.minValidUptoDate} />
                </div>
              </div>

              <div className="couponradio">
                <div className="row">
                  <div className=" col-lg-2">
                    <label>
                      <Field name="couponApplicablePlatform" component="input" type="radio" value="1" /><span className="radio_label">All Users</span>
                    </label>
                  </div>
                  <div className=" col-lg-2">
                    <label>
                      <Field name="couponApplicablePlatform" component="input" type="radio" value="2" /><span className="radio_label">Website Users</span>
                    </label>
                  </div>
                  <div className=" col-lg-2">
                    <label>
                      <Field name="couponApplicablePlatform" component="input" type="radio" value="3" /><span className="radio_label">App Users</span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-2">
                    <label>
                      <Field name="couponApplicableDays" component="input" type="radio" value="1" onChange={(e) => this.toggleDaysDropdown(e)} /><span className="radio_label">All days</span>
                    </label>
                  </div>
                  <div className=" col-lg-2">
                    <label>
                      <Field name="couponApplicableDays" component="input" type="radio" value="2" onChange={(e) => this.toggleDaysDropdown(e)} /><span className="radio_label">Week days</span>
                    </label>
                  </div>
                </div>
              </div>
              {
                this.state.showDaysDropdown == true &&
                <div className="row">
                  <div className=" col-lg-6">
                    <Field label="Days" name='couponWeekdays' optionLabel='name' optionValue='id' options={this.state.weekDays} component={renderReactSelect} placeholder="Select" multi={true} />
                  </div>
                </div>
              }
              <div className="row">
                <div className=" col-lg-6">
                  <Field name="startTime" component={renderTimePicker} type="text" callback={this.callbackStartTime} label="Start Time" />
                </div>
                <div className=" col-lg-6">
                  <Field name="endTime" component={renderTimePicker} type="text" callback={this.callbackEndTime} label="End Time" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className=" col-lg-6">
                  <Field name="couponPriority" component={renderField} type="text" label="Priority" normalize={normalizeNumber} />
                </div>
                <div className=" col-lg-6">
                  <div className="form-group usecheck">
                    <label>
                      <Field name="couponUsedWithOther" component="input" type="checkbox" />
                      Can be used with promotional offers?
                    </label>
                  </div>
                </div>
              </div>
            </section>

          </div>
          <div className="job-info order-info subsection-form">
            <h6 className="h6 marginB0">Conditions</h6>
            <section className="formBg" >
              <div className="row">
                <div className=" col-lg-6">
                  <Field label="Coupon For" name='couponFor' parentCallback={this.updateCouponFor} optionLabel='name' optionValue='id' options={this.state.couponFor} component={renderReactSelect} placeholder="Select" multi={false} />
                  {
                    this.state.showMinimumSubtotalField == true &&
                    <Field name="minimumSubTotal" component={renderField} type="text" label="Minimum Subtotal" normalize={normalizeAmountField} />

                  }
                </div>

              </div>
              {
                this.state.showItemsArraySelection == true &&

                <>
                  <div className="row">
                    <div className=" col-lg-12">
                      {
                        this.state.showProductSearchField == true
                          ?
                          <>
                            <div className="row">
                              <div className=" col-lg-6">
                                <AsyncSelect
                                  getOptionLabel={opt => opt['title']}
                                  getOptionValue={opt => opt['productId']}
                                  value={this.state.defaultProduct}
                                  loadOptions={promiseProductOptions}
                                  onChange={(record) => this.updateSelectedItems(record, 'product')}
                                />
                              </div>
                              <div className=" col-lg-6">
                                {/* <span>or Attach file here</span> */}
                                <div className="upload-document">
                                  <input className="input-file" type="file" id="file" onChange={(e) => this.seclectedFile(e)}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                  <span>Upload document</span>
                                </div>
                              </div>
                            </div>
                          </>
                          :
                            <div className=" col-lg-6" style={{paddingLeft: '0'}}>
                              <Field label="Items" name='items' parentCallback={this.updateSelectedItems} optionLabel='name' optionValue='id' options={this.state.itemsArray} component={renderReactSelect} placeholder="Select" multi={false} />
                            </div>
                      }
                      <Field type="hidden" name="show_items_error" component={renderField} />

                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-lg-6">
                      <ol className="ol-items pt-3">
                        {
                          this.state.selectedItemIds && this.state.selectedItemIds.length > 0 &&
                          this.state.selectedItemIds.map((obj, index) => {
                            return (
                              <li key={obj.id}><span className="itemName">{obj.name}</span><span><button className="dlt-btn" onClick={() => this.deleteItem(index)}>
                                Delete
                              </button></span></li>
                            )
                          })
                        }
                      </ol>
                    </div>
                  </div>
                </>
              }
            </section>
          </div>
          <div className="job-info order-info subsection-form">
            <h6 className="h6 marginB0">Actions</h6>
            <section className="formBg">
              <div className="row">
                <div className=" col-lg-6">
                  <Field label="Discount Type" name='couponDiscountType' optionLabel='name' optionValue='id' options={this.state.couponDiscountTypes} component={renderReactSelect} placeholder="Select" multi={false} />
                </div>
                <div className=" col-lg-6">
                  <Field name="couponDiscount" component={renderField} type="text" label="Discount" normalize={normalizeAmountField} />
                </div>
              </div>
              <div className="row">
                <div className=" col-lg-6">
                  <Field name="couponMaxDiscount" component={renderField} type="text" label="Max discount in AED" normalize={normalizeAmountField} />
                </div>
              </div>
            </section>
          </div>
          {/* <div className = "job-info order-info subsection-form">
          <h6 className="h6 marginB0">Promotion Image</h6>
          <section className="formBg">
            <div className="row">
              <div className=" col-lg-6">
                <ImageUploader
  								className={this.state.imageUploadingApp?"loading":""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText='Add Promotion Image App (767x350)'
                  onChange={(e) => this.onDrop(e, 'App')}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
  							/>
                <Field
                  name="promotionImage"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayLocalImages.length > 0 &&
                  this.state.arrayLocalImages.map((obj, index) => {
                    return(
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.filePath} alt=""/></figure>
    									</div>
                    )
                  })
                }
              </div>
              <div className=" col-lg-6">
                <ImageUploader
  								className={this.state.imageUploadingWeb?"loading":""}
                  accept=".jpg, .jpeg, .png, .gif"
                  singleImage={true}
                  label=""
                  withIcon={false}
                  buttonText='Add Promotion Image Web (1400x250)'
                  onChange={(e) => this.onDrop(e, 'Web')}
                  imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                  maxFileSize={10485760 * 100}
  							/>
                <Field
                  name="promotionImageWeb"
                  component={renderField}
                  type="hidden"
                  label=""
                />
                {
                  this.state.arrayLocalImagesWeb.length > 0 &&
                  this.state.arrayLocalImagesWeb.map((obj, index) => {
                    return(
                      <div className="product-img" key={index}>
                        <figure className="img"><img src={obj.filePath} alt=""/></figure>
    									</div>
                    )
                  })
                }
              </div>
            </div>
          </section>
        </div> */}
          <div className="form-btn-group">
            <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Preview</button>&nbsp;
          </div>
        </form>
        {
          this.state.openCouponPreviewPopup &&
          <CouponPreviewPopup
            couponData={this.state.couponData}
            openPopup={this.state.openCouponPreviewPopup}
            closePopup={this.closeCouponPreviewPopup}
            arrayLocalImages={this.state.arrayLocalImages}
            arrayLocalImagesWeb={this.state.arrayLocalImagesWeb}

          />
        }
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCoupon: (data) => {
      return dispatch(addCoupon(data));
    },
    fetchCategoryTree: (data) => {
      return dispatch(fetchCategoryTree(data));
    },
    fetchAllBrands: (data) => {
      return dispatch(fetchAllBrands(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    },
  }
}

CouponsAdd = connect(null, mapDispatchToProps)(CouponsAdd);

CouponsAdd = reduxForm({
  form: 'CouponsAdd',
  validate,
  //enableReinitialize: true
})(CouponsAdd);

const selector = formValueSelector('CouponsAdd');
CouponsAdd = connect(state => {
  // can select values individually
  const stateCouponType = selector(state, 'couponType');
  const couponAutoGenerate = selector(state, 'couponAutoGenerate');
  const couponApplicableDays = selector(state, 'couponApplicableDays');
  const couponApplicablePlatform = selector(state, 'couponApplicablePlatform');
  const couponFor = selector(state, 'couponFor');
  const selectedItemIds = selector(state, 'selectedItemIds');


  return {
    stateCouponType,
    couponAutoGenerate,
    couponApplicableDays,
    couponApplicablePlatform,
    couponFor,
    selectedItemIds
  }
})(CouponsAdd)


export default withRouter(CouponsAdd);
