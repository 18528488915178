import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import { uploadCsv, downloadProductCsv } from '../../../actions/csv';
import { uploadImage } from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';
import AsyncSelect from 'react-select/lib/Async';
import axios from 'axios';
import { API_ROOT } from '../../../constants';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment';

import { toast } from 'react-toastify';

const promiseProductOptions = (inputValue) => {
    return (new Promise(resolve => {
        if (inputValue.length < 3) {
            resolve([]);
            return;
        }
        axios.get(`${API_ROOT}/admin/getProducts?name=${inputValue}`).then((response) => {
            resolve(response.data.responseData.data);
        })
    }))
}



class UploadCsvFile extends Component {
    constructor(props) {
        super(props);
        var lang = localStorage.getItem('lang');
        if (!lang) {
            lang = 'en';
        }
        this.state = {
            uploadingFile: false,
            csvData: null,

        }
        this.onDrop = this.onDrop.bind(this);
        this.csvLink = React.createRef();
    }



    componentDidMount() {

    }





    componentWillReceiveProps(nextProps) {
    }

    onDrop(picture) {
        var objThis = this;
        console.log(picture);
        //for (var i = 0; i < pictures.length; i++) {
        var form_data = new FormData();
        var length = picture.length;
        var file_data = picture[length - 1];
        // if (file_data && file_data.size > 10485760) { //10485760

        //     return;
        // }

        this.setState({ uploadingFile: true });
        form_data.append("uploadfile", file_data);
        this.props.uploadCsv(form_data).then((response) => {
            this.setState({ uploadingFile: false });
        }).catch((error) => {
            this.setState({ uploadingFile: false });
            if (error.response) {
                //toast.error(error.response.data.message);
            } else if (error.request) {
                //toast.error("Network error!");
            } else {
                //toast.error(error.message);
            }
        });


    }

    downloadCsv(e) {
        e.preventDefault();
        this.setState({ generatingCsv: true });
        this.props.downloadProductCsv().then((response) => {
            this.setState({ csvData: response.value.data, generatingCsv: false });
            setTimeout((e) => {
                this.csvLink.current.link.click();
            }, 500);

        }).catch(function (error) {
            this.setState({ generatingCsv: false });
            if (error.response) {
                toast.error(error.response.data.message);
            } else if (error.request) {
                toast.error("Network error!");
            } else {
                toast.error(error.message);
            }
        });
    }


    render() {
        const { handleSubmit, pristine, reset, submitting, change } = this.props;
        return (
            <>
                <div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
                <ol className="breadcrumb breadcrumb-v1 ">
                    <li className="breadcrumb-item"><Link to="/admin/banners/list">CSV Files</Link></li>
                    <li className="breadcrumb-item active">Upload</li>
                </ol>
                <h5 className="h5 mb-4">Upload CSV File</h5>
                <section className="formBg" style={{ maxWidth: "850px" }}>
                    <p>
                        First download the product csv by clicking <a onClick={(e) => this.downloadCsv(e)} herf="javascript:;" style={{ fontWeight: '500', textDecoration: 'underline', cursor: 'pointer', fontSize: '20px' }} >here</a>, after that update the quantity, prices and upload the CSV.
                    </p>
                    <CSVLink
                        headers={this.state.csvData && this.state.csvData.headers ? this.state.csvData.headers : []}
                        data={this.state.csvData && this.state.csvData.data ? this.state.csvData.data : []}
                        filename={'products-' + moment().format('MM-DD-YYYY') + '.csv'}
                        className='hidden'
                        ref={this.csvLink}
                        target='_blank'
                    />
                    <div className="row">
                        <div className=" col-lg-7 marginB20">
                            <ImageUploader
                                className={this.state.uploadingFile ? "loading" : ""}
                                accept=".csv"
                                singleImage={true}
                                label=""
                                withIcon={false}
                                buttonText="Upload CSV"
                                onChange={(e) => this.onDrop(e)}
                                imgExtension={['.csv']}
                                
                            />
                        </div>
                    </div>
                    {/* <div className="form-btn-group">
                        <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Save</button>&nbsp;
                    </div> */}
                </section>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadCsv: (data) => {
            return dispatch(uploadCsv(data));
        },
        downloadProductCsv: (data) => {
            return dispatch(downloadProductCsv(data));
        }
    }
}

UploadCsvFile = connect(null, mapDispatchToProps)(UploadCsvFile);



export default withRouter(UploadCsvFile);
