import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import { fetchCategories, deleteCategory, updateCategoryFeaturedStatus, updateCategoryStatus } from '../../../actions/categories';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import { getCategoryLevelDashes } from '../../../constants';
import $ from 'jquery';

class CategoriesList extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		var parentId = 0;
		/*if(!queryParams.page){
			queryParams.page = 1;
		}*/
		if (!queryParams.parentId) {
			queryParams.parentId = 0;
		} else {
			parentId = parseInt(queryParams.parentId);
		}
		this.state = {
			parentCategoryData: null,
			params: queryParams,
			pageUrl: '/admin/product-management/categories/list',
			records: null,
			parentId: parentId
		};
	}



	componentDidMount() {
		this.fetchCategories(this.state.params);
	}


	fetchCategories(params) {
		/*if(!params.page){
			params = Object.assign(params, {page: 1});
		}*/
		if (!params.parentId) {
			params = Object.assign(params, { parentId: 0 });
		}
		this.setState({ fetchingRecords: true });
		this.props.fetchCategories(params).then((response) => {
			var parentCategoryData = (response.value.data.responseData.info && response.value.data.responseData.info.id) ? response.value.data.responseData.info : null;
			this.setState({ records: response.value.data.responseData, parentCategoryData: parentCategoryData });
			this.setState({ fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.fetchCategories(params);
			if (params && params.parentId && parseInt(params.parentId) > 0) {
				this.setState({ parentId: parseInt(params.parentId), params: params });
			} else {
				this.setState({ parentId: 0, parentCategoryData: null, params: params });
			}
		}
	}

	deleteRecord(recordid) {
		this.props.deleteCategory({ id: recordid }).then((response) => {
			toast.success(response.value.data.message);
			this.fetchCategories(this.state.params);
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}

	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		//this.props.history.push(this.state.pageUrl+'?page=1&parentId='+this.state.parentId);
		this.props.history.push(this.state.pageUrl + '?parentId=' + this.state.parentId);
	}

	submitFilterForm(values, dispatch, props) {
		var params = this.state.params;
		params = Object.assign(params, values);
		//params.page = 1;
		if (!params.parentId) {
			params.parentId = parseInt(this.state.parentId);
		}
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}

	updateCategoryFeaturedStatus(categoryId, status) {
		this.props.updateCategoryFeaturedStatus({ id: categoryId, status }).then((response) => {
			toast.success(response.value.data.message);
			this.fetchCategories(this.state.params);
		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}


	updateCategoryStatus(categoryId, status, index) {
		this.props.updateCategoryStatus({ id: categoryId, status }).then((response) => {
			toast.success(response.value.data.message);
			this.fetchCategories(this.state.params);
		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}

	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records } = { ...this.state };

		return (
			<>
				{
					this.state.parentId > 0 &&
					<div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
				}

				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/product-management/categories/list?parentId=0">Categories</Link></li>
					{
						this.state.parentCategoryData &&
						<li className="breadcrumb-item"><Link to={"/admin/product-management/categories/list?parentId=" + this.state.parentId}>{this.state.parentCategoryData.categoryName}</Link></li>
					}
					<li className="breadcrumb-item active">List</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">{(this.state.parentId > 0) ? "Sub categories" : "Categories"}</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
								<span className="icon-filter mr-1"></span>
								Filters
							</a>
							<Link to={"/admin/product-management/categories/list/create?parentId=" + this.state.parentId} className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add {(this.state.parentId > 0) ? "Sub category" : "Category"}</Link>
						</div>
					</div>
				</div>
				<div className="collapse" id="filter">
					<div className="filter-dropdown">
						<form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
							<div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
								</div>
							</div>
							<div className="form-btn-group mt-3 text-right">
								<button type="button" onClick={(e) => this.clearFilters(e)} className="btn btn-clear hvr-shadow mr-1">Clear</button>
								<button type="submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
					</div>
				</div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Image</th>
									<th>Name</th>
									{
										(this.state.parentId <= 0)
											?
											<th>Sub-categories</th>
											:
											''
									}
									{
										(this.state.parentId > 0)
											?
											<>
												<th>Attributes</th>
												<th>Featured?</th>
											</>
											:
											''
									}
									<th>Status</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj.id}>
												<td>{obj.id}</td>
												<td>{(obj.attachment && obj.attachment.filePath) ? <img style={{ width: "auto", height: "50px" }} src={obj.attachment.filePath} /> : ""}</td>
												<td>{obj.categoryName}</td>
												{
													(this.state.parentId <= 0)
														?
														<td>{obj.childCount}</td>
														:
														''
												}
												{
													(this.state.parentId > 0)
														?
														<>
															<td>{obj.attribute.length}</td>
															{/*<td>{obj.featuredCategory?<span className="green_status">Yes</span>:<span className="red_status">No</span>}</td>*/}
															<td>
																<label className="switch">
																	<input type="checkbox" checked={obj.featuredCategory ? true : false} onChange={() => this.updateCategoryFeaturedStatus(obj.id, obj.featuredCategory ? 0 : 1, index)} />
																	<span className="slider round"></span>
																</label>
															</td>
														</>
														:
														''
												}

												<td><label className="switch">
													<input type="checkbox" checked={obj.status == 1 ? true : false} onChange={() => this.updateCategoryStatus(obj.id, obj.status ? 0 : 1, index)} />
													<span className="slider round"></span>
												</label></td>
												<td>
													<span className="dropdown threeDots">
														<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
															<span></span> <span></span> <span></span>
														</a>
														<ul className="dropdown-menu" role="menu">
															<li><Link className="dropdown-item" title="Edit" to={"/admin/product-management/categories/list/edit/" + obj.id + "?parentId=" + this.state.parentId} >Edit</Link></li>
															{
																obj.parent_id == 0 &&
																<li><a className="dropdown-item" title="Sub categories" href={"/admin/product-management/categories/list?parentId=" + obj.id} >Sub categories</a></li>
															}
															{
																obj.parent_id > 0 &&
																<>
																	<li><Link className="dropdown-item" title="Manage Attributes" to={"/admin/product-management/categories/list/manageAttributes/" + obj.id + "?parentId=" + obj.parent_id} >Attributes</Link></li>
																	<li><Link className="dropdown-item" title="Manage Attributes" to={"/admin/product-management/products/list/create/?cat_id=" + obj.id} >Add Product</Link></li>
																</>
															}
															<li><Confirm className="confirmPopup"
																onConfirm={() => this.deleteRecord(obj.id)}
																body="Are you sure you want to delete this?"
																confirmText="Confirm Delete"
																title="Delete!">
																<button className="dropdown-item">Delete</button>
															</Confirm></li>
														</ul>
													</span>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{/*
				records && records.data && this.state.records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			*/}
			</>

		);
	}
}

CategoriesList = reduxForm({
	form: 'CategoriesListFilter'
})(CategoriesList);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCategories: (data) => {
			return dispatch(fetchCategories(data));
		},
		deleteCategory: (data) => {
			return dispatch(deleteCategory(data));
		},
		updateCategoryFeaturedStatus: (data) => {
			return dispatch(updateCategoryFeaturedStatus(data));
		},
		updateCategoryStatus: (data) => {
			return dispatch(updateCategoryStatus(data));
		}
	}
}


export default withRouter(connect(null, mapDispatchToProps)(CategoriesList));
