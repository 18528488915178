import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {fetchSettings, updateSettings} from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {normalizeAmountField, normalizeNumber, isUrlValid} from '../../../constants';
import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (values.facebookLink && values.facebookLink.trim() != '') {
    if(!isUrlValid(values.facebookLink)){
      errors.facebookLink = "Enter a valid url";
      hasErrors = true;
    }
  }

  if (values.twitterLink && values.twitterLink.trim() != '') {
    if(!isUrlValid(values.twitterLink)){
      errors.twitterLink = "Enter a valid url";
      hasErrors = true;
    }
  }

  if (values.youtubeLink && values.youtubeLink.trim() != '') {
    if(!isUrlValid(values.youtubeLink)){
      errors.youtubeLink = "Enter a valid url";
      hasErrors = true;
    }
  }

  if (values.instagramLink && values.instagramLink.trim() != '') {
    if(!isUrlValid(values.instagramLink)){
      errors.instagramLink = "Enter a valid url";
      hasErrors = true;
    }
  }

  if (values.pinterestLink && values.pinterestLink.trim() != '') {
    if(!isUrlValid(values.pinterestLink)){
      errors.pinterestLink = "Enter a valid url";
      hasErrors = true;
    }
  }

  if (values.walletAmountRedumption && values.walletAmountRedumption > 100) {
      errors.walletAmountRedumption = "Enter a valid % amount";
      hasErrors = true;
  }

  return hasErrors && errors;
}

class ManageSettings extends Component {
	constructor(props){
    super(props);
    this.state = {


    }
  }

  submitForm(values, dispatch, props){
    return this.props.updateSettings(values)
    .then((response) => {
      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchSettings();
  }

  fetchSettings(){
		this.setState({fetchingRecords: true});
    this.props.fetchSettings().then((response) => {
      let settings = response.value.data.responseData;
      if(settings){
        delete settings.id;
        delete settings.createdAt;
        delete settings.updatedAt;
        this.props.initialize(settings);
      }
      this.setState({fetchingRecords: false, settings: settings});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/manage-settings/configuration">Settings</Link></li>
        <li className="breadcrumb-item active">Manage Settings</li>
      </ol>
        <h5 className="h5 mb-4">Manage Settings</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
          <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  name="referrerRewards"
                  component={renderField}
                  type="text"
                  label="Referrer rewards"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="refereeRewards"
                  component={renderField}
                  type="text"
                  label="Referee rewards"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="referralExpiry"
                  component={renderField}
                  label="Referral reward expiry (days)"
                  normalize={normalizeNumber}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="returnablePeriod"
                  component={renderField}
                  label="Product returnable period (days)"
                  normalize={normalizeNumber}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="assemblyCharges"
                  component={renderField}
                  label="Assembly Charges"
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  label="Tax exclusive"
                  name='taxExclusive'
                  optionLabel='name'
                  optionValue='id'
                  options={[{id: 1, name: 'Yes'}, {id: 0, name: 'No'}]}
                  component={renderReactSelect}
                  placeholder="Select.."
                  multi={false}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  label="Maximum COD cap"
                  name='maximumCodCap'
                  component={renderField}
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  label="Enable online payments"
                  name='allowOnlinePayments'
                  optionLabel='name'
                  optionValue='id'
                  options={[{id: 1, name: 'Yes'}, {id: 0, name: 'No'}]}
                  component={renderReactSelect}
                  placeholder="Select.."
                  multi={false}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  label="Wallet Redemption Amout (%age)"
                  name='walletAmountRedumption'
                  component={renderField}
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="contactUsEmail"
                  component={renderField}
                  label="Contact us email"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  label="Shipping Weight Limit (KG)"
                  name='shippingWeightLimit'
                  component={renderField}
                  normalize={normalizeAmountField}
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  label="Shipping Charge Till Limit"
                  name='shippingChargeTillLimit'
                  component={renderField}
                  normalize={normalizeAmountField}
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  label="Shipping Charge After Limit Per Kg"
                  name='shippingChargeAfterLimitPerKg'
                  component={renderField}
                  normalize={normalizeAmountField}
                />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6">
                <Field
                  name="facebookLink"
                  component={renderField}
                  label="Facebook link"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="twitterLink"
                  component={renderField}
                  label="Twitter link"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="instagramLink"
                  component={renderField}
                  label="Instagram link"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="youtubeLink"
                  component={renderField}
                  label="Youtube link"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="pinterestLink"
                  component={renderField}
                  label="Pinterest link"
                />
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-6">
                <Field
                  name="iosAppLink"
                  component={renderField}
                  label="IOS Applink"
                />
              </div>
              <div className=" col-lg-6">
                <Field
                  name="androidAppLink"
                  component={renderField}
                  label="Android Applink"
                />
              </div>
            </div>
            <div className="form-btn-group">
              <Link to="/admin/user-management/users/list?page=1" type="button" className="btn btn-secondary hvr-shadow">Cancel</Link>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: (data) => {
      return dispatch(fetchSettings(data));
    },
    updateSettings: (data) => {
      return dispatch(updateSettings(data));
    }
  }
}

ManageSettings = connect(null, mapDispatchToProps)(ManageSettings);

ManageSettings = reduxForm({
  form: 'ManageSettings',
  validate,
  //enableReinitialize: true
})(ManageSettings);

export default ManageSettings;
