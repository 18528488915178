import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_BANNERS = 'FETCH_BANNERS';
export const FETCH_BANNER = 'FETCH_BANNER';
export const ADD_BANNER = 'ADD_BANNER';
export const DELETE_BANNER = 'DELETE_BANNER';
export const UPDATE_BANNER_STATUS = 'UPDATE_BANNER_STATUS';

export function fetchBanners(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProductBanners`
  });
  return {
    type: FETCH_BANNERS,
    payload: request
  };
}



export function fetchBanner(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProductBannerWithId`
  });
  return {
    type: FETCH_BANNER,
    payload: request
  };
}

export function addBanner(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addBanner";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_BANNER,
    payload: request
  };
}





export function deleteBanner(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteProductBanners`
  });
  return {
    type: DELETE_BANNER,
    payload: request
  };
}

export function updateBannerStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateProductBannerOrderStatus`
  });
  return {
    type: UPDATE_BANNER_STATUS,
    payload: request
  };
}
