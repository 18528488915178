import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {addCategory, fetchCategory} from '../../../actions/categories';
import {uploadImage} from '../../../actions/common';
import {getCategoryLevelDashes} from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter category name";
    hasErrors = true;
  }
  if (!values.categoryCode || values.categoryCode.trim() === '') {
    errors.categoryCode = "Enter category code";
    hasErrors = true;
  }
  if (!values.categorySymbol || values.categorySymbol.trim() === '') {
    errors.categorySymbol = "Enter category symbol";
    hasErrors = true;
  }
  if (!values.attachmentId || values.attachmentId === '') {
    errors.attachmentId = "Upload category image";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class CategoriesAdd extends Component {
	constructor(props){
    super(props);
    var queryParams = queryString.parse(props.location.search);
    var parentId = queryParams.parentId?queryParams.parentId:0;
    var catLabelSingular = "Category";
    var catLabelPlural = "Categories";
    if(parentId && parentId > 0){
      catLabelSingular = "Sub-category";
      catLabelPlural = "Sub-categories";
    }
    this.state = {
      dataLoaded: false,
      categoryId: props.match.params.categoryId?props.match.params.categoryId:null,
      arrayLocalImages: [],
      categoryTree: [],
      parentId: parentId,
      parentCategoryData: null,
      catLabelSingular,
      catLabelPlural
    }
    this.onDrop = this.onDrop.bind(this);
  }

  submitForm(values, dispatch, props){
    let apiPath = "/admin/addCategory";
    let method = "POST";
    if(this.state.categoryId){
      apiPath = "/admin/editCategory";
      method = "PATCH";

    }
    return this.props.addCategory(values, apiPath, method).then((response) => {
      this.props.history.push("/admin/product-management/categories/list?parentId="+this.state.parentId+"&page=1");
      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.categoryId){
      this.fetchCategory(this.state.categoryId);
    }else{
      this.props.change('parentId', this.state.parentId);
    }

    if(this.state.parentId){
      this.fetchParentCategory(this.state.parentId);
    }
  }

  fetchParentCategory(categoryId){
		this.setState({fetchingRecords: true});
    this.props.fetchCategory({id: categoryId}).then((response) => {
      let categoryData = response.value.data.responseData.data;

      this.setState({fetchingRecords: false, parentCategoryData: categoryData});
      //this.props.initialize(categoryData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchCategory(categoryId){
		this.setState({fetchingRecords: true});
    this.props.fetchCategory({id: categoryId}).then((response) => {
      let categoryData = response.value.data.responseData.data;
      let formData = {};
      let arrayLocalImages = [];
      if(categoryData){
        formData = {
          id: categoryData.id,
          parentId: categoryData.parent_id,
          name: categoryData.categoryName,
          attachmentId: (categoryData.attachment && categoryData.attachment.attachmentId)?categoryData.attachment.attachmentId:null,
          description: categoryData.description,
        }
        if(categoryData.attachment && categoryData.attachment.attachmentId){
          arrayLocalImages.push(categoryData.attachment);
        }
      }
      this.props.initialize(formData);
      this.setState({fetchingRecords: false, arrayLocalImages});
      //this.props.initialize(categoryData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  componentWillReceiveProps(nextProps){
  }

  onDrop(picture) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({imageUploading: true});
      form_data.append("uploadfile", file_data);
      this.props.uploadImage(form_data, {upload_type:"category_image"}).then((response) => {
        this.setState({imageUploading: false});
        var payload = response.value.data.responseData;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);
          this.setState({arrayLocalImages: arrayLocalImages});
					var idArray = [payload.id];
          this.props.change('attachmentId', idArray[0]);
        }
      }).catch((error)=>{
        this.setState({imageUploading: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/categories/list">Categories</Link></li>
        {
          this.state.parentCategoryData &&
          <li className="breadcrumb-item"><Link to={"/admin/categories/list?parentId="+this.state.parentId}>{this.state.parentCategoryData.categoryName}</Link></li>
        }
        <li className="breadcrumb-item active">{this.state.categoryId?"Edit":"Add"} {this.state.parentCategoryData?' Sub-category':''}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.categoryId?"Edit":"Add"} {this.state.parentCategoryData?"Sub-category":"Category"}</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
          <div className="row">
            <div className=" col-lg-7">
              {/*<Field
                label="Parent Category"
                name='parentId'
                optionLabel='categoryName'
                optionValue='id'
                options={this.state.categoryTree}
                component={renderReactSelect}
                placeholder="Select"
                multi={false}
                isClearable={true}
              />*/}
              <Field
                name="name"
                component={renderField}
                type="text"
                label={this.state.catLabelSingular+" Name"}
              />

              <Field
                name="description"
                component={renderTextarea}
                label={this.state.catLabelSingular+" Description (Max. 600 chars)"}
                maxLength={600}
              />


            </div>
            <div className=" col-lg-5">
              <ImageUploader
								className={this.state.imageUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText={this.state.categoryId?'Update '+this.state.catLabelSingular+' Image (300x180)':'Add '+this.state.catLabelSingular+' Image (300x180)'}
                onChange={this.onDrop}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="attachmentId"
                component={renderField}
                type="hidden"
                label=""
              />
              {
              this.state.arrayLocalImages.length > 0 &&
              this.state.arrayLocalImages.map((obj, index) => {
                return(
                  <div className="product-img" key={index}>
                   <figure className="img"><img src={obj.filePath} alt=""/></figure>
									 {/*<span className="pro-img-del icon-close" onClick = {(e) => this.deleteLocalImage(e, obj.id)}></span>*/}
                  </div>
                )
              })
            }
            </div>

          </div>
            <div className="form-btn-group">
              <Link to={"/admin/product-management/categories/list?parentId="+this.state.parentId} type="button" className="btn btn-secondary hvr-shadow">Cancel</Link>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCategory: (data, apiPath, method) => {
      return dispatch(addCategory(data, apiPath, method));
    },
    fetchCategory: (data) => {
      return dispatch(fetchCategory(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    }
  }
}

CategoriesAdd = connect(null, mapDispatchToProps)(CategoriesAdd);

CategoriesAdd = reduxForm({
  form: 'CategoriesAdd',
  validate,
  //enableReinitialize: true
})(CategoriesAdd);

export default withRouter(CategoriesAdd);
