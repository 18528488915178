import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';

import {fetchCouponRuleDetail, updateCouponRuleDetails} from '../../../actions/coupons';
import {uploadImage} from '../../../actions/common';
import ImageUploader from 'react-images-upload';

import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;


  return hasErrors && errors;
}


class CouponsEdit extends Component {
	constructor(props){
    super(props);
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
    this.state = {
      dataLoaded: false,
      couponRuleId: props.match.params.couponRuleId?props.match.params.couponRuleId:null,
      arrayLocalImages: [],
      arrayLocalImagesWeb: [],
    }
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(picture, type) {
    var objThis = this;
    var imageUploading = 'imageUploading'+type;
    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({[imageUploading]: true});
      form_data.append("uploadfile", file_data);
      this.props.uploadImage(form_data, {upload_type:"promotion_image"}).then((response) => {
        this.setState({[imageUploading]: false});
        var payload = response.value.data.responseData;
        var arrayLocalImages = [];
        var arrayLocalImagesWeb = [];
        if(payload && payload.id){
          if(type == 'App'){
            arrayLocalImages.push(payload);
            this.setState({arrayLocalImages: arrayLocalImages});
  					var idArray = [payload.id];
            this.props.change('promotionImage', idArray[0]);
          }else{
            arrayLocalImagesWeb.push(payload);
            this.setState({arrayLocalImagesWeb: arrayLocalImagesWeb});
  					var idArray = [payload.id];
            this.props.change('promotionImageWeb', idArray[0]);
          }

        }
      }).catch((error)=>{
        this.setState({[imageUploading]: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }


  submitForm(values, dispatch, props){

    console.log(values);
    /*if(!values.promotionImage && !values.promotionImageWeb){
      toast.error("Upload atleast one image");
      return;
    }*/
    //values = Object.assign(values, {ruleId: this.state.couponRuleId});


    return this.props.updateCouponRuleDetails(values).then((response) => {
      toast.success(response.value.data.message);
      window.history.back();
    }).catch((error)=>{
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.fetchCouponRuleDetail(this.state.couponRuleId);
  }

  fetchCouponRuleDetail(couponRuleId){
		this.setState({fetchingRecords: true});
    this.props.fetchCouponRuleDetail({ruleId: couponRuleId}).then((response) => {
      let couponData = response.value.data.responseData.coupons;
      this.props.initialize({ruleId: couponData.ruleId, couponRuleName: couponData.couponRuleName, couponRuleDescription: couponData.couponRuleDescription});
      if(couponData && couponData.promotionImage && couponData.promotionImage.id){
        this.setState({arrayLocalImages: [couponData.promotionImage]});
        this.props.change('promotionImage', couponData.promotionImage.id);
      }
      if(couponData && couponData.promotionImageWeb && couponData.promotionImageWeb.id){
        this.setState({arrayLocalImagesWeb: [couponData.promotionImageWeb]});
        this.props.change('promotionImageWeb', couponData.promotionImageWeb.id);
      }
      this.setState({fetchingRecords: false, couponData});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  render() {
    const {handleSubmit, pristine, reset, submitting, change} = this.props;
    return (
      <>
        <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1 ">
          <li className="breadcrumb-item"><Link to="/admin/coupons/list">Edit Coupon Details</Link></li>
          <li className="breadcrumb-item active">Update Details</li>
        </ol>
        <h5 className="h5 mb-4">Update Coupon Details</h5>
        <section className="formBg" style = {{maxWidth: "850px"}}>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >


              <div className="row">
                <div className=" col-lg-6">
                  <Field name="couponRuleName" component={renderField} type="text" label="Rule Name" />
                </div>
                <div className=" col-lg-6">
                  <Field name="couponRuleDescription" component={renderField} type="text" label="Rule Description"  />
                </div>
              </div>




              {/* <div className="row">
                <div className=" col-lg-6">
                  <ImageUploader
    								className={this.state.imageUploadingApp?"loading":""}
                    accept=".jpg, .jpeg, .png, .gif"
                    singleImage={true}
                    label=""
                    withIcon={false}
                    buttonText='Add Promotion Image App (767x350)'
                    onChange={(e) => this.onDrop(e, 'App')}
                    imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                    maxFileSize={10485760 * 100}
    							/>
                  <Field
                    name="promotionImage"
                    component={renderField}
                    type="hidden"
                    label=""
                  />
                  {
                    this.state.arrayLocalImages.length > 0 &&
                    this.state.arrayLocalImages.map((obj, index) => {
                      return(
                        <div className="product-img" key={index}>
                          <figure className="img"><img src={obj.filePath} alt=""/></figure>
      									</div>
                      )
                    })
                  }
                </div>
                <div className=" col-lg-6">
                  <ImageUploader
    								className={this.state.imageUploadingWeb?"loading":""}
                    accept=".jpg, .jpeg, .png, .gif"
                    singleImage={true}
                    label=""
                    withIcon={false}
                    buttonText='Add Promotion Image Web (1400x250)'
                    onChange={(e) => this.onDrop(e, 'Web')}
                    imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                    maxFileSize={10485760 * 100}
    							/>
                  <Field
                    name="promotionImageWeb"
                    component={renderField}
                    type="hidden"
                    label=""
                  />
                  {
                    this.state.arrayLocalImagesWeb.length > 0 &&
                    this.state.arrayLocalImagesWeb.map((obj, index) => {
                      return(
                        <div className="product-img" key={index}>
                          <figure className="img"><img src={obj.filePath} alt=""/></figure>
      									</div>
                      )
                    })
                  }
                </div>
              </div> */}


          <div className="form-btn-group">
            <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
          </div>
        </form>
        </section>
      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCouponRuleDetail: (data) => {
      return dispatch(fetchCouponRuleDetail(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    },
    updateCouponRuleDetails: (data) => {
      return dispatch(updateCouponRuleDetails(data));
    }
  }
}

CouponsEdit = connect(null, mapDispatchToProps)(CouponsEdit);

CouponsEdit = reduxForm({
  form: 'CouponsEdit',
  validate,
  //enableReinitialize: true
})(CouponsEdit);

export default withRouter(CouponsEdit);
