import React, { Component, Fragment } from 'react';
import { Route, Link, NavLink, withRouter } from 'react-router-dom';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {ROUTES} from '../../routesList';


class LeftNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      user: props.user,
      currrentUrl: props.location.pathname,
      permissions: props.permissions
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.pathname != nextProps.location.pathname){
      this.setState({currrentUrl: nextProps.location.pathname});
    }
  }

  componentDidMount(){

  }

  render() {
    return (
      <div id="sideNav">
        <nav className="navbar navbar-expand-md navbar-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            {
              this.state.user.user.type && this.state.user.user.type.toLowerCase() != 'merchant'
              ?
              <ul className="navbar-nav custScroll">
                <li className={(this.state.currrentUrl == '/admin/dashboard' || this.state.currrentUrl == '/')?'active nav-item': 'nav-item'}><Link to="/admin/dashboard" className="nav-link"><span className="icon icon-ic-dashboard"></span><span className="nav-txt">Dashboard</span></Link></li>

                {
                  <PermissibleRender
                    userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_USER"] : this.state.permissions}
                    requiredPermissions={["MANAGE_USER"]}
                    oneperm
                  >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/user-management") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#user-management"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Users Management</span></a>
                    <ul id="user-management" className={(this.state.currrentUrl.indexOf("/user-management") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                      <li className={(this.state.currrentUrl.indexOf('/admin/user-management/users/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/user-management/users/list?page=1" className="nav-link"><span className="nav-txt">Users</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/user-management/roles/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/user-management/roles/list" className="nav-link"><span className="nav-txt">Roles</span></Link></li>
                    </ul>
                  </li>
                  </PermissibleRender>
                }

                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_CUSTOMERS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_CUSTOMERS"]}
                  oneperm
                >

                  <li className={(this.state.currrentUrl.indexOf('/admin/customers/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/customers/list?page=1" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Customers</span></Link></li>
                </PermissibleRender>


                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_PRODUCT_MANAGEMENT"] : this.state.permissions}
                  requiredPermissions={["MANAGE_PRODUCT_MANAGEMENT"]}
                  oneperm
                >
                <li className="nav-item collapsible">
                  <a className={(this.state.currrentUrl.indexOf("/product-management") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#products-management"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Products Management</span></a>
                  <ul id="products-management" className={(this.state.currrentUrl.indexOf("/product-management") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <li className={(this.state.currrentUrl.indexOf('/admin/product-management/categories/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/product-management/categories/list?parentId=0" className="nav-link"><span className="nav-txt">Categories</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/product-management/products/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/product-management/products/list?page=1" className="nav-link"><span className="nav-txt">Products</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/product-management/brands/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/product-management/brands/list?page=1" className="nav-link"><span className="nav-txt">Brands</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/product-management/attribute-groups/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/product-management/attribute-groups/list" className="nav-link"><span className="nav-txt">Attribute Groups</span></Link></li>
                  </ul>
                </li>
              </PermissibleRender>
                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_ORDERS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_ORDERS"]}
                  oneperm
                >
                  <li className={(this.state.currrentUrl.indexOf('/admin/orders/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/orders/list?page=1" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Orders</span></Link></li>
                </PermissibleRender>
                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_TRANSACTIONS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_TRANSACTIONS"]}
                  oneperm
                >
                  <li className={(this.state.currrentUrl.indexOf('/admin/transactions/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/transactions/list?page=1" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Transactions</span></Link></li>
                </PermissibleRender>

                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_SETTINGS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_SETTINGS"]}
                  oneperm
                >
                <li className="nav-item collapsible">
                  <a className={(this.state.currrentUrl.indexOf("/manage-settings") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#settings"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Manage Settings</span></a>
                  <ul id="settings" className={(this.state.currrentUrl.indexOf("/manage-settings") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <li className={(this.state.currrentUrl.indexOf('/admin/manage-settings/configuration') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/manage-settings/configuration" className="nav-link"><span className="nav-txt">Config. Settings</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/manage-settings/price-grids/list') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/manage-settings/price-grids/list" className="nav-link"><span className="nav-txt">Tax Grid</span></Link></li>

                    <li className={(this.state.currrentUrl.indexOf('/admin/manage-settings/page/terms') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/manage-settings/page/terms" className="nav-link"><span className="nav-txt">Terms & Conditions</span></Link></li>
                    <li className={(this.state.currrentUrl.indexOf('/admin/manage-settings/page/privacy-policy') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/manage-settings/page/privacy-policy" className="nav-link"><span className="nav-txt">Privacy Policy</span></Link></li>
                  </ul>
                </li>
              </PermissibleRender>
                {/* <li className={(this.state.currrentUrl.indexOf('/admin/settings/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/settings" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Settings</span></Link></li> */}
                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_PRODUCT_LAUNCH_BANNERS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_PRODUCT_LAUNCH_BANNERS"]}
                  oneperm
                >
                  <li className={(this.state.currrentUrl.indexOf('/admin/banners/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/banners/list" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Promotional Banners</span></Link></li>
                </PermissibleRender>
                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_PROMOTION_ZONE"] : this.state.permissions}
                  requiredPermissions={["MANAGE_PROMOTION_ZONE"]}
                  oneperm
                >
                  <li className={(this.state.currrentUrl.indexOf('/admin/coupons/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/coupons/list" className="nav-link"><span className="icon icon-my-contact"></span><span className="nav-txt">Promotion Zone</span></Link></li>
                </PermissibleRender>

                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_QUESTIONS", "MANAGE_QUESTION_ANSWERS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_QUESTIONS", "MANAGE_QUESTION_ANSWERS"]}
                  oneperm
                >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/manage-question-answers") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#manage-question-answers"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Manage Question/Answers</span></a>
                    <ul id="manage-question-answers" className={(this.state.currrentUrl.indexOf("/manage-question-answers") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                    <PermissibleRender
                      userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_QUESTIONS"] : this.state.permissions}
                      requiredPermissions={["MANAGE_QUESTIONS"]}
                      oneperm
                    >
                      <li className={(this.state.currrentUrl.indexOf('/admin/manage-question-answers/questions/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/manage-question-answers/questions/list?page=1" className="nav-link"><span className="nav-txt">Questions</span></Link></li>
                    </PermissibleRender>
                    <PermissibleRender
                      userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_QUESTION_ANSWERS"] : this.state.permissions}
                      requiredPermissions={["MANAGE_QUESTION_ANSWERS"]}
                      oneperm
                    >
                      <li className={(this.state.currrentUrl.indexOf('/admin/manage-question-answers/answers/') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/manage-question-answers/answers/list?page=1" className="nav-link"><span className="nav-txt">Answers</span></Link></li>
                    </PermissibleRender>
                    </ul>
                  </li>
                </PermissibleRender>
                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_REPORTS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_REPORTS"]}
                  oneperm
                >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/admin/financeReports") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#finance_reports"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Finance Reports</span></a>
                    <ul id="finance_reports" className={(this.state.currrentUrl.indexOf("/admin/financeReports") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                      <li className={(this.state.currrentUrl.indexOf('/admin/financeReports/paymentsReport') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/financeReports/paymentsReport/list?page=1" className="nav-link"><span className="nav-txt">Payments</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/financeReports/logisticsReport') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/financeReports/logisticsReport/list?page=1" className="nav-link"><span className="nav-txt">Logistics</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/financeReports/revenueReport') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/financeReports/revenueReport/list" className="nav-link"><span className="nav-txt">Revenue</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/financeReports/liabilityReport') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/financeReports/liabilityReport/list?page=1" className="nav-link"><span className="nav-txt">Liability</span></Link></li>
                    </ul>
                  </li>
                </PermissibleRender>
                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_REPORTS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_REPORTS"]}
                  oneperm
                >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/admin/operationReports") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#operation_reports"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Operations Reports</span></a>
                    <ul id="operation_reports" className={(this.state.currrentUrl.indexOf("/admin/operationReports") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                      <li className={(this.state.currrentUrl.indexOf('/admin/operationReports/productPerformance') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/operationReports/productPerformance/list?page=1" className="nav-link"><span className="nav-txt">Product Performance</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/operationReports/goodsReturned') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/operationReports/goodsReturned/list?page=1" className="nav-link"><span className="nav-txt">Goods Returned</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/operationReports/stockPerformance') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/operationReports/stockPerformance/list?page=1" className="nav-link"><span className="nav-txt">Stock Performance</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/operationReports/consignments') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/operationReports/consignments/list?page=1" className="nav-link"><span className="nav-txt">Consignments</span></Link></li>
                    </ul>
                  </li>
                </PermissibleRender>

                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_REPORTS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_REPORTS"]}
                  oneperm
                >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/admin/salesReports") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#sales_reports"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Sales Reports</span></a>
                    <ul id="sales_reports" className={(this.state.currrentUrl.indexOf("/admin/salesReports") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesReport') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesReport/list?page=1" className="nav-link"><span className="nav-txt">Sales Report</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesRegionReport') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesRegionReport/list" className="nav-link"><span className="nav-txt">Sales Region Report</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesByProduct') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesByProduct/list?page=1" className="nav-link"><span className="nav-txt">Sales By Product</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesByCategory') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesByCategory/list?page=1" className="nav-link"><span className="nav-txt">Sales By Category</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesByCustomer') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesByCustomer/list?page=1" className="nav-link"><span className="nav-txt">Sales By Customer</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesByCouponCode') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesByCouponCode/list?page=1" className="nav-link"><span className="nav-txt">Sales By Coupon Code</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesByCouponUser') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesByCouponUser/list?page=1" className="nav-link"><span className="nav-txt">Sales By Coupon User</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/salesReports/salesByPlatform') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/salesReports/salesByPlatform/list?page=1" className="nav-link"><span className="nav-txt">Sales By Platform</span></Link></li>
                    </ul>
                  </li>
                </PermissibleRender>

                <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_REPORTS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_REPORTS"]}
                  oneperm
                >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/admin/marketingReports") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#marketing_reports"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Marketing Reports</span></a>
                    <ul id="marketing_reports" className={(this.state.currrentUrl.indexOf("/admin/marketingReports") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                      <li className={(this.state.currrentUrl.indexOf('/admin/marketingReports/customerReports') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/marketingReports/customerReports/list?page=1" className="nav-link"><span className="nav-txt">Customer Reports</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/marketingReports/topKeywords') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/marketingReports/topKeywords/list?page=1" className="nav-link"><span className="nav-txt">Top Keywords</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/marketingReports/newVsReturingUsers') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/marketingReports/newVsReturingUsers/list?page=1" className="nav-link"><span className="nav-txt">New Vs Returning Users</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/marketingReports/abandonedCarts') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/marketingReports/abandonedCarts/list?page=1" className="nav-link"><span className="nav-txt">Abandoned Carts</span></Link></li>

                    </ul>
                  </li>
                </PermissibleRender>

                {/* <PermissibleRender
                  userPermissions={this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'admin'? ["MANAGE_REPORTS"] : this.state.permissions}
                  requiredPermissions={["MANAGE_REPORTS"]}
                  oneperm
                >
                  <li className="nav-item collapsible">
                    <a className={(this.state.currrentUrl.indexOf("/admin/reports") !== -1)?'nav-link dropdown-toggle icon-arrow-down': 'nav-link dropdown-toggle icon-arrow-down collapsed'}      href="javascript:;" data-toggle="collapse" data-target="#reports"><span className="icon icon-ic-Invoices"></span> <span className="nav-txt">Reports</span></a>
                    <ul id="reports" className={(this.state.currrentUrl.indexOf("/admin/reports") !== -1)?'collapse collapse-dropdown show': 'collapse collapse-dropdown'} >
                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/mostPurchasedProducts') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/mostPurchasedProducts/list?page=1" className="nav-link"><span className="nav-txt">Most Purchased Products</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/mostSearchedProducts') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/mostSearchedProducts/list?page=1" className="nav-link"><span className="nav-txt">Most Searched Products</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/mostReturnedProducts') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/mostReturnedProducts/list?page=1" className="nav-link"><span className="nav-txt">Most Returned Products</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/mostViewedProducts') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/mostViewedProducts/list?page=1" className="nav-link"><span className="nav-txt">Most Viewed Products</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/couponUsage') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/couponUsage/list?page=1" className="nav-link"><span className="nav-txt">Coupon Usage</span></Link></li>
                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/userCouponUsage') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/userCouponUsage/list?page=1" className="nav-link"><span className="nav-txt">Coupon Usage User Wise</span></Link></li>

                      <li className={(this.state.currrentUrl.indexOf('/admin/reports/stateWiseOrders') !== -1)?'active nav-item': 'nav-item'}><Link to="/admin/reports/stateWiseOrders/list" className="nav-link"><span className="nav-txt">Orders State Wise</span></Link></li>
                    </ul>
                  </li>
                </PermissibleRender> */}
                <li className="nav-item"><Link to="/logout" className="nav-link"><span className="icon icon-ic-logout"></span><span className="nav-txt">Logout</span></Link></li>
              </ul>
              :

                this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'merchant'
                ?
                <ul className="navbar-nav custScroll">
                  <li className={(this.state.currrentUrl.indexOf('/merchant/products/list') !== -1)?'active nav-item': 'nav-item'}><Link to="/merchant/products/list?page=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Products</span></Link></li>
                  <li className={(this.state.currrentUrl.indexOf('/merchant/csv/') !== -1)?'active nav-item': 'nav-item'}><Link to="/merchant/csv/list?page=1" className="nav-link"><span className="icon icon-ic-Invoices"></span><span className="nav-txt">Manage Inventory</span></Link></li>
                  <li className="nav-item"><Link to="/logout" className="nav-link"><span className="icon icon-ic-logout"></span><span className="nav-txt">Logout</span></Link></li>
                  
                </ul>
                :
                <></>

            }

          </div>
        </nav>
      </div>
    );
  }
}
export default withRouter(LeftNavigation);
