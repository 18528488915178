import React, { Component } from 'react';


class LeftPanel extends Component {
  render() {
    return (
      <div className="col-md-6">
        <div className="logoSec">
          <a href="javascript://"> <img src="/assets/img/logo.png" alt="img" width="270" /> </a>
        </div>
      </div>
    )
  }
}
export default LeftPanel;
