import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderField from '../FormFields/renderField';
import {Redirect, BrowserRouter, Link} from 'react-router-dom';
import { signInUser, signInUserSuccess } from '../../actions/users';
import {toast} from 'react-toastify';
import $ from 'jquery';


//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.email || values.email.trim() === '') {
    errors.email = 'Enter email';
    hasErrors = true;
  }
  if (!values.password || values.password.trim() === '') {
    errors.password = 'Enter password';
    hasErrors = true;
  }
  return hasErrors && errors;
}

//submit login form
const submitLoginForm = (values, dispatch, props) => {

  //dispatch(signInUserSuccess(dummyData));
  return dispatch(signInUser(values))
    .then((result) => {
        dispatch(reset("SignInForm"));
        localStorage.setItem('jwtToken', result.value.data.responseData.token);
        dispatch(signInUserSuccess(result.value.data.responseData));
        if(result.value.data.responseData.type == 'merchant'){
          window.location.href = "/merchant/products/list?page=1";
        }else{
          window.location.href = "/admin/dashboard";
        }

    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }

      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  };


class SignInForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      is_submitting: false,
      isLoggedIn: typeof props.user.status === 'undefined' ? false : props.user.status
    }
  }

  componentDidMount(){


  }

  componentWillReceiveProps(nextProps){
    if(nextProps.user.status === 'authenticated'){

      if(nextProps.user.user.type == 'merchant'){
        window.location.href = "/merchant/products/list?page=1";
      }else{
        window.location.href = "/admin/dashboard";
      }

    }
  }

  render() {
    /*if (this.state.isLoggedIn === true) {
      return <Redirect to={{
        pathname: '/admin/dashboard',
        state: { from: this.props.location }
      }}/>
    }*/
    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <div className="loginForm">
        <h4 className="mb-1">Welcome</h4>
        <p className="marginB42">Login to your account</p>
        <form onSubmit={handleSubmit(submitLoginForm)} >
          <Field
            name="email"
            component={renderField}
            type="text"
            label="Email"
            placeholder="Your email address"
          />

          <Field
            name="password"
            component={renderField}
            type="password"
            placeholder="Your password"
            label="Password"
          />


          <button type="submit" disabled={submitting} className={`${"btn btn-primary btn-block hvr-shadow"} ${submitting?'btn-loader':''}`}>Login</button>


        </form>
        <div className="text-center marginT40">
            <Link to="/forgotpassword"  className="btn-link">Forgot Password</Link>
         </div>

        </div>
    )
  }
}

SignInForm = reduxForm({
  form: 'SignInForm',
  validate
})(SignInForm)

const mapStateToProps = (state, ownProps) => {
  return {
    user: {...state.user}
  }
}

export default connect(mapStateToProps)(SignInForm);
