import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {
	fetchDashboardAnalytics,
	fetchGraphicalAnalytics,
	fetchGraphicalAnalyticsConsignment,
	fetchGraphicalStateWiseAnalytics,
	fetchSalesPlatformAnalytics,
} from '../../actions/common';
import {CURRENCY, currencyFormat} from '../../constants';
import {toast} from 'react-toastify';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


class Dashboard extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
			selectedTab: 2,
			days: 7,
			analyticsData: null,
			graphData: null,
			graphDataConsignments: null,
			graphDataStateWise: null,
			graphOptions: [],
			graphOptionsConsignments: [],
			graphOptionsStateWise: [],
			totalOrders: null,
			totalRevenue: null,
			newCustomers: null
    }
  }

	selectTab(e, tab, days){
		if(e){
			e.preventDefault();
		}
		this.fetchGraphicalAnalytics(days);
		this.fetchDashboardAnalytics(days);
		this.fetchGraphicalAnalyticsConsignment(days)
		this.fetchGraphicalStateWiseAnalytics(days);
		this.fetchSalesPlatformAnalytics(days);
		this.setState({selectedTab: tab, days})
	}


	componentDidMount(){
		this.fetchDashboardAnalytics(this.state.days);
		this.fetchGraphicalAnalytics(this.state.days);
		this.fetchGraphicalAnalyticsConsignment(this.state.days)
		this.fetchGraphicalStateWiseAnalytics(this.state.days);
		this.fetchSalesPlatformAnalytics(this.state.days);
	}

	fetchDashboardAnalytics(days){
		this.setState({fetchingRecords: true});
    this.props.fetchDashboardAnalytics({days}).then((response) => {
			let data = response.value.data.responseData;
      this.setState({fetchingRecords: false, analyticsData: data, totalOrders: data.totalOrders, totalRevenue: data.totalRevenue, newCustomers: data.getNewCustomers});


		}).catch((error)=>{
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchGraphicalAnalytics(days){
		this.setState({fetchingRecords: true});
    this.props.fetchGraphicalAnalytics({days}).then((response) => {
			let graphOptions = {
				chart: {
			    	type: 'column'
			    },
			    title: {
			        text: 'Orders Data'
			    },
			    subtitle: {
			        text: ''
			    },
			    xAxis: {

			        crosshair: true
			    },
			    yAxis: {
			        min: 0,
			        title: {
			            text: 'Number of orders'
			        }
			    },
			    tooltip: {
			        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
			            '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
			        footerFormat: '</table>',
			        shared: true,
			        useHTML: true
			    },
					// tooltip: {
			    //     formatter: function () {
					// 			console.log('tttttt', this);
					// 			return '<span style="font-size:10px">'+this.series.data[this.point.index].y+'</span><table>'
			    //         // return 'The value for <b>' + this.x +
			    //         //     '</b> is <b>' + this.y + '</b>';
			    //     }
			    // },
			    plotOptions: {
			        column: {
			            pointPadding: 0.2,
			            borderWidth: 0
			        }
			    }

			};

			let gData = response.value.data.responseData;

			graphOptions = Object.assign(graphOptions, {series: gData.series, xAxis: {categories: gData.timelineToShow}})

      this.setState({fetchingRecords: false, graphData: response.value.data.responseData, graphOptions});


		}).catch((error)=>{
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchGraphicalAnalyticsConsignment(days){
		this.setState({fetchingRecords: true});
    this.props.fetchGraphicalAnalyticsConsignment({days}).then((response) => {
			let graphOptions = {
			    chart: {
			        plotBackgroundColor: null,
			        plotBorderWidth: null,
			        plotShadow: false,
			        type: 'pie'
			    },
			    title: {
			        text: "Consignment Data"
			    },
			    tooltip: {
			        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
			    },
			    accessibility: {
			        point: {
			            valueSuffix: '%'
			        }
			    },
			    plotOptions: {
			        pie: {
			            allowPointSelect: true,
			            cursor: 'pointer',
			            dataLabels: {
			                enabled: true,
			                format: '<b>{point.name}</b>: {point.y:f}'
			            }
			        }
			    }

			}

			let gData = response.value.data.responseData;

			graphOptions = Object.assign(graphOptions, {series: gData.series})

      this.setState({fetchingRecords: false, graphDataConsignments: gData, graphOptionsConsignments: graphOptions});


		}).catch((error)=>{
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchGraphicalStateWiseAnalytics(days){
		this.setState({fetchingRecords: true});
    this.props.fetchGraphicalStateWiseAnalytics({days}).then((response) => {
			let graphOptions = {
				chart: {
			    	type: 'column'
			    },
			    title: {
			        text: 'State Wise Data'
			    },
			    subtitle: {
			        text: ''
			    },
			    xAxis: {
			        crosshair: true
			    },
			    yAxis: {
			        min: 0,
			        title: {
			            text: 'Number of orders'
			        }
			    },
			    tooltip: {
			        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
			            '<td style="padding:0"><b>{point.y:f}</b></td></tr>',
			        footerFormat: '</table>',
			        shared: true,
			        useHTML: true
			    },
					// tooltip: {
			    //     formatter: function () {
					//
					// 			console.log('ttttttff', this);
					// 			return '<span style="font-size:10px">'+this.point.category+'</span><br/><table><tr><td style="color:'+this.series.color+';padding:0">'+this.series.name+': </td><td style="padding:0"><b>'+this.point.y+'</b></td></tr></table>';
			    //     }
			    // },
			    plotOptions: {
			        column: {
			            pointPadding: 0.2,
			            borderWidth: 0
			        }
			    }

			};

			let gData = response.value.data.responseData;

			graphOptions = Object.assign(graphOptions, {series: gData.series, xAxis: {categories: gData.timelineToShow}})

      this.setState({fetchingRecords: false, graphDataStateWise: gData, graphOptionsStateWise: graphOptions});


		}).catch((error)=>{
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchSalesPlatformAnalytics(days){
		this.setState({fetchingRecords: true});
    this.props.fetchSalesPlatformAnalytics({days}).then((response) => {
			let graphOptions = {
			    chart: {
			        plotBackgroundColor: null,
			        plotBorderWidth: null,
			        plotShadow: false,
			        type: 'pie'
			    },
			    title: {
			        text: "Platform Wise Data"
			    },
			    tooltip: {
			        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
			    },
			    accessibility: {
			        point: {
			            valueSuffix: '%'
			        }
			    },
			    plotOptions: {
			        pie: {
			            allowPointSelect: true,
			            cursor: 'pointer',
			            dataLabels: {
			                enabled: true,
			                format: '<b>{point.name}</b>: {point.y:f}'
			            }
			        }
			    }

			}

			let gData = response.value.data.responseData;

			graphOptions = Object.assign(graphOptions, {series: gData.series})

      this.setState({fetchingRecords: false, graphDataPlatforms: gData, graphOptionsPlatforms: graphOptions});


		}).catch((error) => {
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}







	render() {
		const {analyticsData} = {...this.state};
    return (
      <>
				<h5 className="mb-3 marginT20">Dashboard</h5>
				<section className="formBg" >
					<div style={{position: "relative", float: "none"}}>
						<div className="steps">
							<ul style = {{paddingLeft: 0}}>
								<li className={`${"step-1"} ${this.state.selectedTab == 1?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 1, 1)}><span>Today</span></a></li>
								<li className={`${"step-2"} ${this.state.selectedTab == 2?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 2, 7)}><span>Last 7 Days</span></a></li>
								<li className={`${"step-3"} ${this.state.selectedTab == 3?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 3, 30)}><span>Last 30 Days</span></a></li>
								<li className={`${"step-3"} ${this.state.selectedTab == 4?'active':''}`}><a href="#" onClick = {(e) => this.selectTab(e, 4, 365)}><span>Last 1 Year</span></a></li>
							</ul>
						</div>
						{/* <h5 className="mb-3">Order Analytics</h5> */}
						<div className="row">
	            <div className="col-md-6 col-xl-4">
	              <div className="card mb-3 widget-content bg-midnight-bloom">
	                <div className="widget-content-wrapper text-white">
	                  <div className="widget-content-left">
	                    <div className="widget-heading">Total Orders</div>
	                    <div className="widget-subheading">{this.state.selectedTab == 1? "Today":this.state.selectedTab == 2?"In last 7 days":"In last 30 days"}</div>
	                  </div>
	                  <div className="widget-content-right">
	                  	<div className="widget-numbers text-white"><span>{this.state.totalOrders}</span></div>
	                  </div>
	                </div>
	              </div>
	            </div>
	            <div className="col-md-6 col-xl-4">
	                <div className="card mb-3 widget-content bg-arielle-smile">
	                    <div className="widget-content-wrapper text-white">
	                        <div className="widget-content-left">
	                            <div className="widget-heading">Total Revenue</div>
	                            <div className="widget-subheading">{this.state.selectedTab == 1? "Today":this.state.selectedTab == 2?"In last 7 days":this.state.selectedTab == 3?"In last 30 days":"In last one year"}</div>
	                        </div>
	                        <div className="widget-content-right">
	                            <div className="widget-numbers text-white"><span>{this.state.totalRevenue != null && <><span style={{fontSize: '20px'}}>{CURRENCY}</span> {currencyFormat(parseFloat(this.state.totalRevenue).toFixed(0))}</>}</span></div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	            <div className="col-md-6 col-xl-4">
	                <div className="card mb-3 widget-content bg-grow-early">
	                    <div className="widget-content-wrapper text-white">
	                        <div className="widget-content-left">
	                            <div className="widget-heading">New Customers</div>
	                            <div className="widget-subheading">{this.state.selectedTab == 1? "Today":this.state.selectedTab == 2?"In last 7 days":this.state.selectedTab == 3?"In last 30 days":"In last one year"}</div>
	                        </div>
	                        <div className="widget-content-right">
	                            <div className="widget-numbers text-white"><span>{this.state.newCustomers}</span></div>
	                        </div>
	                    </div>
	                </div>
	            </div>

	        </div>
					<div className="row">
						<div className="col-lg-6">
							<div className="mb-3 card">
								{
									this.state.graphData != null &&
									<HighchartsReact
								    highcharts={Highcharts}
								    options={this.state.graphOptions}
								  />
								}

							</div>
						</div>
						<div className="col-lg-6">
							<div className="mb-3 card">
								{
									this.state.graphDataConsignments != null &&
									<HighchartsReact
								    highcharts={Highcharts}
								    options={this.state.graphOptionsConsignments}
								  />
								}

							</div>
						</div>
					</div>
					<div className="row">

						<div className="col-lg-6">
							<div className="mb-3 card">
								{
									this.state.graphDataStateWise != null &&
									<HighchartsReact
								    highcharts={Highcharts}
								    options={this.state.graphOptionsStateWise}
								  />
								}

							</div>
						</div>
						<div className="col-lg-6">
							<div className="mb-3 card">
								{
									this.state.graphDataPlatforms != null &&
									<HighchartsReact
								    highcharts={Highcharts}
								    options={this.state.graphOptionsPlatforms}
								  />
								}

							</div>
						</div>
					</div>


					</div>
				</section>
			</>
    );
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardAnalytics: (data) => {
      return dispatch(fetchDashboardAnalytics(data));
    },
		fetchGraphicalAnalytics: (data) => {
			return dispatch(fetchGraphicalAnalytics(data));
		},
		fetchGraphicalAnalyticsConsignment: (data) => {
			return dispatch(fetchGraphicalAnalyticsConsignment(data));
		},
		fetchGraphicalStateWiseAnalytics: (data) => {
			return dispatch(fetchGraphicalStateWiseAnalytics(data));
		},
		fetchSalesPlatformAnalytics: (data) => {
			return dispatch(fetchSalesPlatformAnalytics(data));
		}
  }
}


export default withRouter(connect(null, mapDispatchToProps)(Dashboard));
