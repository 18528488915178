import axios from 'axios';

import { API_ROOT } from '../constants';

export const UPLOAD_CSV = 'UPLOAD_CSV';
export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';
export const FETCH_FILE_UPLOAD_LOGS = 'FETCH_FILE_UPLOAD_LOGS';


export function uploadCsv(formValues) {
    const request = axios({
        method: 'POST',
        data: formValues,
        url: `${API_ROOT}/merchant/updateBulkInventory`
    });
    return {
        type: UPLOAD_CSV,
        payload: request
    };
}

export function downloadProductCsv(params) {
    const request = axios({
        method: 'GET',
        params: params,
        url: `${API_ROOT}/merchant/sampleCSV`
    });
    return {
        type: DOWNLOAD_CSV,
        payload: request
    };
}

export function getBulkInventoryLogs(params) {
    const request = axios({
        method: 'GET',
        params: params,
        url: `${API_ROOT}/merchant/getBulkInventoryLogs`
    });
    return {
        type: FETCH_FILE_UPLOAD_LOGS,
        payload: request
    };
}
