import React, { Component, Fragment } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset, FieldArray } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import {fetchProduct, fetchProductsForCompatibility, addProductCompatibility, fetchCompatibleProducts} from '../../../actions/products';
import {fetchPcComponents} from '../../../actions/categories';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select'
import axios from 'axios';
import { API_ROOT, renderPrice } from '../../../constants';


const promiseProductOptions = (inputValue) => {
  return (new Promise(resolve => {
    if (inputValue.length < 3) {
      resolve([]);
      return;
    }
    axios.get(`${API_ROOT}/admin/getProductsForCompatibility?name=${inputValue}`).then((response) => {
      resolve(response.data.responseData.data);
    })
  }))
}

class CompatibleProducts extends Component {
	constructor(props){
    super(props);
    this.state = {
			productId: props.match.params.productId?props.match.params.productId:null,
      defaultProduct: '',
      compatibleProductsArray: [],
      fetchingProductRequest: false,
      compatibleProductsIds: [],
      pcComponents: [],
      selectedPcComponent: null,
      productSearchResults: null,
      searchTerm: null
		};
    this.selectPcComponent = this.selectPcComponent.bind(this);
  }

  componentDidMount(){
    this.fetchPcComponents();
    this.fetchCompatibleProducts(this.state.productId);
		this.fetchProduct(this.state.productId);

  }

  fetchPcComponents(){
    this.props.fetchPcComponents().then((response) => {
      let pcComponents = response.value.data.responseData;
      this.setState({pcComponents});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  fetchCompatibleProducts(productId){
		this.setState({fetchingProductRequest: true});
    this.props.fetchCompatibleProducts({id: productId}).then((response) => {
      let compatibleProducts = response.value.data.responseData.data;
      let compatibleProductsArray= [];
      let compatibleProductsIds= [];
      if(compatibleProducts && compatibleProducts.length > 0){
        compatibleProducts.forEach((member, memberIndex) => {
          compatibleProductsArray.push(member);
          compatibleProductsIds.push(member.productId);
        });
      }
      this.setState({fetchingProductRequest: false, compatibleProductsArray, compatibleProductsIds});
      this.props.change('compatibleProductIds', compatibleProductsIds);
    }).catch((error)=>{
      this.setState({fetchingProductRequest: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  fetchProduct(productId){
		this.setState({fetchingProductRequest: true});
    this.props.fetchProduct({id: productId}).then((response) => {
      let productData = response.value.data.responseData.data;
      this.setState({fetchingProductRequest: false, productData});
    }).catch((error)=>{
      this.setState({fetchingProductRequest: false});
      if(error.response) {
        toast.error(error.response.data.message);
      }else if (error.request) {
        toast.error("Network error!");
      }else{
        toast.error(error.message);
      }
		});
	}


	componentWillReceiveProps(nextProps){

	}

  testOption(inputValue){
    return (new Promise(resolve => {
      if (inputValue.length < 3) {
        resolve([]);
        return;
      }
      axios.get(`${API_ROOT}/admin/getProductsForCompatibility?name=${inputValue}`).then((response) => {
        resolve(response.data.responseData.data);
      })
    }))
  }

	submitForm(values){
    values = Object.assign(values, {id: parseInt(this.state.productId), compatibleProductIds: this.state.compatibleProductsIds});
    console.log(values); //return;


		this.setState({submittingForm: true});
			this.props.addProductCompatibility(values).then((response) => {
				this.setState({submittingForm: false});
				toast.success(response.value.data.message);
        window.history.back();
				//this.props.history.push('/admin/products/list');
			}).catch((error)=>{
	      this.setState({submittingForm: false});
	      if(error.response) {
	        toast.error(error.response.data.message);
	      }else if (error.request) {
	        toast.error("Network error!");
	      }else {
	        toast.error(error.message);
	      }
			});
	}

  updateCompatibleProductsArray(record, index){
    let productSearchResults = this.state.productSearchResults;
    let productsArray = this.state.compatibleProductsArray;
    let productIds = this.state.compatibleProductsIds;

    productSearchResults.data.splice(index, 1);

    if(!(productIds.indexOf(record.productId) !== -1 || this.state.productId == record.productId)){
      productsArray.unshift(record);
      productIds.unshift(record.productId);
    }

    /*if(productIds.indexOf(record.productId) !== -1 || this.state.productId == record.productId){
      return;
    }
    productsArray.push(record);
    productIds.push(record.productId);*/
    this.setState({compatibleProductsArray: productsArray, compatibleProductsIds: productIds, productSearchResults: productSearchResults});
  }

  deleteCompatibleProduct(productId){
    let productsArray = this.state.compatibleProductsArray;
    let updatedProductsArray = [];
    let updatedProductIds = [];
    productsArray.forEach((obj, index) => {
      if(obj.productId != productId){
        updatedProductsArray.push(obj);
        updatedProductIds.push(obj.productId);
      }
    });
    this.setState({compatibleProductsArray: updatedProductsArray, compatibleProductsIds: updatedProductIds});
  }

  selectPcComponent(obj){
    this.setState({selectedPcComponent: obj.id});
  }

  fetchProductsForCompatibility(){
    if(!this.state.selectedPcComponent){
      toast.error("Please select a PC component");
      return;
    }
    if(!this.state.searchTerm || this.state.searchTerm.trim() == ""){
      toast.error("Please enter a product name to be searched");
      return;
    }
    let data = {productId: this.state.productId, componentId: this.state.selectedPcComponent, name: this.state.searchTerm};
    this.setState({fetchingProducts: true});
			this.props.fetchProductsForCompatibility(data).then((response) => {
				this.setState({fetchingProducts: false, productSearchResults: response.value.data.responseData });

			}).catch((error)=>{
	      this.setState({fetchingProducts: false});
	      if(error.response) {
	        toast.error(error.response.data.message);
	      }else if (error.request) {
	        toast.error("Network error!");
	      }else {
	        toast.error(error.message);
	      }
			});
  }




	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return(
			<>
				<ol className="breadcrumb breadcrumb-v1">
					<li className="breadcrumb-item"><Link to="/admin/product-management/products/list?page=1">Products</Link></li>
					<li className="breadcrumb-item active">Manage Compatible Products</li>
				</ol>
				<h5 className="h5 mb-3">Manage Compatible Products</h5>
        <div className="card">
          <div className="card-body card-padding">
            {
              this.state.fetchingProductRequest == true &&
              <div className="row">
                <div className="col-lg-12">
                  {
                    <Loader />
                  }
                </div>
              </div>
            }
            {
              this.state.fetchingProductRequest == false && this.state.productData &&
              <div className="row">
                <div className="col-lg-3">
                {
                  this.state.productData.featuredImage && this.state.productData.featuredImage[0] && <img src = {this.state.productData.featuredImage[0].filePath} />
                }
                </div>
                <div className="col-lg-4">
                  <div className="txt-info-blk">
                    <strong>Name</strong>
                    <p>{this.state.productData.name}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="txt-info-blk">
                    <strong>SKU</strong>
                    <p>{this.state.productData.sku}</p>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
				<div className="card" style = {{marginTop: "10px"}}>
					<div className="card-body card-padding">
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label>PC Component</label>
                  <Select
                    isMulti={false}
                    isSearchable={true}
                    name="componentId"
                    options={this.state.pcComponents}
                    getOptionLabel={opt => opt['name']}
                    getOptionValue={opt => opt['id']}
                    onChange={this.selectPcComponent}
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="form-group">
                  <label>Product Name</label>
                  <input className="form-control" type = "text" value = {this.state.searchTerm} name = "name" onChange = {(e) => this.setState({searchTerm: e.target.value})}/>
                </div>
              </div>
              <div className="col-lg-2">
                <button className={`${"btn btn-primary hvr-shadow"} ${this.state.fetchingProducts?'btn-loader':''}`} onClick = {() => this.fetchProductsForCompatibility()} style={{marginTop: '25px', padding: "10px"}}>Search</button>

              </div>
            </div>
							{/* <div className="row">
								<div className="col-lg-12">
									<AsyncSelect
                    closeMenuOnSelect={false}
                    isMulti = {true}
                    cacheOptions
                    getOptionLabel={opt => opt['title']}
                    getOptionValue={opt => opt['productId']}
                    // value={this.state.defaultProduct}
                    loadOptions={this.testOption}
                    onChange = {(record) => this.updateCompatibleProductsArray(record)}
                  />
								</div>
              </div> */}



					</div>
          {
            this.state.productSearchResults && this.state.productSearchResults.data && this.state.productSearchResults.data.length > 0 &&
          <div className="custom-table " style = {{borderRadius: '0px'}}>
            <div className="table-responsive">

                <table className="table mb-0">
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Published</th>
                    <th></th>
                  </tr>
                  {
                    this.state.productSearchResults.data.map((obj, index) => {
                      return(
                        <tr key = {obj.productId}>
                          <td>{obj.productId}</td>
                          <td>{(obj.featuredImage && obj.featuredImage.filePath)?<img style = {{width: "auto", height: "50px"}} src = {obj.featuredImage.filePath} />:""}</td>
                          <td>{obj.title}</td>
                          <td><ul>{renderPrice(obj.basePrice)}</ul></td>
                          <td>{obj.isPublished?<span className="green_status">Yes</span>:<span className="red_status">No</span>}</td>
                          <td>
                            <button className="btn btn-secondary hvr-shadow" title="Delete" onClick = {() => this.updateCompatibleProductsArray(obj, index)}>Add</button>

      										</td>
                        </tr>
                      )
                    })
                  }
                </table>

            </div>
          </div>
          }
        </div>
        <div className="card" style = {{marginTop: "10px"}}>
          <div className="row">
            <div className="col-lg-8">
              <h6 className="h6">Compatible Products</h6>
            </div>
            <div className="col-lg-4">
              {
                this.state.compatibleProductsIds && this.state.compatibleProductsIds.length > 0 &&
                <div class="form-btn-group mt-6" style = {{margin: '10px 10px 10px 10px'}}>
                  <button type="submit" className={this.state.submittingForm?"btn btn-primary hvr-shadow btn-loader":"btn btn-primary hvr-shadow"}>Update</button>
                </div>
              }

            </div>
          </div>

					<div className="custom-table mt-0" style = {{borderRadius: '0px'}}>
              <div className="table-responsive">
              {
                this.state.fetchingRecords == true && <Loader />
              }
              <table className="table mb-0" >
								<thead>
									<tr>
                    <th>ID</th>
                    <th>Image</th>
  									<th>Name</th>
                    <th>Price</th>
  									<th>Published?</th>
                    <th></th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.compatibleProductsArray && this.state.compatibleProductsArray.length > 0 &&
										this.state.compatibleProductsArray.map((obj, index) => {
											return(
												<tr key={obj.id}>
                          <td>{obj.productId}</td>
                          <td>{(obj.featuredImage && obj.featuredImage.filePath)?<img style = {{width: "auto", height: "50px"}} src = {obj.featuredImage.filePath} />:""}</td>
      										<td>{obj.title}</td>
                          <td><ul>{renderPrice(obj.basePrice)}</ul></td>
      										<td>{obj.isPublished?<span className="green_status">Yes</span>:<span className="red_status">No</span>}</td>
													<td>
														<button className="btn btn-secondary hvr-shadow" title="Delete" onClick = {() => this.deleteCompatibleProduct(obj.productId)}>Delete</button>
													</td>
												</tr>
											)
										})

									}
								</tbody>
							</table>
						</div>

					</div>
          <form onSubmit={ handleSubmit(this.submitForm.bind(this)) } >
            <div className="row">
              <div className="col-lg-12">
                <div class="form-btn-group mt-6" style = {{margin: '10px 10px 10px 10px'}}>
                  <a href = "javascript:;" className="btn btn-secondary hvr-shadow" onClick = {(e) => window.history.back()} >Cancel</a>
                  {
                    this.state.compatibleProductsIds && this.state.compatibleProductsIds.length > 0 &&
                    <button type="submit" className={this.state.submittingForm?"btn btn-primary hvr-shadow btn-loader":"btn btn-primary hvr-shadow"}>Update</button>
                  }

                </div>
              </div>
            </div>
          </form>
				</div>

			</>
    );
	}
}

CompatibleProducts = reduxForm({
  form: 'CompatibleProducts'
})(CompatibleProducts);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProduct: (data) => {
      return dispatch(fetchProduct(data));
    },
    fetchProductsForCompatibility: (data, apiPath, method) => {
      return dispatch(fetchProductsForCompatibility(data, apiPath, method));
    },
    addProductCompatibility: (data) => {
      return dispatch(addProductCompatibility(data));
    },
    fetchCompatibleProducts: (data) => {
      return dispatch(fetchCompatibleProducts(data));
    },
    fetchPcComponents: (data) => {
			return dispatch(fetchPcComponents(data));
		}

  }
}


export default withRouter(connect(null, mapDispatchToProps)(CompatibleProducts));
