import { IntlReducer as Intl } from 'react-redux-multilingual'
import { combineReducers } from 'redux';

import UserReducer from './reducer_user';


import { reducer as reduxFormReducer } from 'redux-form';

//import  StateReducer  from './reducer_state';



const appReducer = combineReducers({
  user: UserReducer,
  form: reduxFormReducer,
  Intl: Intl
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }
  return appReducer(state, action)
}

export default rootReducer;
