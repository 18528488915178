import React from 'react';
import { Component } from 'react';
import SignIn from './admin/SignIn';
import ForgotPassword from './admin/ForgotPassword';
import ResetPassword from './admin/ResetPassword';
import EnsureNotLoggedInContainer from '../containers/EnsureNotLoggedInContainer';

import { Switch, Route, BrowserRouter } from 'react-router-dom';
//import AppContainer from '../containers/AppContainer';

export default class App extends Component {

  render() {
    return (
    	<BrowserRouter>
	    	<Switch>
	    		<Route path="/signin" component={SignIn} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
	    		<Route path="/" component={EnsureNotLoggedInContainer} />
	    	</Switch>
    	</BrowserRouter>

    );
  }
}
