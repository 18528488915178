import React, { Component, PropTypes } from 'react';
const renderField = ({ input, id, labelClass, readOnly, autocomplete, initialValue, notes, innerParentDivClass, parentDivClass, fieldClass, label, type, placeholder,props, meta: {initial, touched, error, invalid, warning } }) => {
  //console.log('rrrrrr', initial);
return(
  <div className={ `${type != "hidden"?"form-group":''} ${parentDivClass ? parentDivClass : ' '} ${touched && error ? ' has-error ':''}`} >
    {(type != "hidden") && (<label className={labelClass?labelClass:""}>{label}</label>)}


      <input autoComplete = {autocomplete?autocomplete:'on'} {...input}  readOnly={readOnly} className={ ` ${fieldClass ? fieldClass:'  form-control  '}` } placeholder={placeholder?placeholder:""} type={type}/>

    {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
    {
    	notes && (<div cl  assName="help-notes"><small>{notes}</small></div>)
    }


</div>
)
}

export default renderField;
