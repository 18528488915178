import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';

import { fetchOrders } from '../../../actions/orders';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { currencyFormat } from '../../../constants';
import Confirm from 'react-confirm-bootstrap';

import $ from 'jquery';
import CancelOrderPopup from './CancelOrderPopup';

class OrdersList extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		let userId = 0;
		if (queryParams.userId) {
			userId = parseInt(queryParams.userId);
		}
		let uName = '';
		if (queryParams.uName) {
			uName = queryParams.uName;
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/orders/list',
			records: null,
			brands: [],
			categoryTree: [],
			userId: userId,
			uName: uName,
			openCancelOrderPopup: false,
			cancelOrderData: null,
			filters: [
				'orderId',
				'userId',
				'isCancel',
				'isReturn',
				'orderStatus',
				'isClosed',
				'customerName',
				'mobile'

			]
		};
		this.openCancelOrderPopup = this.openCancelOrderPopup.bind(this);
		this.closeCancelOrderPopup = this.closeCancelOrderPopup.bind(this);
	}


	openCancelOrderPopup(data) {
		this.setState({ openCancelOrderPopup: true, cancelOrderData: data });
	}

	closeCancelOrderPopup() {
		this.fetchOrders(this.state.params);
		this.setState({ openCancelOrderPopup: false, cancelOrderData: null });
	}



	componentDidMount() {
		this.fetchOrders(this.state.params);
	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	fetchOrders(params) {
		delete params.uName;
		this.setState({ fetchingRecords: true });
		this.props.fetchOrders(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			var queryParams = queryString.parse(nextProps.location.search);
			let userId = 0;
			if (queryParams.userId) {
				userId = parseInt(queryParams.userId);
			}
			let uName = '';
			if (queryParams.uName) {
				uName = queryParams.uName;
			}
			this.setState({ userId, uName });
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchOrders(params);
		}
	}






	submitFilterForm(values, dispatch, props) {
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}



	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		this.props.history.push(this.state.pageUrl + '?page=1');
	}


	

	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records } = { ...this.state }
		return (
			<>
				{
					this.state.userId
						?
						<div className="back-ui" > <a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
						:
						<></>
				}
				{
					this.state.userId
						?
						<ol className="breadcrumb breadcrumb-v1 marginB10">
							<li className="breadcrumb-item"><Link to="/admin/customers/list?page=1">Customers</Link></li>
							<li className="breadcrumb-item"><Link to={"/admin/customers/list/detail/" + this.state.userId}>{this.state.uName}</Link></li>
							<li className="breadcrumb-item"><Link to="/admin/orders/list?page=1">Orders</Link></li>
							<li className="breadcrumb-item active">List</li>
						</ol>
						:
						<ol className="breadcrumb breadcrumb-v1 ">
							<li className="breadcrumb-item"><Link to="/admin/orders/list?page=1">Orders</Link></li>
							<li className="breadcrumb-item active">List</li>
						</ol>
				}

				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">{this.state.userId > 0 ? "User Orders" : "Orders"}</h5>
					</div>
					{
						this.state.userId <= 0 &&
						<div className="col-md-8">
							<div className="jobs-act-btns">
								<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
									<span className="icon-filter mr-1"></span>
									Filters
								</a>
							</div>
						</div>
					}

				</div>
				<div className="collapse" id="filter" >
					<div className="filter-dropdown">
						<form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
							<div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="orderId"
										component={renderField}
										label="Order Id"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="customerName"
										component={renderField}
										label="Customer name"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="mobile"
										component={renderField}
										label="Mobile"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Is Cancelled"
										name='isCancel'
										optionLabel='name'
										optionValue='id'
										options={[{ id: 1, name: "Yes" }, { id: 0, name: "No" }]}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}

									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Is Returned"
										name='isReturn'
										optionLabel='name'
										optionValue='id'
										options={[{ id: 1, name: "Yes" }, { id: 0, name: "No" }]}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}

									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Payment Status"
										name='orderStatus'
										optionLabel='name'
										optionValue='id'
										options={[{ id: 1, name: "Payment awaiting" }, { id: 2, name: "Payment approved" }, { id: 3, name: "Payment declined" }, { id: 4, name: "Cash on delivery" }]}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}

									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Is Closed"
										name='isClosed'
										optionLabel='name'
										optionValue='id'
										options={[{ id: 1, name: "Yes" }, { id: 0, name: "No" }]}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}

									/>
								</div>
							</div>
							<div className="form-btn-group mt-3 text-right">
								<button type="button" onClick={(e) => this.clearFilters(e)} className="btn btn-clear hvr-shadow mr-1">Clear</button>
								<button type="submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
					</div>
				</div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>Order ID</th>
									<th>Date</th>
									<th>Total Amt.</th>
									<th>Customer</th>
									<th>Phone</th>
									<th>Payment Status</th>
									<th>Order Status</th>
									<th>Cancelled</th>
									<th>Return Request</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj._id}>
												<td className="nowrap"><Link to={"/admin/orders/list/detail/" + obj.orderId}>{obj.orderId}</Link></td>
												<td>{moment(obj.orderData.createdAt).format('DD/MM/Y')}</td>
												<td className="nowrap">{obj.orderData.currencyCode} <strong>{currencyFormat(parseFloat(obj.orderData.totalAmount).toFixed(2))}</strong></td>
												<td className="nowrap">{obj.userName}</td>
												<td className="nowrap">+{obj.userCountryCode} {obj.userMobile}</td>
												<td>{obj.orderStatus == 1 ? "Payment awaiting" : obj.orderStatus == 2 ? "Payment approved" : obj.orderStatus == 4 ? "Cash on delivery" : "Payment declined"}</td>
												<td>{obj.isClosed ? <span className="green_status">Close</span> : <span className="orange_status">Open</span>}</td>
												<td>{obj.isCancel ? <span className="red_status">Yes</span> : <span className="green_status">No</span>}</td>
												<td>{obj.isReturn ? <span className="red_status">Yes</span> : <span className="green_status">No</span>}</td>
												<td>
													<span className="dropdown threeDots">
														<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
															<span></span> <span></span> <span></span>
														</a>
														<ul className="dropdown-menu" role="menu">
															<li><Link className="dropdown-item" title="View" to={"/admin/orders/list/detail/" + obj.orderId} >View Detail</Link></li>
															{
																obj.isClosed
																	?
																	<></>
																	:
																	<li>

																		<button className="dropdown-item" onClick={() => this.openCancelOrderPopup(obj)}>Cancel Order</button>
																	</li>

															}
														</ul>
													</span>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
				{
					this.state.openCancelOrderPopup &&
					<CancelOrderPopup
						data={this.state.cancelOrderData}
						openPopup={this.state.openCancelOrderPopup}
						type="order"
						closePopup={this.closeCancelOrderPopup}
					/>
				}
			</>
		);
	}
}

OrdersList = reduxForm({
	form: 'OrdersListFilter'
})(OrdersList);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrders: (data) => {
			return dispatch(fetchOrders(data));
		}
	}
}


export default withRouter(connect(null, mapDispatchToProps)(OrdersList));
