import axios from 'axios';

import { API_ROOT } from '../constants';

export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNIN_USER = 'SIGNIN_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const ADD_USER = 'ADD_USER';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const UPDATE_NOTIFICATION_CHECK_TIME = 'UPDATE_NOTIFICATION_CHECK_TIME';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const ADD_ROLE = 'ADD_ROLE';

export const FETCH_ROLES = 'FETCH_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';

export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';

export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNUP_USER = 'SIGNUP_USER';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMER_DETAIL = 'FETCH_CUSTOMER_DETAIL';

export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';

export const FETCH_CUSTOMER_ADDRESSES = 'FETCH_CUSTOMER_ADDRESSES';

export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';

export const EDIT_USER = 'EDIT_USER';

const ROOT_URL = API_ROOT;

let token = null;



export function signInUser(formValues) {
  const request = axios.post(`${ROOT_URL}/admin/login`, formValues);

  return {
    type: SIGNIN_USER,
    payload: request
  };
}

export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  };
}



export function forgotPassword(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/admin/resetPasswordInitiate`

  });
  return {
    type: FORGOT_PASSWORD,
    payload: request
  };
}

export function resetPassword(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/resetPassword`
  });
  return {
    type: RESET_PASSWORD,
    payload: request
  };
}


export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}


export function changePassword(formValues) {

  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${ROOT_URL}/admin/changePassword`
  });
  return {
    type: CHANGE_PASSWORD,
    payload: request
  };
}



export function deleteUser(params) {

  //alert(token);
  const request = axios({
    method: 'DELETE',
    params: params,
    url: `${ROOT_URL}/deleteUser`
  });

  return {
    type: DELETE_USER,
    payload: request
  };
}

export function fetchUsers(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/user/list`
  });

  return {
    type: FETCH_USERS,
    payload: request
  };
}



export function fetchUser(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/fetchUser`
  });

  return {
    type: FETCH_USER,
    payload: request
  };
}



export function updateUserStatus(formValues) {

  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${ROOT_URL}/admin/user/updateStatus`
  });
  return {
    type: UPDATE_USER_STATUS,
    payload: request
  };
}



export function addUser(formValues) {

  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${ROOT_URL}/admin/user/create`
  });
  return {
    type: ADD_USER,
    payload: request
  };
}

export function fetchNotifications(params) {

  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getNotifications`
  });

  return {
    type: FETCH_NOTIFICATIONS,
    payload: request
  };
}

export function fetchMessages(params) {

  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getMessages`
  });

  return {
    type: FETCH_MESSAGES,
    payload: request
  };
}

export function updateNotificationCheckedTime(params) {

  const request = axios({
    method: 'post',
    data: params,
    url: `${ROOT_URL}/updateNotificationCheckedTime`
  });

  return {
    type: UPDATE_NOTIFICATION_CHECK_TIME,
    payload: request
  };
}

export function readNotification(params){

  const request = axios({
    method: 'POST',
    data: params,
    url: `${ROOT_URL}/readNotification`
  });
  return {
    type: READ_NOTIFICATION,
    payload: request
  };
}

export function addRole(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addRole";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${ROOT_URL}${apiPath}`
  });
  return {
    type: ADD_ROLE,
    payload: request
  };
}

export function fetchRoles(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getRoles`
  });

  return {
    type: FETCH_ROLES,
    payload: request
  };
}

export function fetchRole(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getRoleWithId`
  });

  return {
    type: FETCH_ROLE,
    payload: request
  };
}

export function deleteRole(params) {

  //alert(token);
  const request = axios({
    method: 'DELETE',
    data: params,
    url: `${ROOT_URL}/admin/deleteRole`
  });

  return {
    type: DELETE_ROLE,
    payload: request
  };
}


export function fetchPermissions(params) {

  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getPermissions`
  });

  return {
    type: FETCH_PERMISSIONS,
    payload: request
  };
}

export function fetchCustomers(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/customerList`
  });
  return {
    type: FETCH_CUSTOMERS,
    payload: request
  };
}

export function fetchCustomerDetail(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/customerDetail`
  });
  return {
    type: FETCH_CUSTOMER_DETAIL,
    payload: request
  };
}

export function fetchCustomerAddresses(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/customerAddresses`
  });
  return {
    type: FETCH_CUSTOMER_ADDRESSES,
    payload: request
  };
}

export function markNotificationAsRead(data){
  const request = axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/admin/markNotificationAsRead`
  });
  return {
    type: MARK_NOTIFICATION_AS_READ,
    payload: request
  };
}

export function fetchUserDetail(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/user/viewProfile`
  });
  return {
    type: FETCH_USER_DETAIL,
    payload: request
  };
}

export function editUser(data){
  const request = axios({
    method: 'PATCH',
    data: data,
    url: `${ROOT_URL}/admin/user/update`
  });
  return {
    type: EDIT_USER,
    payload: request
  };
}
