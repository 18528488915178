import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {Link, NavLink} from 'react-router-dom';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import {jsUcfirst} from '../../constants';
import renderReactSelect from '../FormFields/renderReactSelectForForm';
import {SOCKET_URL} from '../../constants';
import io from 'socket.io-client';
import {fetchNotifications, markNotificationAsRead} from '../../actions/users';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

class Header extends Component {
	constructor(props) {
    super(props);
		var defaultLang = localStorage.getItem('lang');
		this.socket = io.connect(`${SOCKET_URL}?token=${window.localStorage.jwtToken || ' '}`, {transports: [ 'polling', 'websocket']});
    this.state = {
      user: props.user,
			defaultLang: defaultLang?defaultLang:"en",
			records: [],
      notifications: null,
      page: 1,
      notificationCount: 0,
      messageCount: 0
    };
		this.updateLang = this.updateLang.bind(this);
  }

	updateLang(obj){
		localStorage.setItem('lang', obj.id);
		window.location.reload();
	}

  componentDidMount(){
		this.websocketStart();
		setTimeout(() => {
      window.$$('.notifications-parent').on('click', (e) => {
        this.fetchNotifications({page: 1}, false);
      });
    }, 500);
		this.props.change('lang', this.state.defaultLang);
    $(document).click(function(e){
      if(!$(e.target).parents('#search-results-dropdown').length){
        $('#search-results-dropdown').removeClass('active');
      };
    });

		$(".nav-hamburger").click(function(){
			$(".leftmenu").toggleClass('open');
			$(this).toggleClass('active');
		})
		
  }

	websocketStart(){
    this.socket.on("error", message => {
       //console.log('error3333',message);
     });
     this.socket.on("disconnect", message => {
       //console.log('disconnect3333',message);
     });
     this.socket.on("connecting", message => {
       //console.log('connecting3333',message);
     });
     this.socket.on("errorfromserver", message => {
       //console.log('error3333',message);
     });

     this.socket.on("orderNotification", message => {
       console.log('ttttttt', message);
       this.setState({notificationCount: message.NotificationCount});
     });
  }






  fetchNotifications(params, append){
    if(!params){
      params = {page:1};
    }
    this.setState({fetchingNotifications: true, notificationCount: 0});
    this.props.fetchNotifications(params).then((response) => {
      this.setState({fetchingNotifications: false});
      var payload = response.value.data.responseData;
      var page = parseInt(payload.page);
      if(payload.data.length > 0){
        page = parseInt(payload.page) + 1;
      }
      if(append){
        if(this.state.notifications && this.state.notifications.data){
           var notifications = this.state.notifications;
           var updatedData = notifications.data.concat(payload.data);
           notifications = Object.assign(notifications, {data: updatedData});
           this.setState({notifications: notifications, page});
        }else{

          this.setState({notifications: payload, page});
        }

      }else{

        this.setState({notifications: payload, page});
      }

    }).catch((error)=>{
        this.setState({fetchingNotifications: false});
        if (error.response) {
          //toast.error(error.response.data.flash_message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });
    }

    loadMoreNotifications(e){
      e.preventDefault();
      if(this.state.fetchingNotifications){
        return;
      }
      var page = this.state.page;
      var params = {page: page};
      this.fetchNotifications(params, true);
    }

		markNotificationAsRead(notificationId, index){
	    this.props.markNotificationAsRead({notificationId}).then((response) => {
				let notifications = this.state.notifications;
				notifications.data[index] = Object.assign(notifications.data[index], {isRead: 1});
	    }).catch((error)=>{
	        if (error.response) {
	          //toast.error(error.response.data.flash_message);
	        } else if (error.request) {
	          //toast.error("Network error!");
	        } else {
	          //toast.error(error.message);
	        }
	      });
	    }




  render() {
		return (
			<div className="row">
      <div className="col-md-3 col-3">
        <Link className="logo" to="/admin/dashboard">
          <img width="200" className="i" src="/assets/img/logo_header.png" alt="img"/>
        </Link>
      </div>
      <div className="col-md-9 col-9 pl-0">
				<div id="topNav">
          <nav className="navbar navbar-expand-sm justify-content-end p-0">
            <ul className="navbar-nav align-items-center">
							<li className="nav-item" style = {{width: "150px"}}>
								<form>
		              <Field
										name='lang'
										optionLabel='name'
		                optionValue='id'
		                options={[{id: 'en', name: 'English'}, {id: 'ar', name: 'Arabic'}]}
		                component={renderReactSelect}
		                multi={false}
										parentCallback = {this.updateLang}
		              />
								</form>
		          </li>
							{
								this.state.user.type && this.state.user.type.toLowerCase() != 'merchant' &&
												<li className="notification notifications-parent" >
					           			<a id = "noti-anchor" href="javascript:void(0);"   data-toggle="dropdown" className={this.state.notificationCount > 0?"notify drp-down":'drp-down'}><i><img src="/assets/img/school-bell.svg" alt=""/></i></a>
					           			<div className="notification-drop dropdown-menu dropdown-menu-xl py-0">
					             			<ul>
							               {

							                 this.state.fetchingNotifications &&
							                 <li key={0}>
							                   <Skeleton count={2} height={25} />
							                 </li>
							               }
							                {
							                  this.state.notifications && this.state.notifications.data && this.state.notifications.data.length > 0 &&
							                  this.state.notifications.data.map((obj, index) => {
							                    var notiLink = "/admin/orders/list/detail/"+obj.data.orderId;
							                    return(
							                      <li key = {obj._id}>
							                         <Link to={notiLink} onClick = {(e) => this.markNotificationAsRead(obj._id, index)}>
							                            {/* <figure className="org-color">{obj.profileImage && <img src={obj.profileImage} alt=""/>}</figure> */}
							                            <div className="notification-content">
							                               <h6 className={`${obj.isRead?'notification_read':''}`} dangerouslySetInnerHTML={{__html:obj.message}} />
							                               <span className="time">{moment(obj.createdAt).fromNow()}</span>
							                            </div>
							                         </Link>
							                      </li>
							                    )
							                  })
							                }


							                {
							                  !this.state.fetchingNotifications && this.state.notifications && this.state.notifications.data.length <= 0 &&
							                  <li>
							                    No notifications found.
							                  </li>
							                }

					              </ul>
					              {
					                this.state.notifications && this.state.notifications.loadMoreFlag == 1 &&
					                <div className="show-all">
					                   <a className={this.state.fetchingNotifications === true?'show-all btn-loader':'show-all'} href="#" onClick = {(e) => this.loadMoreNotifications(e)} >Load More</a>
					                </div>
					              }
					           </div>
					        </li>
								}
              <li className="nav-item">
                <span href="javascript://" className="dropdown profile-dropdown">
                  <button type="button" className="dropdown-toggle icon-arrow-down" data-toggle="dropdown">

										<img className="rounded-circle" src="/assets/img/default_user.png" alt="img" width="32" height="32" />
                    <span>{jsUcfirst(this.state.user.type)} Account</span>
                  </button>
                  <ul className="dropdown-menu">

                    <li>
                      <Link className="dropdown-item" to="/admin/change-password">Change Password</Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/logout">Logout</Link>
                    </li>
                  </ul>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>

		</div>


		);
  	}
}
const mapStatesToProps = (state, ownProps) => {
  return {
    user: state.user.user,
	};
}

const mapDispatchToProps = (dispatch) => {
  return {
		fetchNotifications: (data) => {
      return dispatch(fetchNotifications(data));
    },
		markNotificationAsRead: (data) => {
			return dispatch(markNotificationAsRead(data));
		}
	}
}

Header = reduxForm({
  form: 'LangDrpForm'
})(Header);


export default connect(mapStatesToProps,mapDispatchToProps)(Header);
