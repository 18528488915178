import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {fetchCustomerDetail, fetchCustomerAddresses} from '../../../actions/users';


import { toast } from 'react-toastify';



class CustomerDetail extends Component {
	constructor(props){
    super(props);
    this.state = {
      customerId: props.match.params.userId?props.match.params.userId:null,
      customerData: null,
			customerAddresses: null,
    }


  }


  componentDidMount(){
    this.fetchCustomerDetail(this.state.customerId);
		this.fetchCustomerAddresses(this.state.customerId);
  }






  fetchCustomerDetail(customerId){
		this.setState({fetchingCustomer: true});
    this.props.fetchCustomerDetail({customerId: customerId}).then((response) => {
      let customerData = response.value.data.responseData.data;


      this.setState({fetchingRecords: false, customerData});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchCustomerAddresses(customerId){
		this.setState({fetchingCustomer: true});
    this.props.fetchCustomerAddresses({customerId: customerId}).then((response) => {
      let customerAddresses = response.value.data.responseData.data;


      this.setState({fetchingRecords: false, customerAddresses});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  componentWillReceiveProps(nextProps){
  }

	render() {
    const {customerData, customerAddresses} = this.state;
    return (
      <>
				<div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      	<ol className="breadcrumb breadcrumb-v1">
        <li className="breadcrumb-item"><Link to="/admin/customers/list">Customers</Link></li>
        <li className="breadcrumb-item active">View</li>
      </ol>
      <h5 className="h5 mb-4">Customer Detail</h5>
			<div className="job-info order-info subsection-form">
				<h6 className="h6 marginB0">Basic Information</h6>
				<section className="formBg" >
					<div className="row">
						{
							customerData && customerData.attachment && customerData.attachment.filePath &&
							<div class=" col-lg-3">
								<div class="form-group">
									<a href = {customerData.attachment.filePath} target = "_blank">
									<div className="frame" style={{backgroundImage: "url("+customerData.attachment.filePath+")"}}>

									</div>
									</a>
								</div>
							</div>
						}

						<div class=" col-lg-3">
							<div class="form-group">
								<label><strong>Name</strong></label>
								<span class="data-span">{customerData && customerData.name}</span>
							</div>
						</div>
						<div class=" col-lg-3">
							<div class="form-group">
								<label><strong>Mobile</strong></label>
								<span class="data-span">+{customerData && customerData.countryCode} {customerData && customerData.mobile}</span>
							</div>
						</div>
						<div class=" col-lg-3">
							<div class="form-group">
								<label><strong>Email</strong></label>
								<span class="data-span">{customerData && customerData.email}</span>
							</div>
						</div>
						<div class=" col-lg-3">
							<div class="form-group">
								<label><strong>Referral Code</strong></label>
								<span class="data-span">{customerData && customerData.referralCode}</span>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div className="job-info order-info subsection-form">
				<h6 className="h6 marginB0">Saved Addresses</h6>
				<section className="formBg" >
					<div className="row">
						{
							customerAddresses && customerAddresses.length > 0 &&
							customerAddresses.map((obj, index) => {
								return(
									<div class=" col-lg-4" key = {index}>
										<div className="address-section">
										  <h6>{obj.fullName}</h6>
										  <p>{obj.address}, {obj.city}, {obj.state} {obj.zipcode?"-"+obj.zipcode:''}<br/><b>Landmark:</b> {obj.landmark}</p>
											<p><b>Phone number</b> {obj.mobileNumber}</p>
											<p><b>Address Type</b> {obj.addressType == 1?"Home": obj.addressType == 2?"Work": "Other"}</p>
										</div>
									</div>
								)
							})
						}
					</div>
				</section>
			</div>

    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomerDetail: (data) => {
      return dispatch(fetchCustomerDetail(data));
    },
		fetchCustomerAddresses: (data) => {
			return dispatch(fetchCustomerAddresses(data));
		}

  }
}

CustomerDetail = connect(null, mapDispatchToProps)(CustomerDetail);



export default withRouter(CustomerDetail);
