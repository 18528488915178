import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import { fetchProducts, deleteProduct, updateProductPublishStatus, updateProductFeaturedStatus } from '../../../actions/products';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import { getCategoryLevelDashes, renderPrice } from '../../../constants';
import { fetchCategoryTree, fetchPcComponents } from '../../../actions/categories';
import { fetchAllBrands } from '../../../actions/brands';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import $ from 'jquery';
import { ExportToCsv } from 'export-to-csv';

const options = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	showTitle: true,
	title: 'Product List',
	useTextFile: false,
	useBom: true,
	useKeysAsHeaders: true,
 };
 
 const csvExporter = new ExportToCsv(options);

class ProductsList extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/product-management/products/list',
			records: null,
			brands: [],
			categoryTree: [],
			pcComponents: [],
			filters: [
				'name',
				'brandId',
				'categoryId',
				'componentId',
				'productId'
			]
		};
	}



	componentDidMount() {
		this.fetchPcComponents();
		this.fetchAllBrands();
		this.fetchCategoryTree();
		this.fetchProducts(this.state.params);
		this.updateFilterForm(this.state.params);

	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}

	fetchPcComponents() {
		this.props.fetchPcComponents().then((response) => {
			let pcComponents = response.value.data.responseData;
			this.setState({ pcComponents });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	fetchAllBrands() {
		this.props.fetchAllBrands().then((response) => {
			let brands = response.value.data.responseData.data;
			this.setState({ brands: brands });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	fetchProducts(params) {
		this.setState({ fetchingRecords: true });
		this.props.fetchProducts(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchProducts(params);
		}
	}

	deleteRecord(recordid) {
		this.props.deleteProduct({ id: recordid }).then((response) => {
			toast.success(response.value.data.message);
			this.fetchProducts(this.state.params);
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}


	publishRecord(productId, status, index) {
		var records = this.state.records;
		this.props.updateProductPublishStatus({ id: productId, status }).then((response) => {
			records.data[index].isPublished = status;
			this.setState({ records: records });
			toast.success(response.value.data.message);

		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}

	updateProductFeaturedStatus(productId, status, index) {
		var records = this.state.records;
		this.props.updateProductFeaturedStatus({ id: productId, status }).then((response) => {
			records.data[index].featuredProduct = status;
			this.setState({ records: records });
			toast.success(response.value.data.message);
		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}

	submitFilterForm(values, dispatch, props) {
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}

	fetchCategoryTree() {
		this.setState({ fetchingRecords: true });
		this.props.fetchCategoryTree().then((response) => {
			let records = response.value.data.responseData;
			let categoryTree = [];
			if (records) {
				for (var i = 0; i < records.length; i++) {

					categoryTree.push({ id: records[i].id, categoryName: records[i].name, isDisabled: true });
					for (var k = 0; k < records[i].children.length; k++) {
						categoryTree.push({ id: records[i].children[k].id, categoryName: records[i].children[k].name, isDisabled: false });
					}
				}
			}
			this.setState({ categoryTree });

		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		this.setState({ params: { page: 1 } });
		this.props.history.push(this.state.pageUrl + '?page=1');
	}

	exportToCsv(data) {
		let newData = [];
		// const STATUS = ['PENDING', 'APPROVED', 'DECLINED']
		for (let element of data) {
		   newData.push({
			  'Product Id': element.productId,
			  "SKU": element.sku,
			  "Title": element.title,
			//   'Id': element.id,	
			  "Name": element.name,
		   })
		}
		csvExporter.generateCsv(newData);
	 }

	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records } = { ...this.state }
		return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/product-management/products/list?page=1">Products</Link></li>
					<li className="breadcrumb-item active">List</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">Products</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
								<span className="icon-filter mr-1"></span>
								Filters
							</a>
							<Link to="/admin/product-management/products/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add Product</Link>&nbsp;
							{
								records && records.data && records.data.length && 
							<a to="" className="btn-add ml-1  hvr-shadow" style={{cursor: 'ponter', color: '#229E71'}}
							onClick={() => this.exportToCsv(records.data)}><span className="icon-ic_plus"></span>Export CSV</a>
							}
						</div>
					</div>
				</div>
				<div className="collapse" id="filter" >
					<div className="filter-dropdown">
						<form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
							<div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="productId"
										component={renderField}
										label="Product Id"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Sub-category"
										name='categoryId'
										optionLabel='categoryName'
										optionValue='id'
										options={this.state.categoryTree}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}
										isClearable={true}

									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Brand"
										name='brandId'
										optionLabel='name'
										optionValue='id'
										options={this.state.brands}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}
										isClearable={true}
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="PC component"
										name='componentId'
										optionLabel='name'
										optionValue='id'
										options={this.state.pcComponents}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}
										isClearable={true}
									/>
								</div>
							</div>
							<div className="form-btn-group mt-3 text-right">
								<button type="button" onClick={(e) => this.clearFilters(e)} className="btn btn-clear hvr-shadow mr-1">Clear</button>
								<button type="submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
					</div>
				</div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID/SKU</th>

									<th>Image</th>
									{/* <th>Name</th> */}
									<th>Title</th>
									<th>Qty</th>
									<th>Price</th>
									<th>Category</th>
									<th>Sub-category</th>
									<th>Brand</th>
									<th>Published?</th>
									<th>Featured?</th>
									<th>Views</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj._id}>
												<td className="nowrap">{obj.productId}<br /><Link to={"/admin/product-management/products/list/view/" + obj.productId}>{obj.sku}</Link></td>
												<td>{(obj.featuredImage && obj.featuredImage.filePath) ? <img style={{ "maxWidth": "none", width: "auto", height: "50px" }} src={obj.featuredImage.filePath} /> : ""}</td>
												{/* <td>{obj.name}</td> */}
												<td className="nowrap">{obj.title}</td>
												<td>{obj.quantity}</td>
												<td><ul>{renderPrice(obj.basePrice)}</ul></td>
												<td className="nowrap">{obj.parentCategory}</td>
												<td className="nowrap">{obj.categoryName}</td>
												<td>{obj.brandName}</td>
												{/*<td>{obj.isPublished?<span className="green_status">Yes</span>:<span className="red_status">No</span>}</td>*/}
												<td>
													<label className="switch">
														<input type="checkbox" checked={obj.isPublished ? true : false} onChange={() => this.publishRecord(obj.productId, obj.isPublished ? 0 : 1, index)} />
														<span className="slider round"></span>
													</label>
												</td>
												{/*<td>{obj.featuredProduct?<span className="green_status">Yes</span>:<span className="red_status">No</span>}</td>*/}
												<td><label className="switch">
													<input type="checkbox" checked={obj.featuredProduct ? true : false} onChange={() => this.updateProductFeaturedStatus(obj.productId, obj.featuredProduct ? 0 : 1, index)} />
													<span className="slider round"></span>
												</label></td>
												<td>{obj.productViews}</td>
												<td>
													<span className="dropdown threeDots">
														<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
															<span></span> <span></span> <span></span>
														</a>
														<ul className="dropdown-menu" role="menu">
															<li><Link className="dropdown-item" title="View" to={"/admin/product-management/products/list/view/" + obj.productId} >View</Link></li>
															<li><Link className="dropdown-item" title="Edit" to={"/admin/product-management/products/list/edit/" + obj.productId} >Edit</Link></li>
															<li><Link className="dropdown-item" title="Manage Gallery" to={"/admin/product-management/products/list/manageGallery/" + obj.productId} >Manage Gallery</Link></li>
															<li><Link className="dropdown-item" title="Manage Video" to={"/admin/product-management/products/list/manageVideo/" + obj.productId} >Manage Video</Link></li>
															<li><Link className="dropdown-item" title="Compatible Products" to={"/admin/product-management/products/list/compatibleProducts/" + obj.productId} >Compatible Products</Link></li>
															<li><Confirm className="confirmPopup"
																onConfirm={() => this.deleteRecord(obj.productId)}
																body="Are you sure you want to delete this?"
																confirmText="Confirm Delete"
																title="Delete!">
																<button className="dropdown-item">Delete</button>
															</Confirm></li>
														</ul>
													</span>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
			</>

		);
	}
}

ProductsList = reduxForm({
	form: 'ProductsListFilter'
})(ProductsList);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProducts: (data) => {
			return dispatch(fetchProducts(data));
		},
		deleteProduct: (data) => {
			return dispatch(deleteProduct(data));
		},
		updateProductPublishStatus: (data) => {
			return dispatch(updateProductPublishStatus(data));
		},
		updateProductFeaturedStatus: (data) => {
			return dispatch(updateProductFeaturedStatus(data));
		},
		fetchCategoryTree: (data) => {
			return dispatch(fetchCategoryTree(data));
		},
		fetchAllBrands: (data) => {
			return dispatch(fetchAllBrands(data));
		},
		fetchPcComponents: (data) => {
			return dispatch(fetchPcComponents(data));
		}
	}
}


export default withRouter(connect(null, mapDispatchToProps)(ProductsList));
