import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import renderSelect from '../../FormFields/renderSelect';
import {fetchQuestions, deleteQuestion, approveQuestion} from '../../../actions/questionAnswers';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import $ from 'jquery';

class QuestionsList extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.page){
			queryParams.page = 1;
		}
		this.state = {
			params: queryParams,
			records: null,
      isApprovedOptions: [{id: 0, name: "No"}, {id: 1, name: "Yes"}],
			pageUrl: '/admin/manage-question-answers/questions/list',
      filters: [
				'question',
				'status'
			]
		};
  }



	componentDidMount(){
		this.fetchQuestions(this.state.params);
	}

	fetchQuestions(params){
		this.setState({fetchingRecords: true});
    this.props.fetchQuestions(params).then((response) => {
			this.setState({records: response.value.data.responseData});
      this.setState({fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.fetchQuestions(params);
		}
	}

  deleteRecord(recordid){
    this.props.deleteQuestion({questionId: recordid}).then((response) => {
      toast.success(response.value.data.message);
      this.fetchQuestions(this.state.params);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.flash_message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

  approveRecord(questionId, status, index){
		var records = this.state.records;
		this.props.approveQuestion({questionId: questionId, isApproved: status}).then((response) => {
			records.data[index].isApproved = status;
			this.setState({records: records});
      toast.success(response.value.data.message);

    }).catch((error)=>{
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
	}
  submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?page=1');
	}

	render() {
    const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state};
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1">
        	<li className="breadcrumb-item"><Link to="/admin/manage-question-answers/questions/list">Questions</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Questions</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
            </div>
          </div>
        </div>
        <div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-4 col-lg-6 cust-col">
									<Field
										name="question"
										component={renderField}
										label="Question Contains"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Is Approved"
		                name='status'
		                optionLabel='name'
		                optionValue='id'
		                options={this.state.isApprovedOptions}
		                component={renderReactSelect}
		                placeholder="Select"
		                multi={false}
										isClearable={true}

		              />
								</div>

							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
                  <th>Image</th>
                  <th>Product</th>
									<th>Question</th>
                  <th>User</th>
                  <th>Is Approved</th>
                  <th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.data && records.data.length > 0 &&
							records.data.map((obj, index) => {
                return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
                    <td>{(obj.featuredImage && obj.featuredImage.filePath)?<img style = {{width: "auto", height: "50px"}} src = {obj.featuredImage.filePath} />:""}</td>
                    <td>{obj.title}</td>
										<td>{obj.question}</td>
                    <td>{obj.questionerName}</td>
                    <td>
											<label className="switch">
												<input type="checkbox" checked = {obj.isApproved?true:false} onChange = {() => this.approveRecord(obj.id, obj.isApproved?0:1, index)} />
											  <span className="slider round"></span>
											</label>
										</td>
                    <td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Confirm className="confirmPopup"
                              onConfirm={() => this.deleteRecord(obj.id)}
                              body="Are you sure you want to delete this?"
                              confirmText="Confirm Delete"
                              title="Delete!">
                              <button className="dropdown-item">Delete</button>
                          </Confirm></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.data && this.state.records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			}
			</>

    );
	}
}

QuestionsList = reduxForm({
  form: 'QuestionsListFilter'
})(QuestionsList);


const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuestions: (data) => {
      return dispatch(fetchQuestions(data));
    },
    deleteQuestion: (data) => {
      return dispatch(deleteQuestion(data));
    },
    approveQuestion: (data) => {
      return dispatch(approveQuestion(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(QuestionsList));
