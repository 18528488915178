import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {fetchCategoryTree, fetchAttributes} from '../../../actions/categories';
import queryString from 'query-string';
import {fetchCouponRuleDetail} from '../../../actions/coupons';
import {COUPON_FOR, COUPON_TYPES, WEEK_DAYS, COUPON_DISCOUNT_TYPES} from '../../../constants';
import moment from 'moment';
import { toast } from 'react-toastify';

class CouponRuleDetail extends Component {
	constructor(props){
    super(props);
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
    this.state = {
      dataLoaded: false,
      couponRuleId: props.match.params.couponRuleId?props.match.params.couponRuleId:null,
      couponData: null,
      couponTypes: COUPON_TYPES[lang],
      couponDiscountTypes: COUPON_DISCOUNT_TYPES[lang],
      couponFor: COUPON_FOR[lang],
      weekDays: WEEK_DAYS[lang]
    }


  }

  getCouponDiscountTypes(option){
    for(var i = 0; i < this.state.couponDiscountTypes.length; i++){
      if(this.state.couponDiscountTypes[i].id == option){
        return this.state.couponDiscountTypes[i].name;
      }
    }
	}

  getCouponTypeOption(option){
    for(var i = 0; i < this.state.couponTypes.length; i++){
      if(this.state.couponTypes[i].id == option){
        return this.state.couponTypes[i].name;
      }
    }
	}

  getCouponForOption(option){
    for(var i = 0; i < this.state.couponFor.length; i++){
      if(this.state.couponFor[i].id == option){
        return this.state.couponFor[i].name;
      }
    }
	}

  getWeekDaysOption(option){
    for(var i = 0; i < this.state.weekDays.length; i++){
      if(this.state.weekDays[i].id == option){
        return this.state.weekDays[i].name;
      }
    }
	}


  componentDidMount(){
    this.fetchCouponRuleDetail(this.state.couponRuleId);
  }

  fetchCouponRuleDetail(couponRuleId){
		this.setState({fetchingRecords: true});
    this.props.fetchCouponRuleDetail({ruleId: couponRuleId}).then((response) => {
      let couponData = response.value.data.responseData.coupons;
      this.setState({fetchingRecords: false, couponData});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}


  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const {couponData} = this.state;
    return (
      <>
			<div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1">
        <li className="breadcrumb-item"><Link to="/admin/coupons/list">Coupon Rule Detail</Link></li>
        <li className="breadcrumb-item active">View</li>
      </ol>
        <h5 className="h5 mb-4">View Detail</h5>
        {
          couponData &&
          <section className="formBg" style={{maxWidth:"850px"}}>
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Name</strong></label>
                  <span className="data-span">{couponData.couponRuleName}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Coupon Type</strong></label>
                  <span className="data-span">{this.getCouponTypeOption(couponData.couponType)}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Publish Status</strong></label>
                  <span className="data-span">{couponData.couponStatus?'Active': 'Inactive'}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-12">
                <div className="form-group">
                  <label><strong>Description</strong></label>
                  <span className="data-span">{couponData.couponRuleDescription}</span>
                </div>
              </div>
            </div>
            {
              (couponData.couponType == 3 || couponData.couponType == 1) &&
              <>
                <div className="row">
                  {
                    couponData.couponAutoGenerate != 1 &&
                    <div className=" col-lg-4">
                      <div className="form-group">
                        <label><strong>Coupon Code</strong></label>
                        <span className="data-span">{couponData.couponCode}</span>
                      </div>
                    </div>
                  }



                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>Uses per User</strong></label>
                      <span className="data-span">{couponData.couponUsesPerUser}</span>
                    </div>
                  </div>


                  <div className=" col-lg-4">
                    <div className="form-group">
                      <label><strong>Uses per Coupon</strong></label>
                      <span className="data-span">{couponData.couponUsesPerCoupon}</span>
                    </div>
                  </div>
                </div>
                {
                  couponData.couponAutoGenerate == 1 &&
                  <>
                    <div className="row">
                      <div className=" col-lg-4">
                        <div className="form-group">
                          <label><strong>Coupon Quantity</strong></label>
                          <span className="data-span">{couponData.couponQuantity}</span>
                        </div>
                      </div>
                      <div className=" col-lg-4">
                        <div className="form-group">
                          <label><strong>Code Length</strong></label>
                          <span className="data-span">{couponData.couponCodeLength}</span>
                        </div>
                      </div>
                      <div className=" col-lg-4">
                        <div className="form-group">
                          <label><strong>Code Format</strong></label>
                          <span className="data-span">{couponData.couponCodeFormat}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-lg-4">
                        <div className="form-group">
                          <label><strong>Coupon Prefix</strong></label>
                          <span className="data-span">{couponData.couponCodePrefix}</span>
                        </div>
                      </div>
                      <div className=" col-lg-4">
                        <div className="form-group">
                          <label><strong>Code Suffix</strong></label>
                          <span className="data-span">{couponData.couponCodeSuffix}</span>
                        </div>
                      </div>
                      <div className=" col-lg-4">
                        <div className="form-group">
                          <label><strong>Dash Every X Characters</strong></label>
                          <span className="data-span">{couponData.couponCodeDash}</span>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </>
            }




            <div className="row">
              <div className=" col-lg-12">
                <div className="form-group">
                  <label><strong>Coupon Validity</strong></label>
                  <span className="data-span">{moment(couponData.couponStartDate).format("DD MMM, Y")} {couponData.startTime} to {moment(couponData.couponEndDate).format("DD MMM, Y")} {couponData.endTime}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Coupon Valid for (Users)</strong></label>
                  <span className="data-span">{couponData.couponApplicablePlatform == 1?"All Users":couponData.couponApplicablePlatform == 2?"Website Users":couponData.couponApplicablePlatform == 3?"App Users":''}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Coupon Valid for Days</strong></label>
                  <span className="data-span">
                    {
                      couponData.couponApplicableDays == 1
                      ?
                      "All Days"
                      :
                      <>
                        {
                          couponData.dayOfWeek && couponData.dayOfWeek.length > 0 &&
                          couponData.dayOfWeek.map((obj, index) => {
                            return(
                              <span key = {index}>{index > 0?", "+this.getWeekDaysOption(obj):this.getWeekDaysOption(obj)}</span>
                            )
                          })
                        }
                      </>

                    }
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Priority</strong></label>
                  <span className="data-span">{couponData.couponPriority}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Can be used with promotional offers?</strong></label>
                  <span className="data-span">{couponData.couponUsedWithOther?"Yes":"No"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Coupon to be applied on</strong></label>
                  <span className="data-span">{this.getCouponForOption(couponData.couponFor)}</span>
                </div>
              </div>
              <div className=" col-lg-8">
                <div className="form-group">
                  <label><strong></strong></label>
                  <span className="data-span">
                    {
                      couponData.entityData && couponData.entityData.length > 0 &&
                      couponData.entityData.map((obj, index) => {
                        return(
                          <span key = {index}>{index > 0?", ":''}{obj.name}</span>
                        )
                      })
                    }
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Minimum Subtotal</strong></label>
                  <span className="data-span">{couponData.minimumSubTotal}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Discount Type</strong></label>
                  <span className="data-span">{this.getCouponDiscountTypes(couponData.discountType)}</span>
                </div>
              </div>
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Discount</strong></label>
                  <span className="data-span">{couponData.discount}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-lg-4">
                <div className="form-group">
                  <label><strong>Maximum Discount</strong></label>
                  <span className="data-span">{couponData.couponMaxDiscount}</span>
                </div>
              </div>
            </div>

              <div className="row">
							{
	              couponData.promotionImage && couponData.promotionImage.filePath &&
                <div className=" col-lg-4">
                  <div className="form-group">
										<label><strong>App Image</strong></label>
                    <img src = {couponData.promotionImage.filePath} />
                  </div>
                </div>
							}
							{
	              couponData.promotionImageWeb && couponData.promotionImageWeb.filePath &&
                <div className=" col-lg-4">
                  <div className="form-group">
										<label><strong>Web Image</strong></label>
                    <img src = {couponData.promotionImageWeb.filePath} />
                  </div>
                </div>
							}
              </div>


            <div className="form-btn-group">
              <a href="javascript:;" className="btn btn-secondary hvr-shadow" onClick = {(e) => window.history.back()}>Back</a>
            </div>
          </section>
        }

      </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCouponRuleDetail: (data) => {
      return dispatch(fetchCouponRuleDetail(data));
    }

  }
}

CouponRuleDetail = connect(null, mapDispatchToProps)(CouponRuleDetail);



export default withRouter(CouponRuleDetail);
