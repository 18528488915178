
import {
  VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAILURE,
	SIGNUP_USER,
	SIGNIN_USER_PENDING, SIGNIN_USER_SUCCESS,  SIGNIN_USER_FAILURE,
	LOGOUT_USER,


  PASSWORD_RESET,
  UPDATE_USER_PROFILE_FAILURE,

  TWITTER_LOGIN, TWITTER_LOGIN_PENDING, TWITTER_LOGIN_SUCCESS, TWITTER_LOGIN_FAILURE,
  FETCH_USER_PERMISSIONS_SUCCESS
} from '../actions/users';

import {FromatValidationErrors} from "../constants";



const INITIAL_STATE = {user: null, status:null, error:null, loading: false};

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case SIGNIN_USER_SUCCESS://return authenticated user,  make loading = false and status = authenticated
      return { ...state, user: action.payload, status:'authenticated', error:null, loading: false}; //<-- authenticated

    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
}
