import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import {connect} from 'react-redux';
import Header from './Header';
import LeftNavigation from './LeftNavigation';
import { PermissibleRender } from '@brainhubeu/react-permissible';

import {ROUTES} from '../../routesList';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from './Dashboard';



import UsersList from './users/UsersList';
import UsersCreate from './users/UsersCreate';
import UsersEdit from './users/UsersEdit';

import UserDetail from './users/UserDetail';

import RolesList from './roles/RolesList';
import RolesAdd from './roles/RolesAdd';

import CurrenciesList from './currencies/CurrenciesList';
import CurrenciesAdd from './currencies/CurrenciesAdd';

import CategoriesList from './categories/CategoriesList';
import CategoriesAdd from './categories/CategoriesAdd';

import ProductsList from './products/ProductsList';
import ProductsAdd from './products/ProductsAdd';
import ProductsEdit from './products/ProductsEdit';
import ProductsManageGallery from './products/ProductsManageGallery';
import ProductsManageVideo from './products/ProductsManageVideo';
import ProductsView from './products/ProductsView';

import OrdersList from './orders/OrdersList';
import OrderDetail from './orders/OrderDetail';

import TransactionsList from './transactions/TransactionsList';

import CompatibleProducts from './products/CompatibleProducts';

import AttributesManage from './categories/AttributesManage';

import BrandsList from './brands/BrandsList';
import BrandsAdd from './brands/BrandsAdd';

import BannersList from './banners/BannersList';
import BannersAdd from './banners/BannersAdd';

import CouponsList from './coupons/CouponsList';
import CouponsAdd from './coupons/CouponsAdd';
import CouponsEdit from './coupons/CouponsEdit';

import AttributeGroupsList from './attributeGroups/AttributeGroupsList';
import AttributeGroupsAdd from './attributeGroups/AttributeGroupsAdd';

import CouponRuleDetail from './coupons/CouponRuleDetail';

import ChangePassword from './ChangePassword';
import CouponCodesList from './coupons/CouponCodesList';

import QuestionsList from './questionAnswers/QuestionsList';
import AnswersList from './questionAnswers/AnswersList';

import CustomersList from './customers/CustomersList';
import CustomerDetail from './customers/CustomerDetail';

import ManageSettings from './settings/ManageSettings';
import ManagePage from './settings/ManagePage';

import PriceGridList from './settings/PriceGridList';
import AddPriceGrid from './settings/AddPriceGrid';

import LiabilityReport from './reportsFinance/LiabilityReport';
import LogisticsReport from './reportsFinance/LogisticsReport';
import PaymentsReport from './reportsFinance/PaymentsReport';
import RevenueReport from './reportsFinance/RevenueReport';

import ProductPerformanceReport from './reportsOperations/ProductPerformanceReport';
import GoodsReturnedReport from './reportsOperations/GoodsReturnedReport';
import StockPerformanceReport from './reportsOperations/StockPerformanceReport';
import ConsignmentsReport from './reportsOperations/ConsignmentsReport';

import AbandonedCartsReport from './reportsMarketing/AbandonedCartsReport';
import NewVsReturingUsersReport from './reportsMarketing/NewVsReturingUsersReport';
import CustomerReport from './reportsMarketing/CustomerReport';
import TopKeywordsReport from './reportsMarketing/TopKeywordsReport';

import SalesByCouponCodeReport from './reportsSales/SalesByCouponCodeReport';
import SalesByCouponUserReport from './reportsSales/SalesByCouponUserReport';
import SalesByCustomerReport from './reportsSales/SalesByCustomerReport';
import SalesByProductReport from './reportsSales/SalesByProductReport';
import SalesByCategoryReport from './reportsSales/SalesByCategoryReport';
import SalesRegionReport from './reportsSales/SalesRegionReport';
import SalesReport from './reportsSales/SalesReport';
import SalesByPlatform from './reportsSales/SalesByPlatform';
import ManageCsvFiles from './csvUploads/ManageCsvFiles';
import UploadCsvFile from './csvUploads/UploadCsvFile';


//Merchant URLS
import MerchantProductsList from './products/MerchantProductsList';

// import CouponUsageList from './reports/CouponUsageList';
// import UserCouponUsageList from './reports/UserCouponUsageList';
//
// import ProductsMostPurchased from './reports/ProductsMostPurchased';
// import ProductsMostSearched from './reports/ProductsMostSearched';
// import ProductsMostReturned from './reports/ProductsMostReturned';
// import ProductsMostViewed from './reports/ProductsMostViewed';
//
// import OrdersStateWise from './reports/OrdersStateWise';

import Logout from './Logout';



class Layout extends Component {
  constructor(props){
    super(props);
    this.state = {
      permissions: props.user.user.permissions,
      user: props.user
    }
  }

  componentWillMount() {
  }


	render() {

    return (
      <>
        <ToastContainer autoClose={5000} />
        <header>
	        <div className="container-fluid">
             <Header permissions = {this.state.permissions} />
             <LeftNavigation  permissions = {this.state.permissions}  user = {this.state.user} />
          </div>
        </header>
        <div id="wrapper">
          <div className="container-fluid">
            <Route exact path={ROUTES.INDEX.URL} component = {Dashboard}/>
            <Route exact path={ROUTES.DASHBOARD.URL} component = {Dashboard}/>

            {
              this.state.user.user.type && this.state.user.user.type.toLowerCase() != 'merchant'
              ?
              <>
                <Route exact path={ROUTES.USERS_LIST.URL} component = {UsersList} />
                <Route exact path={ROUTES.CUSTOMERS_LIST.URL} component = {CustomersList} />
                <Route exact path={ROUTES.CUSTOMER_DETAIL.URL} component = {CustomerDetail} />
                <Route exact path={ROUTES.ROLES_LIST.URL} component = {RolesList} />
                <Route exact path={ROUTES.ROLES_CREATE.URL} component = {RolesAdd} />
                <Route exact path={ROUTES.ROLES_EDIT.URL} component = {RolesAdd} />
                <Route exact path={ROUTES.USERS_CREATE.URL} component = {UsersCreate} />
                <Route exact path={ROUTES.USERS_EDIT.URL} component = {UsersEdit} />

                {/* <Route exact path={ROUTES.CURRENCIES_CREATE.URL} component = {CurrenciesAdd} />
                <Route exact path={ROUTES.CURRENCIES_EDIT.URL} component = {CurrenciesAdd} />
                <Route exact path={ROUTES.CURRENCIES_LIST.URL} component = {CurrenciesList} /> */}
                <Route exact path={ROUTES.CONFIG_SETTINGS.URL} component = {ManageSettings} />

                <Route exact path={ROUTES.PRICE_GRID_SETTINGS.URL} component = {PriceGridList} />
                <Route exact path={ROUTES.PRICE_GRID_ADD.URL} component = {AddPriceGrid} />
                <Route exact path={ROUTES.PRICE_GRID_EDIT.URL} component = {AddPriceGrid} />

                <Route exact path={ROUTES.PAGE_SETTINGS.URL} component = {ManagePage} />

                <Route exact path={ROUTES.CATEGORIES_CREATE.URL} component = {CategoriesAdd} />
                <Route exact path={ROUTES.CATEGORIES_EDIT.URL} component = {CategoriesAdd} />
                <Route exact path={ROUTES.CATEGORIES_LIST.URL} component = {CategoriesList} />



                <Route exact path={ROUTES.PRODUCTS_CREATE.URL} component = {ProductsAdd} />
                <Route exact path={ROUTES.PRODUCTS_EDIT.URL} component = {ProductsEdit} />
                <Route exact path={ROUTES.PRODUCTS_VIEW.URL} component = {ProductsView} />
                <Route exact path={ROUTES.PRODUCTS_LIST.URL} component = {ProductsList} />


                <Route exact path={ROUTES.PRODUCTS_MANAGE_GALLERY.URL} component = {ProductsManageGallery} />
                <Route exact path={ROUTES.PRODUCTS_MANAGE_VIDEO.URL} component = {ProductsManageVideo} />
                <Route exact path={ROUTES.COMPATIBLE_PRODUCTS.URL} component = {CompatibleProducts} />

                <Route exact path={ROUTES.BRANDS_LIST.URL} component = {BrandsList} />
                <Route exact path={ROUTES.BRANDS_CREATE.URL} component = {BrandsAdd} />
                <Route exact path={ROUTES.BRANDS_EDIT.URL} component = {BrandsAdd} />

                <Route exact path={ROUTES.BANNERS_LIST.URL} component = {BannersList} />
                <Route exact path={ROUTES.BANNERS_CREATE.URL} component = {BannersAdd} />
                <Route exact path={ROUTES.BANNERS_EDIT.URL} component = {BannersAdd} />


                <Route exact path={ROUTES.ORDERS_LIST.URL} component = {OrdersList} />
                <Route exact path={ROUTES.ORDER_DETAIL.URL} component = {OrderDetail} />


                <Route exact path={ROUTES.TRANSACTIONS_LIST.URL} component = {TransactionsList} />

                <Route exact path={ROUTES.ATTTRIBUTE_GROUPS_LIST.URL} component = {AttributeGroupsList} />
                <Route exact path={ROUTES.ATTTRIBUTE_GROUPS_CREATE.URL} component = {AttributeGroupsAdd} />
                <Route exact path={ROUTES.ATTTRIBUTE_GROUPS_EDIT.URL} component = {AttributeGroupsAdd} />

                <Route exact path={ROUTES.COUPONS_LIST.URL} component = {CouponsList} />
                <Route exact path={ROUTES.COUPONS_LIST_COUPON_CODES.URL} component = {CouponCodesList} />
                <Route exact path={ROUTES.COUPON_RULE_DETAIL.URL} component = {CouponRuleDetail} />
                <Route exact path={ROUTES.COUPONS_CREATE.URL} component = {CouponsAdd} />
                <Route exact path={ROUTES.COUPONS_LIST_EDIT.URL} component = {CouponsEdit} />

                <Route exact path={ROUTES.QUESTIONS_LIST.URL} component = {QuestionsList} />
                <Route exact path={ROUTES.ANSWERS_LIST.URL} component = {AnswersList} />

                <Route exact path={ROUTES.CATEGORY_MANAGE_ATTRIBUTES.URL} component = {AttributesManage} />

                <Route exact path={ROUTES.FINANCE_REPORTS_LIABILITY_REPORT.URL} component = {LiabilityReport} />
                <Route exact path={ROUTES.FINANCE_REPORTS_LOGISTICS_REPORT.URL} component = {LogisticsReport} />
                <Route exact path={ROUTES.FINANCE_REPORTS_PAYMENTS_REPORT.URL} component = {PaymentsReport} />
                <Route exact path={ROUTES.FINANCE_REPORTS_REVENUE_REPORT.URL} component = {RevenueReport} />

                <Route exact path={ROUTES.OPERATIONS_REPORTS_PRODUCT_PERFORMANCE.URL} component = {ProductPerformanceReport} />
                <Route exact path={ROUTES.OPERATIONS_REPORTS_GOODS_RETURNED.URL} component = {GoodsReturnedReport} />
                <Route exact path={ROUTES.OPERATIONS_REPORTS_STOCK_PERFORMANCE.URL} component = {StockPerformanceReport} />
                <Route exact path={ROUTES.OPERATIONS_REPORTS_CONSIGNMENT.URL} component = {ConsignmentsReport} />

                <Route exact path={ROUTES.MARKETING_REPORTS_ABANDONED_CARTS.URL} component = {AbandonedCartsReport} /> {/* Not working */}
                <Route exact path={ROUTES.MARKETING_REPORTS_NEW_VS_RETURNING_USERS.URL} component = {NewVsReturingUsersReport} /> {/* Not working */}
                <Route exact path={ROUTES.MARKETING_REPORTS_CUSTOMERS.URL} component = {CustomerReport} /> {/* Filters Not working */}
                <Route exact path={ROUTES.MARKETING_REPORTS_TOP_KEYWORDS.URL} component = {TopKeywordsReport} /> {/* Not working */}

                <Route exact path={ROUTES.SALES_REPORTS_SALES_BY_COUPON_CODE.URL} component = {SalesByCouponCodeReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES_BY_COUPON_CUSTOMER.URL} component = {SalesByCouponUserReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES_BY_CUSTOMERS.URL} component = {SalesByCustomerReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES_BY_PRODUCT.URL} component = {SalesByProductReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES_BY_CATEGORY.URL} component = {SalesByCategoryReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES_REGION.URL} component = {SalesRegionReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES.URL} component = {SalesReport} />
                <Route exact path={ROUTES.SALES_REPORTS_SALES_BY_PLATFORM.URL} component = {SalesByPlatform} />





                {/* <Route exact path={ROUTES.REPORTS_COUPON_USAGE_LIST.URL} component = {CouponUsageList} />
                <Route exact path={ROUTES.REPORTS_USER_COUPON_USAGE_LIST.URL} component = {UserCouponUsageList} />

                <Route exact path={ROUTES.REPORTS_MOST_PURCHASED_PRODUCTS_LIST.URL} component = {ProductsMostPurchased} />
                <Route exact path={ROUTES.REPORTS_MOST_SEARCHED_PRODUCTS_LIST.URL} component = {ProductsMostSearched} />
                <Route exact path={ROUTES.REPORTS_MOST_RETURNED_PRODUCTS_LIST.URL} component = {ProductsMostReturned} />
                <Route exact path={ROUTES.REPORTS_MOST_VIEWED_PRODUCTS_LIST.URL} component = {ProductsMostViewed} />
                <Route exact path={ROUTES.REPORTS_ORDERS_STATE_WISE_LIST.URL} component = {OrdersStateWise} /> */}

              </>
              :
                this.state.user.user.type && this.state.user.user.type.toLowerCase() == 'merchant'
                ?
                <>
                  <Route exact path={ROUTES.MERCHANT_PRODUCTS_LIST.URL} component = {MerchantProductsList} />
                  <Route exact path={ROUTES.MERCHANT_PRODUCTS_VIEW.URL} component = {ProductsView} />
                  <Route exact path={ROUTES.MERCHANT_UPLOADED_FILES_LIST.URL} component = {ManageCsvFiles} />
                  <Route exact path={ROUTES.MERCHANT_UPLOAD_CSV_FILE.URL} component = {UploadCsvFile} />
                </>
                :
                <></>

            }
            <Route  path={ROUTES.CHANGE_PASSWORD.URL} component={ChangePassword} />
            <Route  path={ROUTES.LOGOUT.URL} component={Logout} />
          </div>
        </div>
      </>
    );
	}
}
const mapStatesToProps = (state, ownProps) => {
  return {user: state.user};
}

export default connect(mapStatesToProps, null)(Layout);
