import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addUser, fetchRoles} from '../../../actions/users';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {DEFAULT_DIAL_CODE, COUNTRIES} from '../../../constants';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter name";
    hasErrors = true;
  }
  if (!values.mobile || values.mobile.trim() === '') {
    errors.mobile = "Enter mobile";
    hasErrors = true;
  }
  if (!values.email || values.email.trim() === '') {
    errors.email = "Enter email";
    hasErrors = true;
  }

  if (!values.password || values.password.trim() === '') {
    errors.password = "Enter password";
    hasErrors = true;
  }else if(values.password.length < 6){
    errors.password = "Password must be at-least 6 characters long";
    hasErrors = true;
  }

  if (!values.role) {
    errors.role = "Select role";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class UsersCreate extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
      roles: [],
      countryCode: DEFAULT_DIAL_CODE
    }
  }

  submitForm(values, dispatch, props){
    
    return this.props.addUser(values)
    .then((response) => {
      this.props.reset();
      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    this.props.change('countryCode', this.state.countryCode);
    this.fetchRoles();
  }

  fetchRoles(){
		this.setState({fetchingRecords: true});
    this.props.fetchRoles().then((response) => {
      let roles = response.value.data.responseData.data;
      this.setState({fetchingRecords: false, roles: roles});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/user-management/users/list">Users</Link></li>
        <li className="breadcrumb-item active">{this.state.user_id?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.user_id?"Edit":"Add"} User</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
            <div className="row">
            <div className=" col-lg-6">
              <Field
                name="name"
                component={renderField}
                type="text"
                label="Name"
              />
            </div>
            <div className=" col-lg-6">
              <div className = "row">
                <div className=" col-lg-4">
                <Field
                     label="Dial Code"
                     name='countryCode'
                     optionLabel='label'
                     optionValue='code'
                     options={COUNTRIES}
                     component={renderReactSelect}
                     placeholder=""
                     multi={false}
                  />
                </div>
                <div className=" col-lg-8">
                  <Field
                    name="mobile"
                    component={renderField}
                    type="text"
                    label="Mobile"
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-6">
              <Field
                name="email"
                component={renderField}
                label="Email"
              />
            </div>
            <div className=" col-lg-6">
              <Field
                name="password"
                component={renderField}
                label="Password"
              />
            </div>


            <div className=" col-lg-6">

              <Field
                label="Role"
                name='role'
                optionLabel='role'
                optionValue='id'
                options={this.state.roles}
                component={renderReactSelect}
                placeholder="Select a role"
                multi={false}
               />
             </div>

            </div>
            <div className="form-btn-group">
              <Link to="/admin/user-management/users/list?page=1" type="button" className="btn btn-secondary hvr-shadow">Cancel</Link>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
            </div>
          </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => {
      return dispatch(addUser(data));
    },
    fetchRoles: (data) => {
      return dispatch(fetchRoles(data));
    }
  }
}

UsersCreate = connect(null, mapDispatchToProps)(UsersCreate);

UsersCreate = reduxForm({
  form: 'UsersCreate',
  validate,
  //enableReinitialize: true
})(UsersCreate);

export default UsersCreate;
