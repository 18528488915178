import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import { fetchCustomers, updateUserStatus } from '../../../actions/users';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { currencyFormat, USER_STATUS } from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';

import $ from 'jquery';

class CustomersList extends Component {
	constructor(props) {
		super(props);
		var lang = localStorage.getItem('lang');
		if (!lang) {
			lang = 'en';
		}
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		if (!queryParams.sortBy) {
			queryParams.sortBy = 'id';
		}
		if (!queryParams.sortOrder) {
			queryParams.sortOrder = 'desc';
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/customers/list',
			records: null,
			brands: [],
			categoryTree: [],
			userStatuses: USER_STATUS[lang],

			filters: [
				'name',
				'email',
				'mobile',
				'status',
				'referringCode'
			]
		};
	}



	componentDidMount() {
		this.fetchCustomers(this.state.params);
	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	fetchCustomers(params) {
		this.setState({ fetchingRecords: true });

		this.props.fetchCustomers(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchCustomers(params);
		}
	}



	submitFilterForm(values, dispatch, props) {
		var params = values;
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}

	sortResults(e, sortBy, sortOrder) {
		if (e) {
			e.preventDefault();
		}
		let params = this.state.params;
		params = Object.assign(params, { sortBy: sortBy, sortOrder: sortOrder });
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}



	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		this.props.history.push(this.state.pageUrl + '?page=1');
	}

	updateUserStatus(userId, status) {
		this.setState({ fetchingRecords: true });
		this.props.updateUserStatus({ userId, status }).then((response) => {
			this.setState({ fetchingRecords: false });
			this.fetchCustomers(this.state.params);
			toast.success(response.value.data.message);
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records, params } = { ...this.state }
		return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/orders/list?page=1">Customers</Link></li>
					<li className="breadcrumb-item active">List</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">Customers</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
								<span className="icon-filter mr-1"></span>
								Filters
							</a>
						</div>
					</div>
				</div>
				<div className="collapse" id="filter" >
					<div className="filter-dropdown">
						<form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
							<div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="email"
										component={renderField}
										label="Email"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="mobile"
										component={renderField}
										label="Mobile"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Status"
										name='status'
										optionLabel='name'
										optionValue='id'
										options={this.state.userStatuses}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}

									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="referringCode"
										component={renderField}
										label="Referral code used"
									/>
								</div>
							</div>
							<div className="form-btn-group mt-3 text-right">
								<button type="button" onClick={(e) => this.clearFilters(e)} className="btn btn-clear hvr-shadow mr-1">Clear</button>
								<button type="submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
					</div>
				</div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th><a href="javascript:;" className={params.sortBy == 'id' ? params.sortOrder : ''} onClick={(e) => this.sortResults(e, 'id', params.sortOrder == 'desc' ? 'asc' : 'desc')}>ID</a></th>
									<th>Pic</th>
									<th><a href="javascript:;" className={params.sortBy == 'name' ? params.sortOrder : ''} onClick={(e) => this.sortResults(e, 'name', params.sortOrder == 'desc' ? 'asc' : 'desc')}>Name</a></th>
									<th>Mobile</th>
									<th><a href="javascript:;" className={params.sortBy == 'email' ? params.sortOrder : ''} onClick={(e) => this.sortResults(e, 'email', params.sortOrder == 'desc' ? 'asc' : 'desc')}>Email</a></th>
									<th>Referral code</th>
									<th>Referral use</th>
									<th><a href="javascript:;" className={params.sortBy == 'status' ? params.sortOrder : ''} onClick={(e) => this.sortResults(e, 'status', params.sortOrder == 'desc' ? 'asc' : 'desc')}>Status</a></th>
									<th><a href="javascript:;" className={params.sortBy == 'createdAt' ? params.sortOrder : ''} onClick={(e) => this.sortResults(e, 'createdAt', params.sortOrder == 'desc' ? 'asc' : 'desc')}>Registered on</a></th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj.id}>
												<td className="nowrap"><Link to={"/admin/customers/list/detail/" + obj.id}>{obj.id}</Link></td>
												{/* <td className="nowrap">{obj.id}</td> */}
												<td>{(obj.attachment && obj.attachment.filePath) ? <a href={obj.attachment.filePath} target="_blank" ><img src={obj.attachment.filePath} style={{ width: "60px" }} /></a> : ''}</td>
												<td>{obj.name}</td>
												<td className="nowrap">+{obj.countryCode} {obj.mobile}</td>
												<td className="nowrap">{obj.email}</td>
												<td className="nowrap">{obj.referralCode}</td>
												<td className="nowrap">{obj.referringCode}</td>
												<td>
													<label className="switch">
														<input type="checkbox" checked={obj.status == 1 ? true : false} onChange={() => this.updateUserStatus(obj.id, obj.status == 1 ? 2 : 1, index)} />
														<span className="slider round"></span>
													</label>
												</td>
												<td className="nowrap">{moment(obj.createdAt).format('DD MMM, Y')}</td>
												<td>
													<span className="dropdown threeDots">
														<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
															<span></span> <span></span> <span></span>
														</a>
														<ul className="dropdown-menu" role="menu">
															{/* <li><Link className="dropdown-item" title="View detail" to = {"/admin/customers/list/detail/"+obj.id} >View Detail</Link></li> */}
															<li><Link className="dropdown-item" title="View orders" to={"/admin/orders/list?userId=" + obj.id + "&uName=" + obj.name}>Orders</Link></li>
														</ul>
													</span>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
			</>

		);
	}
}

CustomersList = reduxForm({
	form: 'CustomersListFilter'
})(CustomersList);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCustomers: (data) => {
			return dispatch(fetchCustomers(data));
		},
		updateUserStatus: (data) => {
			return dispatch(updateUserStatus(data));
		},
	}
}


export default withRouter(connect(null, mapDispatchToProps)(CustomersList));
