import React, { Component } from 'react';
import { Redirect, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, FieldArray } from 'redux-form';
import renderField from '../FormFields/renderField';
import {changePassword} from '../../actions/users';

import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.oldPassword || values.oldPassword.trim() === '') {
    errors.oldPassword = "Enter old password";
    hasErrors = true;
  }
  if (!values.newPassword || values.newPassword.trim() === '') {
    errors.newPassword = "Enter new password";
    hasErrors = true;
  }else if(values.newPassword.length < 6){
    errors.newPassword = "Password must be at least 6 characters long";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class ChangePassword extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
    }
  }

  submitForm(values, dispatch, props){
    this.setState({submittingForm: true});
    this.props.changePassword(values).then((response) => {
      this.setState({submittingForm: false});
      toast.success(response.value.data.message);
      this.props.reset();
    }).catch((error)=>{
      this.setState({submittingForm: false});
      if(error.response) {
        toast.error(error.response.data.message);
      }else if (error.request) {
        toast.error("Network error!");
      }else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
  }

  componentWillReceiveProps(nextProps){
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>

			<h5 className="h5 mb-4">Change Password</h5>
			<section className="formBg" style={{maxWidth:"672px"}}>
    		<form onSubmit={ handleSubmit(this.submitForm.bind(this)) } >
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="add-left-block">
                <div className="fields-ui-block">
                  <div className="basic-details">
                    <div className="heading">
                      <h4>Fill in the required details</h4>
                    </div>
                    <div className="form-block">
                      <div className="row">
                        <div className="col-lg-12">
                          <Field type="password" name='oldPassword' className="form-control" label="Old Password" component={renderField} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <Field type="password" name='newPassword' className="form-control" label="New Password" component={renderField} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-btn-group">
      	          <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${this.state.submittingForm?" btn-loader ":"  "}` } disabled = {this.state.submittingForm}>Submit</button>
      	        </div>
              </div>
            </div>
          </div>
        </form>
      </section>
		</>
    )
	}
}

ChangePassword = reduxForm({
  form: 'ChangePassword',
  validate,
  enableReinitialize: true
})(ChangePassword);

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data) => {
      return dispatch(changePassword(data));
    }
  }
}

export default connect(null, mapDispatchToProps)(ChangePassword);
