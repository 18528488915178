import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import { fetchCoupons, deleteCouponRule, updateCouponPublishStatus, updateCouponPosition } from '../../../actions/coupons';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import $ from 'jquery';
import moment from 'moment';
import { COUPON_TYPES } from '../../../constants';

class CouponsList extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		var lang = localStorage.getItem('lang');
		if (!lang) {
			lang = 'en';
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/coupons/list',
			records: null,
			couponTypes: COUPON_TYPES[lang],
			filters: [
				'name'
			]
		};
	}

	getCouponTypeOption(option) {
		for (var i = 0; i < this.state.couponTypes.length; i++) {
			if (this.state.couponTypes[i].id == option) {
				return this.state.couponTypes[i].name;
			}
		}
	}



	componentDidMount() {
		this.fetchCoupons(this.state.params);
		this.updateFilterForm(this.state.params);

	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}



	fetchCoupons(params) {
		this.setState({ fetchingRecords: true });
		this.props.fetchCoupons(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchCoupons(params);
		}
	}

	deleteRecord(recordid) {
		this.props.deleteCouponRule({ ruleId: recordid }).then((response) => {
			toast.success(response.value.data.message);
			this.fetchCoupons(this.state.params);
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}


	publishRecord(id, status, index) {
		var records = this.state.records;
		this.props.updateCouponPublishStatus({ ruleId: id, status }).then((response) => {
			records.data[index].couponStatus = status;
			this.setState({ records: records });
			toast.success(response.value.data.message);

		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}



	submitFilterForm(values, dispatch, props) {
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}


	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		this.props.history.push(this.state.pageUrl + '?page=1');
	}

	updateCouponPosition(id, status, index, key) {
		var records = this.state.records;
		this.props.updateCouponPosition({ key, ruleId: id, value: status }).then((response) => {
			records.data[index][key] = status;
			this.setState({ records: records });
			toast.success(response.value.data.message);

		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}



	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records } = { ...this.state }
		return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/coupons/list?page=1">Promo Codes</Link></li>
					<li className="breadcrumb-item active">List</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">Promo Codes</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
							{/*<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>*/}
							<Link to="/admin/coupons/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Create Coupon</Link>
						</div>
					</div>
				</div>
				{/*<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Category"
		                name='categoryId'
		                optionLabel='categoryName'
		                optionValue='id'
		                options={this.state.categoryTree}
		                component={renderReactSelect}
		                placeholder="Select"
		                multi={false}
										isClearable={true}

		              />
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Brand"
		                name='brandId'
		                optionLabel='name'
		                optionValue='id'
		                options={this.state.brands}
		                component={renderReactSelect}
		                placeholder="Select"
		                multi={false}
										isClearable={true}
		              />
								</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>*/}
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Rule Name</th>
									<th>Coupon Type</th>
									<th>Coupon</th>
									<th>Show at top</th>
									<th>Show at bottom</th>
									<th>Date</th>
									<th>Time</th>
									<th>Status</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj.id}>
												<td>{obj.id}</td>
												<td className="nowrap"><Link to={"/admin/coupons/list/detail/" + obj.id}>{obj.couponRuleName}</Link></td>
												<td className="nowrap">{this.getCouponTypeOption(obj.couponType)}</td>
												<td>{obj.couponType == 1 ? <Link to={"/admin/coupons/list/couponCodes/" + obj.id}>{obj.couponCount}</Link> : obj.couponCount}</td>
												<td>
													<label className="switch">
														<input type="checkbox" checked={obj.topPosition ? true : false} onChange={() => this.updateCouponPosition(obj.id, obj.topPosition ? 0 : 1, index, "topPosition")} />
														<span className="slider round"></span>
													</label>
												</td>
												<td>
													<label className="switch">
														<input type="checkbox" checked={obj.bottomPosition ? true : false} onChange={() => this.updateCouponPosition(obj.id, obj.bottomPosition ? 0 : 1, index, 'bottomPosition')} />
														<span className="slider round"></span>
													</label>
												</td>
												<td className="nowrap">{moment(obj.couponStartDate).format("DD MMM, Y")}<br />-<br />{moment(obj.couponEndDate).format("DD MMM, Y")}</td>
												<td className="nowrap">{obj.startTime}<br />-<br />{obj.endTime}</td>
												<td>
													<label className="switch">
														<input type="checkbox" checked={obj.couponStatus ? true : false} onChange={() => this.publishRecord(obj.id, obj.couponStatus ? 0 : 1, index)} />
														<span className="slider round"></span>
													</label>
												</td>
												<td>
													<span className="dropdown threeDots">
														<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
															<span></span> <span></span> <span></span>
														</a>
														<ul className="dropdown-menu" role="menu">
															<li>
																{/* <Link to = {"/admin/coupons/list/manageImages/"+obj.id} className="dropdown-item">Manage Images</Link> */}
																<Link to={"/admin/coupons/list/edit/" + obj.id} className="dropdown-item">Edit</Link>
															</li>
															<li><Confirm className="confirmPopup"
																onConfirm={() => this.deleteRecord(obj.id)}
																body="Are you sure you want to delete this?"
																confirmText="Confirm Delete"
																title="Delete!">
																<button className="dropdown-item">Delete</button>
															</Confirm></li>
														</ul>
													</span>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
			</>

		);
	}
}

CouponsList = reduxForm({
	form: 'CouponsListFilter'
})(CouponsList);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCoupons: (data) => {
			return dispatch(fetchCoupons(data));
		},
		deleteCouponRule: (data) => {
			return dispatch(deleteCouponRule(data));
		},
		updateCouponPublishStatus: (data) => {
			return dispatch(updateCouponPublishStatus(data));
		},
		updateCouponPosition: (data) => {
			return dispatch(updateCouponPosition(data));
		}
	}
}

export default withRouter(connect(null, mapDispatchToProps)(CouponsList));
