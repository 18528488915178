import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { currencyFormat } from '../../../constants';
import Confirm from 'react-confirm-bootstrap';
import CancelOrderPopup from './CancelOrderPopup';

import {
  Field,
  reduxForm,
  change,
  SubmissionError,
  FormSection,
  FieldArray
} from 'redux-form';
import queryString from 'query-string';
import { fetchOrder, updateConsignmentStatus, updateOrderReturnStatus } from '../../../actions/orders';
import { toast } from 'react-toastify';
import RefundPopup from './RefundPopup';
import LogisticsPopup from './LogisticsPopup';
import moment from 'moment';

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    var params = queryString.parse(props.location.search);
    this.state = {
      dataLoaded: false,
      orderId: props.match.params.orderId
        ? props.match.params.orderId
        : null,
      orderData: null,
      openRefundPopup: false,
      refundPopupData: null,
      openLogisticsPopup: false,
      logisticsPopupData: null,
      openCancelOrderPopup: false,
      cancelOrderData: null,
    }
    this.openRefundPopup = this.openRefundPopup.bind(this);
    this.closeRefundPopup = this.closeRefundPopup.bind(this);

    this.openLogisticsPopup = this.openLogisticsPopup.bind(this);
    this.closeLogisticsPopup = this.closeLogisticsPopup.bind(this);

    this.fetchOrder = this.fetchOrder.bind(this);

    this.openCancelOrderPopup = this.openCancelOrderPopup.bind(this);
    this.closeCancelOrderPopup = this.closeCancelOrderPopup.bind(this);

  }

  openRefundPopup(data) {
    this.setState({ openRefundPopup: true, refundPopupData: data });
  }

  closeRefundPopup() {
    this.fetchOrder(this.state.orderId);
    this.setState({ openRefundPopup: false });
  }

  openLogisticsPopup(data) {
    this.setState({ openLogisticsPopup: true, logisticsPopupData: data });
  }

  closeLogisticsPopup() {
    this.fetchOrder(this.state.orderId);
    this.setState({ openLogisticsPopup: false });
  }

  openCancelOrderPopup(data) {
    this.setState({ openCancelOrderPopup: true, cancelOrderData: data });
  }

  closeCancelOrderPopup() {
    this.fetchOrder(this.state.orderId);
    this.setState({ openCancelOrderPopup: false, cancelOrderData: null });
  }

  componentDidMount() {
    this.fetchOrder(this.state.orderId);
  }

  fetchOrder(orderId) {
    this.setState({ fetchingRecords: true });
    this.props.fetchOrder({ orderId: orderId }).then((response) => {
      console.log("orders____________", response)
      let orderData = response.value.data.responseData;
      let orderItemUpdatingStatuses = [];
      if (orderData && orderData.orderItems) {
        orderData.orderItems.forEach((obj, index) => {
          orderItemUpdatingStatuses.push({ isLoading: false });
        })
      }

      this.setState({ fetchingRecords: false, orderData, orderItemUpdatingStatuses });
    }).catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.orderId != nextProps.match.params.orderId) {
      this.fetchOrder(nextProps.match.params.orderId);
      this.setState({ orderId: nextProps.match.params.orderId });
    }
  }

  updateConsignmentStatus(consignmentId, orderId, status, index) {
    let orderItemUpdatingStatuses = this.state.orderItemUpdatingStatuses;
    orderItemUpdatingStatuses[index] = Object.assign(orderItemUpdatingStatuses[index], { isLoading: true });
    this.setState({ orderItemUpdatingStatuses });
    this.props.updateConsignmentStatus({ orderId, consignmentId, status }).then((response) => {
      orderItemUpdatingStatuses[index] = Object.assign(orderItemUpdatingStatuses[index], { isLoading: false });
      this.setState({ orderItemUpdatingStatuses });
      this.fetchOrder(orderId);
    }).catch((error) => {
      orderItemUpdatingStatuses[index] = Object.assign(orderItemUpdatingStatuses[index], { isLoading: false });
      this.setState({ orderItemUpdatingStatuses });
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  updateOrderReturnStatus(consignmentId, orderId, type, index) {
    let orderItemUpdatingStatuses = this.state.orderItemUpdatingStatuses;
    orderItemUpdatingStatuses[index] = Object.assign(orderItemUpdatingStatuses[index], { isLoading: true });
    this.setState({ orderItemUpdatingStatuses });
    this.props.updateOrderReturnStatus({ orderId, consignmentId, type }).then((response) => {
      orderItemUpdatingStatuses[index] = Object.assign(orderItemUpdatingStatuses[index], { isLoading: false });
      this.setState({ orderItemUpdatingStatuses });
      this.fetchOrder(orderId);
    }).catch((error) => {
      orderItemUpdatingStatuses[index] = Object.assign(orderItemUpdatingStatuses[index], { isLoading: false });
      this.setState({ orderItemUpdatingStatuses });
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const { orderData } = this.state;
    return (
      <>
        <div className="back-ui" > <a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
        <ol className="breadcrumb breadcrumb-v1">
          <li className="breadcrumb-item">
            <Link to="/admin/product-management/products/list">Orders</Link>
          </li>
          <li className="breadcrumb-item active">Detail</li>
        </ol>
        <h5 className="h5 mb-4">Order Detail</h5>
        <div className="job-info order-info">
          <h6 className="h6">Order ID: {orderData && orderData.orderId} {orderData && <>({orderData.orderStatus == 1 ? "Payment awaiting" : orderData.orderStatus == 2 ? "Payment approved" : orderData.orderStatus == 3 ? "Payment declined" : "Cash on delivery"})</>}</h6>
          {
            orderData && orderData.defaultProductItems && orderData.defaultProductItems.length > 0 &&
            <div className="p-4">
              <div className="row">
                <div className="col-sm-12 col-12">

                  <>
                    <hr></hr>
                    <h5>Items</h5>
                    <hr></hr>
                  </>

                  <ul className="orderItems-ul">
                    {
                      orderData && orderData.defaultProductItems && orderData.defaultProductItems.length > 0 &&
                      orderData.defaultProductItems.map((obj, index) => {
                        return (
                          <li key={index}>
                            <div className="item-container">
                              <div className="item-left-block">
                                <figure><a href="javascript:;"><img src={obj.featuredImage.filePath} alt="" /></a></figure>
                              </div>
                              <div className="item-right-block">
                                <div className="item-base-details">
                                  <div className="top-block">
                                    {
                                      obj.consignmentId && <div className="">Consignment Id: {obj.consignmentId}</div>
                                    }

                                    <a href="javascript:;"><h4>{obj.title}</h4></a>
                                    <div className="price-ui"><span>{obj.currencyCode}</span> {currencyFormat(obj.discount ? parseFloat(obj.price).toFixed(2) - parseFloat(obj.discount).toFixed(2) : parseFloat(obj.price).toFixed(2))}</div>
                                    {/* <div className="order-timeline">
                                   {
                                     obj.orderTimeline && obj.orderTimeline.length > 0 &&
                                     obj.orderTimeline.map((obj1, index1) => {
                                       var statusToBeUpdated = "";
                                       if(statusToBeUpdated == "" && obj1.statusDate == null){
                                         statusToBeUpdated = obj1.status;
                                       }
                                       return(
                                         <div key = {index1} className={`${"orderStatus"} ${index1 == 0?'ordered': index1 == 1?'packed':index1 == 2?"shipped": index1 == 3?"delivered": ''} ${obj1.statusDate?'active':''}`}><span>{obj1.status}</span>
                                         <div className="dateTime">
                                           {obj1.statusDate?<span>{moment(obj1.statusDate).format("ddd, Do MMM")}</span>:''}
                                         </div>
                                       </div>
                                       )
                                     })
                                   }

                                 </div> */}
                                    <div className="order-timeline">
                                      <div className="track-status">
                                        <ul>
                                          {
                                            obj.orderTimeline && obj.orderTimeline.length > 0 &&
                                            obj.orderTimeline.map((obj1, index1) => {
                                              var statusToBeUpdated = "";
                                              if (statusToBeUpdated == "" && obj1.statusDate == null) {
                                                statusToBeUpdated = obj1.status;
                                              }
                                              return (
                                                <li key={index1} className={obj1.statusDate ? "done checked" : ""}>
                                                  <span>{obj1.status}</span>
                                                  {obj1.statusDate ? <div className="date-ui">{moment(obj1.statusDate).format("ddd, Do MMM")}</div> : ''}
                                                </li>

                                              )
                                            })
                                          }
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    obj.isItemReturned == 1
                                      ?
                                      <>
                                        {
                                          obj.nextStatus == 'Return Accepted'
                                            ?
                                            <div className="stausChange">
                                              <div className="form-btn-group" style={{ marginTop: 0, border: 'none' }}>

                                                <Confirm className="confirmPopup"
                                                  onConfirm={() => this.updateOrderReturnStatus(obj.consignmentId, obj.order_id, 1, index)}
                                                  body="Are you sure you want to accept this request?"
                                                  confirmText="Confirm Accept"
                                                  title="Accept!">
                                                  <button className={`${"hvr-shadow btn btn-secondary "} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`} disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button" >Accept</button>
                                                </Confirm>

                                                <Confirm className="confirmPopup"
                                                  onConfirm={() => this.updateOrderReturnStatus(obj.consignmentId, obj.order_id, 2, index)}
                                                  body="Are you sure you want to reject this request?"
                                                  confirmText="Confirm Reject"
                                                  title="Reject!">
                                                  <button className={`${"hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`} disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button">Reject</button>
                                                </Confirm>
                                              </div>
                                            </div>
                                            :
                                            obj.nextStatus == 'Return Product Received'
                                              ?
                                              <div className="stausChange">
                                                <div className="">
                                                  <button className={`${"btn-add ml-1  hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`} disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button" onClick={(e) => this.updateOrderReturnStatus(obj.consignmentId, obj.order_id, 3, index)}>Return Received</button>

                                                </div>
                                              </div>
                                              :
                                              obj.nextStatus == 'Refund Generated'
                                                ?
                                                <div className="stausChange">
                                                  <div className="">
                                                    <button className={"btn-add ml-1  hvr-shadow btn btn-secondary"} type="button" onClick={(e) => this.openRefundPopup({ orderId: obj.order_id, consignmentId: obj.consignmentId, amount: obj.discount ? parseFloat(obj.price).toFixed(2) - parseFloat(obj.discount).toFixed(2) : parseFloat(obj.price).toFixed(2), refundFor: 1, paymentMethod: String(obj.returnRefundPaymentMode) })}>Generate Refund</button>
                                                  </div>
                                                </div>
                                                :
                                                <></>
                                        }

                                      </>

                                      :
                                      <>
                                        {
                                          obj.nextStatus != null &&
                                          <div className="stausChange">
                                            <div className="" >
                                              {
                                                obj.nextStatus == "In Transit"
                                                  ?
                                                  <button
                                                    style={{ width: "100%" }}
                                                    className={`${"btn-add ml-1  hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`}
                                                    disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button"
                                                    onClick={(e) => this.openLogisticsPopup({ obj, index, orderData })}>Mark {obj.nextStatus}
                                                  </button>
                                                  :
                                                  <button
                                                    style={{ width: "100%" }}
                                                    className={`${"btn-add ml-1  hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`}
                                                    disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button"
                                                    onClick={(e) => this.updateConsignmentStatus(obj.consignmentId, obj.order_id, obj.nextStatus, index)}>Mark {obj.nextStatus}
                                                  </button>

                                              }

                                            </div>
                                            {
                                              obj.status != 'Delivered' &&
                                              <div className="" style={{ marginTop: '10px', width: "100%" }}>
                                                <button
                                                  style={{ width: "100%" }}
                                                  className="btn-add ml-1  hvr-shadow btn btn-secondary"
                                                  type="button"
                                                  onClick={(e) => this.openCancelOrderPopup(obj)}>Cancel Order
                                                </button>
                                              </div>
                                            }

                                          </div>
                                        }
                                      </>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              {
                                obj.returnPickUpAddress &&
                                <div className="col-sm-4 col-4">
                                  <div className="p-4">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="address-section">
                                          <h5>Return Address</h5>
                                          <h6>{orderData && orderData.deliveryAddress.fullName}</h6>
                                          <p>{orderData && orderData.deliveryAddress.address}, {orderData && orderData.deliveryAddress.city}, {orderData && orderData.deliveryAddress.state_id} {orderData && orderData.deliveryAddress.zipcode ? "-" + orderData.deliveryAddress.zipcode : ''}<br />{orderData && orderData.deliveryAddress.landmark ? <><b>Landmark:</b> {orderData.deliveryAddress.landmark}</> : ''}</p>
                                          <p><b>Phone number</b> {orderData && orderData.deliveryAddress.mobileNumber ? orderData.deliveryAddress.mobileNumber : ''}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              {
                                obj.returnRefundReason &&
                                <div className="col-sm-5 col-5">
                                  <div className="p-4">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="address-section">
                                          <h5>Reason</h5>
                                          {
                                            obj.cancelByAdmin && obj.cancelByAdmin == 1
                                              ?
                                              <h6>Cancelled by admin</h6>
                                              :
                                              <>
                                                <h6>{obj.returnRefundReason}</h6>
                                                <p>{obj.returnRefundReasonDescription}</p>
                                              </>
                                          }

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>

                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          }

          {
            orderData && orderData.buildPcItems && orderData.buildPcItems.length > 0 &&
            <div className="p-4">
              <div className="row">
                <div className="col-sm-12 col-12">
                  <>
                    <hr></hr>
                    <h5>Build PC Items</h5>
                    <hr></hr>
                  </>

                  <ul className="orderItems-ul">
                    {
                      orderData && orderData.buildPcItems && orderData.buildPcItems.length > 0 &&
                      orderData.buildPcItems.map((obj, index) => {
                        return (
                          <li key={index}>
                            <div className="item-container">
                              <div className="item-left-block">
                                <figure><a href="javascript:;"><img src={obj.featuredImage.filePath} alt="" /></a></figure>
                              </div>
                              <div className="item-right-block">
                                <div className="item-base-details">
                                  <div className="top-block">
                                    {
                                      obj.consignmentId && <div className="">Consignment Id: {obj.consignmentId}</div>
                                    }

                                    <a href="javascript:;"><h4>{obj.title}</h4></a>
                                    <div className="price-ui"><span>{obj.currencyCode}</span> {currencyFormat(obj.discount ? parseFloat(obj.price).toFixed(2) - parseFloat(obj.discount).toFixed(2) : parseFloat(obj.price).toFixed(2))}</div>
                                    {/* <div className="order-timeline">
                                   {
                                     obj.orderTimeline && obj.orderTimeline.length > 0 &&
                                     obj.orderTimeline.map((obj1, index1) => {
                                       var statusToBeUpdated = "";
                                       if(statusToBeUpdated == "" && obj1.statusDate == null){
                                         statusToBeUpdated = obj1.status;
                                       }
                                       return(
                                         <div key = {index1} className={`${"orderStatus"} ${index1 == 0?'ordered': index1 == 1?'packed':index1 == 2?"shipped": index1 == 3?"delivered": ''} ${obj1.statusDate?'active':''}`}><span>{obj1.status}</span>
                                         <div className="dateTime">
                                           {obj1.statusDate?<span>{moment(obj1.statusDate).format("ddd, Do MMM")}</span>:''}
                                         </div>
                                       </div>
                                       )
                                     })
                                   }

                                 </div> */}
                                    <div className="order-timeline">
                                      <div className="track-status">
                                        <ul>
                                          {
                                            obj.orderTimeline && obj.orderTimeline.length > 0 &&
                                            obj.orderTimeline.map((obj1, index1) => {
                                              var statusToBeUpdated = "";
                                              if (statusToBeUpdated == "" && obj1.statusDate == null) {
                                                statusToBeUpdated = obj1.status;
                                              }
                                              return (
                                                <li key={index1} className={obj1.statusDate ? "done checked" : ""}>
                                                  <span>{obj1.status}</span>
                                                  {obj1.statusDate ? <div className="date-ui">{moment(obj1.statusDate).format("ddd, Do MMM")}</div> : ''}
                                                </li>

                                              )
                                            })
                                          }
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    obj.isItemReturned == 1
                                      ?
                                      <>
                                        {
                                          obj.nextStatus == 'Return Accepted'
                                            ?
                                            <div className="stausChange">
                                              <div className="form-btn-group" style={{ marginTop: 0, border: 'none' }}>

                                                <Confirm className="confirmPopup"
                                                  onConfirm={() => this.updateOrderReturnStatus(obj.consignmentId, obj.order_id, 1, index)}
                                                  body="Are you sure you want to accept this request?"
                                                  confirmText="Confirm Accept"
                                                  title="Accept!">
                                                  <button className={`${"hvr-shadow btn btn-secondary "} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`} disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button" >Accept</button>
                                                </Confirm>

                                                <Confirm className="confirmPopup"
                                                  onConfirm={() => this.updateOrderReturnStatus(obj.consignmentId, obj.order_id, 2, index)}
                                                  body="Are you sure you want to reject this request?"
                                                  confirmText="Confirm Reject"
                                                  title="Reject!">
                                                  <button className={`${"hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`} disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button">Reject</button>
                                                </Confirm>
                                              </div>
                                            </div>
                                            :
                                            obj.nextStatus == 'Return Product Received'
                                              ?
                                              <div className="stausChange">
                                                <div className="">
                                                  <button className={`${"btn-add ml-1  hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`} disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button" onClick={(e) => this.updateOrderReturnStatus(obj.consignmentId, obj.order_id, 3, index)}>Return Received</button>

                                                </div>
                                              </div>
                                              :
                                              obj.nextStatus == 'Refund Generated'
                                                ?
                                                <div className="stausChange">
                                                  <div className="">
                                                    <button className={"btn-add ml-1  hvr-shadow btn btn-secondary"} type="button" onClick={(e) => this.openRefundPopup({ orderId: obj.order_id, consignmentId: obj.consignmentId, amount: obj.discount ? parseFloat(obj.price).toFixed(2) - parseFloat(obj.discount).toFixed(2) : parseFloat(obj.price).toFixed(2), refundFor: 1, paymentMethod: String(obj.returnRefundPaymentMode) })}>Generate Refund</button>
                                                  </div>
                                                </div>
                                                :
                                                <></>
                                        }

                                      </>

                                      :
                                      <>
                                        {
                                          obj.nextStatus != null &&
                                          <div className="stausChange">
                                            <div className="" >
                                              {
                                                obj.nextStatus == "In Transit"
                                                  ?
                                                  <button
                                                    style={{ width: "100%" }}
                                                    className={`${"btn-add ml-1  hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`}
                                                    disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button"
                                                    onClick={(e) => this.openLogisticsPopup({ obj, index, orderData })}>Mark {obj.nextStatus}
                                                  </button>
                                                  :
                                                  <button
                                                    style={{ width: "100%" }}
                                                    className={`${"btn-add ml-1  hvr-shadow btn btn-secondary"} ${this.state.orderItemUpdatingStatuses[index].isLoading ? 'btn-loader' : ''}`}
                                                    disabled={this.state.orderItemUpdatingStatuses[index].isLoading ? true : false} type="button"
                                                    onClick={(e) => this.updateConsignmentStatus(obj.consignmentId, obj.order_id, obj.nextStatus, index)}>Mark {obj.nextStatus}
                                                  </button>

                                              }

                                            </div>
                                            {
                                              obj.status != 'Delivered' &&
                                              <div className="" style={{ marginTop: '10px', width: "100%" }}>
                                                <button
                                                  style={{ width: "100%" }}
                                                  className="btn-add ml-1  hvr-shadow btn btn-secondary"
                                                  type="button"
                                                  onClick={(e) => this.openCancelOrderPopup(obj)}>Cancel Order
                                                </button>
                                              </div>
                                            }

                                          </div>
                                        }
                                      </>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              {
                                obj.returnPickUpAddress &&
                                <div className="col-sm-4 col-4">
                                  <div className="p-4">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="address-section">
                                          <h5>Return Address</h5>
                                          <h6>{orderData && orderData.deliveryAddress.fullName}</h6>
                                          <p>{orderData && orderData.deliveryAddress.address}, {orderData && orderData.deliveryAddress.city}, {orderData && orderData.deliveryAddress.state_id} {orderData && orderData.deliveryAddress.zipcode ? "-" + orderData.deliveryAddress.zipcode : ''}<br />{orderData && orderData.deliveryAddress.landmark ? <><b>Landmark:</b> {orderData.deliveryAddress.landmark}</> : ''}</p>
                                          <p><b>Phone number</b> {orderData && orderData.deliveryAddress.mobileNumber ? orderData.deliveryAddress.mobileNumber : ''}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              {
                                obj.returnRefundReason &&
                                <div className="col-sm-5 col-5">
                                  <div className="p-4">
                                    <div className="row">
                                      <div className="col-12">
                                        <div className="address-section">
                                          <h5>Reason</h5>
                                          {
                                            obj.cancelByAdmin && obj.cancelByAdmin == 1
                                              ?
                                              <h6>Cancelled by admin</h6>
                                              :
                                              <>
                                                <h6>{obj.returnRefundReason}</h6>
                                                <p>{obj.returnRefundReasonDescription}</p>
                                              </>
                                          }

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>

                          </li>
                        )
                      })
                    }
                  </ul>
                </div>


              </div>
            </div>
          }
        </div>

        <div className="job-info order-info address-info">
          <h6 className="h6">Address</h6>
          <div className="p-4">
            <div className="row">
              <div className="col-sm-5 col-12">
                <div className="address-section">
                  <h5>Shipping Address</h5>
                  <h6>{orderData && orderData.deliveryAddress.fullName}</h6>
                  <p>{orderData && orderData.deliveryAddress.address}, {orderData && orderData.deliveryAddress.city}, {orderData && orderData.deliveryAddress.state_id} {orderData && orderData.deliveryAddress.zipcode ? "-" + orderData.deliveryAddress.zipcode : ''}<br />{orderData && orderData.deliveryAddress.landmark ? <><b>Landmark:</b> {orderData.deliveryAddress.landmark}</> : ''}</p>
                  <p><b>Phone number</b> {orderData && orderData.deliveryAddress.mobileNumber ? orderData.deliveryAddress.mobileNumber : ''}</p>
                </div>
              </div>
              <div className="col-sm-5 col-12">
                <div className="address-section">
                  <h5>Billing Address</h5>
                  <h6>{orderData && orderData.billingAddress.fullName}</h6>
                  <p>{orderData && orderData.billingAddress.address}, {orderData && orderData.billingAddress.city}, {orderData && orderData.billingAddress.state_id} {orderData && orderData.billingAddress.zipcode ? "-" + orderData.billingAddress.zipcode : ''}<br />{orderData && orderData.billingAddress.landmark ? <><b>Landmark:</b> {orderData.billingAddress.landmark}</> : ''}</p>
                  <p><b>Phone number</b> {orderData && orderData.deliveryAddress.mobileNumber ? orderData.deliveryAddress.mobileNumber : ''}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="job-info order-info address-info">
          <h6 className="h6">Summary</h6>
          <div className="p-4">
            <div className="row">
              <div className="col-sm-6 col-6">
                <div className="row">
                  <div className="col-sm-12 col-12">
                    <h6>Order Summary</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-3">
                    <label>Sub Total: </label>
                  </div>
                  <div className="col-sm-8 col-8">
                    <span><strong>{orderData && <>{orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.orderSummary.subTotal).toFixed(2))}</>}</strong></span>
                  </div>
                </div>
                {
                  orderData && (orderData.orderSummary.couponDiscount) && orderData.orderSummary.couponDiscount > 0
                    ?
                    <div className="row">
                      <div className="col-sm-3 col-3">
                        <label>Coupon Applied: </label>
                      </div>
                      <div className="col-sm-8 col-8">
                        <span><strong>- {orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.orderSummary.couponDiscount).toFixed(2))}</strong></span>
                      </div>
                    </div>
                    :
                    <></>
                }
                <div className="row">
                  <div className="col-sm-3 col-3">
                    <label>Shipping: </label>
                  </div>
                  <div className="col-sm-8 col-8">
                    <span><strong>{orderData && <>{orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.orderSummary.estimatedShipping).toFixed(2))}</>}</strong></span>
                  </div>
                </div>
                {
                  orderData && orderData.orderSummary.isAssembled && orderData.orderSummary.isAssembled == 1
                    ?
                    <div className="row">
                      <div className="col-sm-3 col-3">
                        <label>Assemble Charges: </label>
                      </div>
                      <div className="col-sm-8 col-8">
                        <span><strong>- {orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.orderSummary.assembleCharges).toFixed(2))}</strong></span>
                      </div>
                    </div>
                    :
                    <></>
                }
                {
                  orderData && orderData.paymentDetails && orderData.paymentDetails.walletPayment && parseFloat(orderData.paymentDetails.walletPayment) > 0 &&
                  <div className="row">
                    <div className="col-sm-3 col-3">
                      <label>Wallet Used: </label>
                    </div>
                    <div className="col-sm-8 col-8">
                      <span><strong>- {orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.paymentDetails.walletPayment).toFixed(2))}</strong></span>
                    </div>
                  </div>

                }

                <div className="row">
                  <div className="col-sm-3 col-3">
                    <label>Taxes: </label>
                  </div>
                  <div className="col-sm-8 col-8">
                    <span><strong>{orderData && <>{orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.orderSummary.estimatedTaxes).toFixed(2))}</>}</strong></span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-3">
                    <label>Total: </label>
                  </div>
                  <div className="col-sm-8 col-8">
                    <span><strong>{orderData && <>{orderData.orderSummary.currencyCode} {currencyFormat(parseFloat(orderData.orderSummary.total).toFixed(2))}</>}</strong></span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6">
                <div className="col-sm-12 col-12">
                  <h6>Payment Method</h6>
                  {
                    orderData && orderData.paymentDetails && orderData.paymentDetails.cardSource && orderData.paymentDetails.cardSource.type == 'card'
                      ?
                      <p>{orderData.paymentDetails.cardSource.scheme} Ending in {orderData.paymentDetails.cardSource.last4}</p>
                      :
                      orderData && orderData.paymentDetails && orderData.paymentDetails.cardSource && orderData.paymentDetails.cardSource.type == "Cash on Delivery"
                        ?
                        <p>Cash on Delivery</p>
                        :
                        <p>Wallet</p>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>


        {
          orderData && orderData.installmentDetails && orderData.installmentDetails.length > 0 &&
          <div className="job-info order-info address-info">
            <h6 className="h6">Tabby Payment</h6>
            <div className="p-4">
              <div class="">
                <h4>{orderData.installmentDetails[0].installments.length + 1} payments of AED {orderData.paymentDetails.totalAmount}. </h4>
                <p>No interest.  No fees</p>

                <div class="emi-steps">
                  <div class="emi-card done">
                    <div class="left_blk">
                      <div class="step-circle progress-25">
                        <div class="circle-inner ">
                        </div>
                      </div>
                      <label>Today</label>
                    </div>
                    <div class="right_blk">
                      <p>AED {orderData.installmentDetails[0].downpayment}</p>
                    </div>
                  </div>

                  <div class="emi-card">
                    <div class="left_blk">
                      <div class="step-circle progress-50">
                        <div class="circle-inner ">
                        </div>
                      </div>
                      <label>{moment(orderData.installmentDetails[0].installments[0].due_date).format("ll")}</label>
                    </div>
                    <div class="right_blk">
                      <p>AED {orderData.installmentDetails[0].installments[0].amount}</p>
                    </div>
                  </div>


                  <div class="emi-card">
                    <div class="left_blk">
                      <div class="step-circle progress-75">
                        <div class="circle-inner ">
                        </div>
                      </div>
                      <label>{moment(orderData.installmentDetails[0].installments[1].due_date).format("ll")}</label>
                    </div>
                    <div class="right_blk">
                      <p>AED {orderData.installmentDetails[0].installments[1].amount}</p>
                    </div>
                  </div>

                  <div class="emi-card">
                    <div class="left_blk">
                      <div class="step-circle progress-100">
                        <div class="circle-inner ">
                        </div>
                      </div>
                      <label>{moment(orderData.installmentDetails[0].installments[2].due_date).format("ll")}</label>
                    </div>
                    <div class="right_blk">
                      <p>AED {orderData.installmentDetails[0].installments[2].amount}</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        }


        {
          this.state.openRefundPopup &&
          <RefundPopup
            data={this.state.refundPopupData}
            openPopup={this.state.openRefundPopup}
            closePopup={this.closeRefundPopup}
            callback={this.fetchOrder}
          />
        }
        {
          this.state.openLogisticsPopup &&
          <LogisticsPopup
            data={this.state.logisticsPopupData}
            openPopup={this.state.openLogisticsPopup}
            closePopup={this.closeLogisticsPopup}
          />
        }
        {
          this.state.openCancelOrderPopup &&
          <CancelOrderPopup
            data={this.state.cancelOrderData}
            openPopup={this.state.openCancelOrderPopup}
            closePopup={this.closeCancelOrderPopup}
            type="consignment"
          />
        }
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrder: (data) => {
      return dispatch(fetchOrder(data));
    },
    updateConsignmentStatus: (data) => {
      return dispatch(updateConsignmentStatus(data));
    },
    updateOrderReturnStatus: (data) => {
      return dispatch(updateOrderReturnStatus(data));
    }
  }
}

OrderDetail = connect(null, mapDispatchToProps)(OrderDetail);

export default withRouter(OrderDetail);
