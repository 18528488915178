import React, { Component, PropTypes } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';


const renderTimePicker = ({ input, id, date, callback, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {

    return(
      <div className={parentDivClass ? parentDivClass : ''} >
        {type != "hidden" && (<label>{label}</label>)}
        <TimePicker
          name={input.name}
          className={ ` ${fieldClass ? fieldClass:' form-control  '} ${touched && error ? ' is-invalid ':'form-control'}` }
          defaultValue={input.value?moment(input.value, 'hh:mm a'):null}
          use12Hours
          inputReadOnly
          showSecond={false}
          minuteStep={1}
          onChange={(newValue, g) => {
            if(newValue)
              input.onChange(newValue.format("hh:mm a"))
          }}

        />
        {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
        {
        	notes && (<div className="help-notes"><small>{notes}</small></div>)
        }
      </div>
    )
  }

export default renderTimePicker;
