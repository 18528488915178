import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-dna';
import { API_ROOT } from '../../constants';
const UPLOAD_ENDPOINT = "attachment/create";


const renderCKEdtior = ({ input, id, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadfile", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_ROOT}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                resolve({
                  default: res.responseData.filePath
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className={parentDivClass ? parentDivClass : 'form-group'} >
      {type != "hidden" && (<label>{label}</label>)}
      <CKEditor
        {...input} content={input.value} activeClass={` ${fieldClass ? fieldClass : ' ckeditor-container  '} ${touched && error ? '  is-invalid ' : ''}`}
        // config={config}
        // config={{
        //   extraPlugins: [uploadPlugin]
        // }}
        config={{
          toolbar: {
            items: [
              'heading', '|',
              'fontfamily', 'fontsize', '|',
              'alignment', '|',
              'fontColor', 'fontBackgroundColor', '|',
              'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
              'link', '|',
              // 'outdent', 'indent', '|',
              'bulletedList', 'numberedList', 'todoList', '|',
              // 'code', 'codeBlock', '|',
              // 'insertTable', '|',
              'uploadImage',
              // 'blockQuote', 
              '|',
              'undo', 'redo'
            ],

          },

          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
              { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
              { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
              { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
            ]
          },
          image: {
            // You need to configure the image toolbar, too, so it uses the new style buttons.
            toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:side', 'imageStyle:alignCenter', 'imageStyle:alignRight'],


          },
          link: {
            // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
            addTargetToExternalLinks: true,

            // Let the users control the "download" attribute of each link.
            decorators: [
              {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                  download: 'download'
                }
              }
            ]
          },
          extraPlugins: [uploadPlugin]
        }}
        editor={ClassicEditor}
        data={input.value}
        onInit={editor => {
          // You can store the "editor" and use when it is needed.
          //console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          //const data = editor.getData();
          input.onChange(editor.getData());
          console.log(editor.getData());
        }}
        onBlur={editor => {
          //input.onBlur(editor.getData());
          //console.log( 'Blur.', editor.getData() );
        }}
        onFocus={editor => {
          //console.log( 'Focus.', editor );
        }}
      />
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
      {
        notes && (<div className="help-notes"><small>{notes}</small></div>)
      }
    </div>

  )
}

export default renderCKEdtior;



/*import React, { Component, PropTypes } from 'react';
import CKEditor from "react-ckeditor-component";
const config = {
  toolbarGroups: [
    { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    { name: 'forms', groups: [ 'forms' ] },
    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    { name: 'links', groups: [ 'links' ] },
    { name: 'insert', groups: [ 'insert' ] },
    { name: 'styles', groups: [ 'styles' ] },
    { name: 'colors', groups: [ 'colors' ] },
    { name: 'tools', groups: [ 'tools' ] },
    { name: 'others', groups: [ 'others' ] },
    { name: 'about', groups: [ 'about' ] }
  ],
  height: 80,
  autoGrow_minHeight: 80,
  autoGrow_maxHeight: 500,
  removeButtons: 'Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Radio,Form,TextField,Textarea,Select,Button,ImageButton,HiddenField,About,Maximize,ShowBlocks,Styles,Font,Format,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Anchor,BidiLtr,BidiRtl,Language,Outdent,Indent,Subscript,Superscript,Strike,CreateDiv,Blockquote,CopyFormatting,RemoveFormat,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Checkbox'
}

const renderCKEdtior = ({ input, id, readOnly, notes, parentDivClass, fieldClass, label, type, placeholder, meta: { touched, error, invalid, warning } }) => {

return (
     <div className={parentDivClass ? parentDivClass : ''} >
       {type != "hidden" && (<label>{label}</label>)}
       <CKEditor
          {...input}  content={input.value} activeClass={ ` ${fieldClass ? fieldClass:' ckeditor-container  '} ${touched && error ? '  is-invalid ':''}` }

           config={config}

           events={{
            blur: (evt)=>{

              input.onBlur(evt.editor.getData());
            },
            change: (evt)=>{
              input.onChange(evt.editor.getData());
            }
           }}

          />

       {touched && ((error && <label className="error">{error}</label>) || (warning && <div className="invalid-feedback">{warning}</div>))}
       {
          notes && (<div className="help-notes"><small>{notes}</small></div>)
       }
     </div>

  )
}

export default renderCKEdtior;*/
