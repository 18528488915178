import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import {addBrand, fetchBrand} from '../../../actions/brands';
import {uploadImage} from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter brand name";
    hasErrors = true;
  }
  if (!values.attachmentId || values.attachmentId === '') {
    errors.attachmentId = "Upload brand logo image";
    hasErrors = true;
  }

  return hasErrors && errors;
}

class BrandsAdd extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
      brandId: props.match.params.brandId?props.match.params.brandId:null,
      arrayLocalImages: [],
      arrayBannerImageWeb: [],
      arrayBannerImageApp: []
    }
    this.onDrop = this.onDrop.bind(this);

  }

  submitForm(values, dispatch, props){
    let apiPath = "/admin/addBrand";
    let method = "POST";
    if(this.state.brandId){
      apiPath = "/admin/editBrand";
      method = "PATCH";

    }

    return this.props.addBrand(values, apiPath, method).then((response) => {


      toast.success(response.value.data.message);
      window.history.back();
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.brandId){
      this.fetchBrand(this.state.brandId);
    }
  }

  fetchBrand(brandId){
		this.setState({fetchingRecords: true});
    this.props.fetchBrand({id: brandId}).then((response) => {
      let brandData = response.value.data.responseData.data;
      let formData = {};
      let arrayLocalImages = [];
      let arrayBannerImageWeb = [];
      let arrayBannerImageApp = [];
      if(brandData){
        formData = {
          id: brandData.id,
          name: brandData.name,
          attachmentId: (brandData.attachment && brandData.attachment.attachmentId)?brandData.attachment.attachmentId:null,
          attachmentIdBannerImageWeb: (brandData.attachmentBannerImageWeb && brandData.attachmentBannerImageWeb.attachmentId)?brandData.attachmentBannerImageWeb.attachmentId:null,
          attachmentIdBannerImageApp: (brandData.attachmentBannerImageApp && brandData.attachmentBannerImageApp.attachmentId)?brandData.attachmentBannerImageApp.attachmentId:null,
        }
        if(brandData.attachment && brandData.attachment.attachmentId){
          arrayLocalImages.push(brandData.attachment);
        }
        if(brandData.attachmentBannerImageWeb && brandData.attachmentBannerImageWeb.attachmentId){
          arrayBannerImageWeb.push(brandData.attachmentBannerImageWeb);
        }
        if(brandData.attachmentBannerImageApp && brandData.attachmentBannerImageApp.attachmentId){
          arrayBannerImageApp.push(brandData.attachmentBannerImageApp);
        }
      }
      this.props.initialize(formData);
      this.setState({fetchingRecords: false, arrayLocalImages, arrayBannerImageWeb, arrayBannerImageApp});
      //this.props.initialize(categoryData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  componentWillReceiveProps(nextProps){
  }

  onDrop(picture, type) {
    var objThis = this;

    //for (var i = 0; i < pictures.length; i++) {
      var form_data = new FormData();
      var length = picture.length;
      var file_data = picture[length - 1];
      if(file_data && file_data.size > 10485760){ //10485760
        alert("Max file size allowed is 10MB");
        return;
      }

      this.setState({[type]: true});
      form_data.append("uploadfile", file_data);
      this.props.uploadImage(form_data, {upload_type:"brand_image"}).then((response) => {
        this.setState({[type]: false});
        var payload = response.value.data.responseData;
        var arrayLocalImages = [];
        if(payload && payload.id){
          arrayLocalImages.push(payload);

					var idArray = [payload.id];
          if(type == 'imageUploading'){
            this.setState({arrayLocalImages: arrayLocalImages});
            this.props.change('attachmentId', idArray[0]);

          }else if(type == 'bannerImageWebUploading'){
            this.setState({arrayBannerImageWeb: arrayLocalImages});
            this.props.change('attachmentIdBannerImageWeb', idArray[0]);

          }else if(type == 'bannerImageAppUploading'){
            this.setState({arrayBannerImageApp: arrayLocalImages});
            this.props.change('attachmentIdBannerImageApp', idArray[0]);
          }

        }
      }).catch((error)=>{
        this.setState({[type]: false});
        if (error.response) {
          //toast.error(error.response.data.message);
        } else if (error.request) {
          //toast.error("Network error!");
        } else {
          //toast.error(error.message);
        }
      });

    //}
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/brands/list">Brands</Link></li>
        <li className="breadcrumb-item active">{this.state.brandId?"Edit":"Add"}</li>
      </ol>
      <h5 className="h5 mb-4">{this.state.brandId?"Edit":"Add"} Brand</h5>
      <section className="formBg" style={{maxWidth:"850px"}}>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
          <div className="row">
            <div className=" col-lg-7">
              <Field
                name="name"
                component={renderField}
                type="text"
                label="Brand Name"
              />
            </div>
            <div className=" col-lg-7">
              <ImageUploader
								className={this.state.imageUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText={this.state.brandId? 'Update Brand Logo Image':'Upload Brand Logo Image'}
                onChange={(e) => this.onDrop(e, 'imageUploading')}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="attachmentId"
                component={renderField}
                type="hidden"
                label=""
              />
              {
                this.state.arrayLocalImages.length > 0 &&
                this.state.arrayLocalImages.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                     <figure className="img"><img src={obj.filePath} alt=""/></figure>
  									 {/*<span className="pro-img-del icon-close" onClick = {(e) => this.deleteLocalImage(e, obj.id)}></span>*/}
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="row">
            <div className=" col-lg-6">
              <ImageUploader
								className={this.state.bannerImageWebUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText={this.state.brandId? 'Update Brand Banner Image Web (1400x250)':'Upload Brand Banner Image Web (1400x250)'}
                onChange={this.onDrop}
                onChange={(e) => this.onDrop(e, 'bannerImageWebUploading')}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="attachmentIdBannerImageWeb"
                component={renderField}
                type="hidden"
                label=""
              />
              {
                this.state.arrayBannerImageWeb.length > 0 &&
                this.state.arrayBannerImageWeb.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                     <figure className="img"><img src={obj.filePath} alt=""/></figure>
  									 {/*<span className="pro-img-del icon-close" onClick = {(e) => this.deleteLocalImage(e, obj.id)}></span>*/}
                    </div>
                  )
                })
              }
            </div>
            <div className=" col-lg-6">
              <ImageUploader
								className={this.state.bannerImageAppUploading?"loading":""}
                accept=".jpg, .jpeg, .png, .gif"
                singleImage={true}
                label=""
                withIcon={false}
                buttonText={this.state.brandId? 'Update Brand Banner Image App (767x350)':'Upload Brand Banner Image App (767x350)'}
                onChange={(e) => this.onDrop(e, 'bannerImageAppUploading')}
                imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                maxFileSize={10485760 * 100}
							/>
              <Field
                name="attachmentIdBannerImageApp"
                component={renderField}
                type="hidden"
                label=""
              />
              {
                this.state.arrayBannerImageApp.length > 0 &&
                this.state.arrayBannerImageApp.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                     <figure className="img"><img src={obj.filePath} alt=""/></figure>
  									 {/*<span className="pro-img-del icon-close" onClick = {(e) => this.deleteLocalImage(e, obj.id)}></span>*/}
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="form-btn-group">
            <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;
          </div>
        </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBrand: (data, apiPath, method) => {
      return dispatch(addBrand(data, apiPath, method));
    },
    fetchBrand: (data) => {
      return dispatch(fetchBrand(data));
    },
    uploadImage: (data) => {
      return dispatch(uploadImage(data));
    }
  }
}

BrandsAdd = connect(null, mapDispatchToProps)(BrandsAdd);

BrandsAdd = reduxForm({
  form: 'BrandsAdd',
  validate,
  //enableReinitialize: true
})(BrandsAdd);

export default withRouter(BrandsAdd);
