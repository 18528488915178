import React, { Component } from 'react';
import SignInForm from '../../components/admin/SignInForm';
import { ToastContainer } from 'react-toastify';
import {Link} from 'react-router-dom';
import LeftPanel from './LeftPanel';
import 'react-toastify/dist/ReactToastify.css';

class SignIn extends Component {
  render() {
    return (
      <div className="container">
        <ToastContainer autoClose={5000} />
         <section className="loginView">
            <div className="row">
               <LeftPanel />
               <div className="col-md-6">
                  <SignInForm />
               </div>
            </div>
         </section>
      </div>
    )
  }
}
export default SignIn;
