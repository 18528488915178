import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset, formValueSelector } from 'redux-form';
import {connect} from 'react-redux';
import renderField from '../../FormFields/renderField';
import {makeRefund} from '../../../actions/orders';
import {toast} from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {normalizeAmountField} from '../../../constants';


//Client side validation
function validate(values, props) {

}



class RefundPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.openPopup,
      data: props.data
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.initialize(this.state.data);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    this.props.closePopup();
  }



  submitForm(values, dispatch, props){

    values = Object.assign(values, {amount: parseFloat(values.amount), paymentMethod: parseInt(values.paymentMethod)});

    this.setState({fetchingRecords: true});
    return this.props.makeRefund(values).then((response) => {
      /*if(this.props.callback){
        this.props.callback(values.orderId);
      }*/
      this.setState({fetchingRecords: false});
      this.props.closePopup();
      toast.success(response.value.data.message);

    }).catch(function(error){
      if (error.response) {
        throw new SubmissionError(error.response.data.error);
        toast.error(error.response.data.flash_message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }

    });
  }

  componentWillReceiveProps(nextProps){

  }




  render() {
    const {handleSubmit, pristine, submitting} = this.props;
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className="largePopup">
        <ModalHeader toggle={this.toggle} charCode="&times;">Generate Refund</ModalHeader>
        <div className="container">
          <div className="card">
            <div className="card-body card-padding">
              <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
                <ModalBody>
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <Field
                        name="amount"
                        component={renderField}
                        type="text"
                        label="Amount"
                        normalize={normalizeAmountField}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <label>Payment Method</label>
                      <ul className = "refund_ul">
                        <li>
                          <label>
                            <Field
                              name="paymentMethod"
                              component="input"
                              type="radio"
                              label="Payment Method"
                              value="1"
                            />
                            Wallet
                          </label>
                        </li>
                        <li>
                          <label>
                          <Field
                            name="paymentMethod"
                            component="input"
                            type="radio"
                            value="2"
                          />
                          Original Source
                          </label>
                        </li>
                        </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-md-4 col-md-offset-4">
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="form-btn-group marginT0">
                    <button className="btn btn-secondary hvr-shadow" onClick={this.toggle}>Cancel</button>
                    <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting}>Submit</button>
                  </div>
                </ModalFooter>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    makeRefund: (data) => {
      return dispatch(makeRefund(data));
    }

  }
}

RefundPopup = reduxForm({
  form: 'RefundPopup',
  validate
})(RefundPopup)



export default connect(null, mapDispatchToProps)(RefundPopup);
