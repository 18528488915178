import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import { merchantFetchProducts, merchantUpdateInventoryPriceOnServer } from '../../../actions/products';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import { getCategoryLevelDashes, renderPrice, normalizeAmountField, normalizeNumber } from '../../../constants';
import { fetchCategoryTree } from '../../../actions/categories';
import { fetchAllBrands } from '../../../actions/brands';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import $ from 'jquery';

class MerchantProductsList extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		this.state = {
			params: queryParams,
			pageUrl: '/merchant/products/list',
			records: null,
			brands: [],
			categoryTree: [],
			filters: [
				'name',
				'brandId',
				'categoryId'
			]
		};
	}



	componentDidMount() {

		this.fetchAllBrands();
		this.fetchCategoryTree();
		this.fetchProducts(this.state.params);
		this.updateFilterForm(this.state.params);

	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}

	fetchAllBrands() {
		this.props.fetchAllBrands().then((response) => {
			let brands = response.value.data.responseData.data;
			this.setState({ brands: brands });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	fetchProducts(params) {
		this.setState({ fetchingRecords: true });
		this.props.merchantFetchProducts(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchProducts(params);
		}
	}

	submitFilterForm(values, dispatch, props) {
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}

	fetchCategoryTree() {
		this.setState({ fetchingRecords: true });
		this.props.fetchCategoryTree().then((response) => {
			let records = response.value.data.responseData;
			let categoryTree = [];
			if (records) {
				for (var i = 0; i < records.length; i++) {

					categoryTree.push({ id: records[i].id, categoryName: records[i].name, isDisabled: true });
					for (var k = 0; k < records[i].children.length; k++) {
						categoryTree.push({ id: records[i].children[k].id, categoryName: records[i].children[k].name, isDisabled: false });
					}
				}
			}
			this.setState({ categoryTree });

		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		this.props.history.push(this.state.pageUrl + '?page=1');
	}

	updatePrice(e, product, index) {
		let val = normalizeAmountField(e.target.value);
		let records = this.state.records;
		if (records && records.data && records.data.length > 0) {
			records.data[index] = Object.assign(records.data[index], { merchantBasePrice: val });
		}
		this.setState({ records: records });
	}

	updateCostPrice(e, product, index) {
		let val = normalizeAmountField(e.target.value);
		let records = this.state.records;
		if (records && records.data && records.data.length > 0) {
			records.data[index] = Object.assign(records.data[index], { merchantCostPrice: val });
		}
		this.setState({ records: records });
	}

	updateInventory(e, product, index) {
		let val = normalizeNumber(e.target.value);
		let records = this.state.records;
		if (records && records.data && records.data.length > 0) {
			records.data[index] = Object.assign(records.data[index], { merchantInventory: val });
		}
		this.setState({ records: records });
	}

	updateInventoryOnServer(e, product, index) {
		if (!isNaN(parseInt(e.target.value))) {
			this.updateMerchantInventory({ productId: product.productId, productIdMongo: product._id, key: 'quantity', value: parseInt(e.target.value) });
		}

	}
	updatePriceOnServer(e, product, index) {
		if (!isNaN(parseFloat(e.target.value))) {
			this.updateMerchantInventory({ productId: product.productId, productIdMongo: product._id, key: 'basePrice', value: parseFloat(e.target.value) });
		}
	}

	updateCostPriceOnServer(e, product, index) {
		if (!isNaN(parseFloat(e.target.value))) {
			this.updateMerchantInventory({ productId: product.productId, productIdMongo: product._id, key: 'costPrice', value: parseFloat(e.target.value) });
		}
	}

	updateMerchantInventory(values) {
		this.props.merchantUpdateInventoryPriceOnServer(values).then((response) => {

		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records } = { ...this.state }
		return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/product-management/products/list?page=1">Products</Link></li>
					<li className="breadcrumb-item active">List</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">Products</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
							<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
								<span className="icon-filter mr-1"></span>
								Filters
							</a>
						</div>
					</div>
				</div>
				<div className="collapse" id="filter" >
					<div className="filter-dropdown">
						<form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
							<div className="row">
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Category"
										name='categoryId'
										optionLabel='categoryName'
										optionValue='id'
										options={this.state.categoryTree}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}
										isClearable={true}

									/>
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										label="Brand"
										name='brandId'
										optionLabel='name'
										optionValue='id'
										options={this.state.brands}
										component={renderReactSelect}
										placeholder="Select"
										multi={false}
										isClearable={true}
									/>
								</div>
							</div>
							<div className="form-btn-group mt-3 text-right">
								<button type="button" onClick={(e) => this.clearFilters(e)} className="btn btn-clear hvr-shadow mr-1">Clear</button>
								<button type="submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
					</div>
				</div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID/SKU</th>
									<th>Image</th>
									<th>Name</th>
									<th>Inventory</th>
									<th>Price</th>
									<th>Cost Price</th>
									<th>Category</th>
									<th>Brand</th>

									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj._id}>
												<td className="nowrap">{obj.productId}<br /><Link to={"/merchant/products/list/view/" + obj.productId}>{obj.sku}</Link></td>
												<td>{(obj.featuredImage && obj.featuredImage.filePath) ? <img style={{ "maxWidth": "none", width: "auto", height: "50px" }} src={obj.featuredImage.filePath} /> : ""}</td>
												<td className="nowrap">{obj.title}</td>
												<td><input type="text" value={obj.merchantInventory == null ? '-' : obj.merchantInventory} onBlur={(e) => this.updateInventoryOnServer(e, obj, index)} onChange={(e) => this.updateInventory(e, obj, index)} style={{ width: "50px" }} /></td>
												<td className="nowrap">AED <input type="text" value={obj.merchantBasePrice == null ? '-' : obj.merchantBasePrice} onBlur={(e) => this.updatePriceOnServer(e, obj, index)} onChange={(e) => this.updatePrice(e, obj, index)} style={{ width: "70px" }} /></td>
												<td className="nowrap">AED <input type="text" value={obj.merchantCostPrice == null ? '-' : obj.merchantCostPrice} onBlur={(e) => this.updateCostPriceOnServer(e, obj, index)} onChange={(e) => this.updateCostPrice(e, obj, index)} style={{ width: "70px" }} /></td>
												<td>{obj.categoryName}</td>
												<td>{obj.brandName}</td>
												{/*<td>{obj.isPublished?<span className="green_status">Yes</span>:<span className="red_status">No</span>}</td>*/}
												<td>
													<span className="dropdown threeDots">
														<a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
															<span></span> <span></span> <span></span>
														</a>
														<ul className="dropdown-menu" role="menu">
															<li><Link className="dropdown-item" title="View" to={"/merchant/products/list/view/" + obj.productId} >View</Link></li>
														</ul>
													</span>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
			</>

		);
	}
}

MerchantProductsList = reduxForm({
	form: 'MerchantProductsListFilter'
})(MerchantProductsList);

const mapDispatchToProps = (dispatch) => {
	return {
		merchantFetchProducts: (data) => {
			return dispatch(merchantFetchProducts(data));
		},
		fetchCategoryTree: (data) => {
			return dispatch(fetchCategoryTree(data));
		},
		fetchAllBrands: (data) => {
			return dispatch(fetchAllBrands(data));
		},
		merchantUpdateInventoryPriceOnServer: (data) => {
			return dispatch(merchantUpdateInventoryPriceOnServer(data));
		}
	}
}


export default withRouter(connect(null, mapDispatchToProps)(MerchantProductsList));
