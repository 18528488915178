import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection } from 'redux-form';
import renderField from '../../FormFields/renderField';
import {addCurrency, fetchCurrency} from '../../../actions/currencies';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';



import { toast } from 'react-toastify';

//Client side validation
function validate(values) {
  var errors = {};
  var hasErrors = false;
  if (!values.name || values.name.trim() === '') {
    errors.name = "Enter currency name";
    hasErrors = true;
  }
  if (!values.currencyCode || values.currencyCode.trim() === '') {
    errors.currencyCode = "Enter currency code";
    hasErrors = true;
  }
  if (!values.currencySymbol || values.currencySymbol.trim() === '') {
    errors.currencySymbol = "Enter currency symbol";
    hasErrors = true;
  }
  return hasErrors && errors;
}

class CurrenciesAdd extends Component {
	constructor(props){
    super(props);
    this.state = {
      dataLoaded: false,
      currencyId: props.match.params.currencyId?props.match.params.currencyId:null
    }
  }

  submitForm(values, dispatch, props){
    let apiPath = "/admin/addCurrency";
    let method = "POST";
    if(this.state.currencyId){
      apiPath = "/admin/editCurrency";
      method = "PATCH";
    }
    return this.props.addCurrency(values, apiPath, method).then((response) => {
      if(this.state.currencyId){
        this.props.history.push("/admin/settings/currencies/list");
      }else{
        this.props.reset();
      }

      toast.success(response.value.data.message);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
        if(error.response.data.errors){
          throw new SubmissionError(error.response.data.errors);
        }
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
    });
  }

  componentDidMount(){
    if(this.state.currencyId){
      this.fetchCurrency(this.state.currencyId);
    }
  }

  fetchCurrency(currencyId){
		this.setState({fetchingRecords: true});
    this.props.fetchCurrency({id: currencyId}).then((response) => {
      let currencyData = response.value.data.responseData.data;
      this.setState({fetchingRecords: false, currencyId: currencyId});
      this.props.initialize(currencyData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  componentWillReceiveProps(nextProps){
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    return (
      <>
      <div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1 ">
        <li className="breadcrumb-item"><Link to="/admin/currencies/list">Currencies</Link></li>
        <li className="breadcrumb-item active">{this.state.currencyId?"Edit":"Add"}</li>
      </ol>
        <h5 className="h5 mb-4">{this.state.currencyId?"Edit":"Add"} Currency</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
        <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
          <div className="row">
            <div className=" col-lg-7">
              <Field
                name="name"
                component={renderField}
                type="text"
                label="Currency Name"
              />
            </div>
            <div className=" col-lg-7">
              <Field
                name="currencyCode"
                component={renderField}
                type="text"
                label="Currency Code"
              />
            </div>
            <div className=" col-lg-7">
              <Field
                name="currencySymbol"
                component={renderField}
                type="text"
                label="Currency Symbol"
              />
            </div>
            </div>
            <div className="form-btn-group">
              <Link to="/admin/settings/currencies/list" type="button" className="btn btn-secondary hvr-shadow">Cancel</Link>
              <button type="submit"  className={`${"btn btn-primary hvr-shadow"} ${submitting?" btn-loader ":"  "}` } disabled = {submitting?true:false} >Save</button>&nbsp;

            </div>
          </form>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCurrency: (data, apiPath, method) => {
      return dispatch(addCurrency(data, apiPath, method));
    },
    fetchCurrency: (data) => {
      return dispatch(fetchCurrency(data));
    }
  }
}

CurrenciesAdd = connect(null, mapDispatchToProps)(CurrenciesAdd);

CurrenciesAdd = reduxForm({
  form: 'CurrenciesAdd',
  validate,
  //enableReinitialize: true
})(CurrenciesAdd);

export default withRouter(CurrenciesAdd);
