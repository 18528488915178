import React, { Component } from 'react';
import {connect} from 'react-redux';
import {logoutUser} from '../../actions/users';

class Logout extends Component {
  componentDidMount(){
    localStorage.removeItem('jwtToken');
    this.props.logoutUser();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <main className="main-content col">
            <div className="main-content-container container-fluid px-4 my-auto h-100">
              <div className="row no-gutters h-100">
                <div className="col-lg-3 col-md-5 auth-form mx-auto my-auto">
                  <h5 className="auth-form__title text-center mb-4">&nbsp;</h5>
                  <h5 className="auth-form__title text-center mb-4">Logging out...</h5>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default connect(null, {logoutUser})(Logout);
