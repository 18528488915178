import React, { Component, PropTypes } from 'react';


const renderTextarea = ({ input, id, rows, maxLength, labelClass, innerParentDivClass, readOnly, initialValue, notes, parentDivClass, fieldClass, label, type, placeholder,props, meta: {initial, touched, error, invalid, warning } }) => {
return(
  <div className={ `${"form-group"} ${parentDivClass ? parentDivClass : ' '} ${touched && error ? ' has-error ':''}`} >
    <label className={labelClass?labelClass:""}>{label}</label>
    <div className={innerParentDivClass?innerParentDivClass:""}>
      <textarea rows = {rows?rows:"5"} {...input} maxLength = {maxLength?maxLength:''}   className={ ` ${fieldClass ? fieldClass:' form-control  '} ${touched && error ? ' ':''}` } placeholder={placeholder?placeholder:''} />
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}
      {
    	   notes && (<div className="help-notes"><small>{notes}</small></div>)
       }
     </div>
  </div>
)
}

export default renderTextarea;
