import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_ANSWERS = 'FETCH_ANSWERS';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_ANSWER = 'DELETE_ANSWER';
export const APPROVE_QUESTION = 'APPROVE_QUESTION';
export const APPROVE_ANSWER = 'APPROVE_ANSWER';
export const MARK_ANSWER_AS_DEFAULT = 'MARK_ANSWER_AS_DEFAULT';

export function fetchQuestions(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/questionListing`
  });
  return {
    type: FETCH_QUESTIONS,
    payload: request
  };
}

export function deleteQuestion(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteQuestion`
  });
  return {
    type: DELETE_QUESTION,
    payload: request
  };
}

export function approveQuestion(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/approveQuestion`
  });
  return {
    type: APPROVE_QUESTION,
    payload: request
  };
}

export function fetchAnswers(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/questionAnswerListing`
  });
  return {
    type: FETCH_ANSWERS,
    payload: request
  };
}

export function deleteAnswer(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteAnswer`
  });
  return {
    type: DELETE_ANSWER,
    payload: request
  };
}

export function approveAnswer(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/markAnswerAsApproved`
  });
  return {
    type: APPROVE_ANSWER,
    payload: request
  };
}

export function markAnswerAsDefault(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/markAnswerAsDefault`
  });
  return {
    type: MARK_ANSWER_AS_DEFAULT,
    payload: request
  };
}
