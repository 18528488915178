import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_DISPUTES = 'FETCH_DISPUTES';
export const FETCH_COUPONS = 'FETCH_COUPONS';
export const ADD_COUPON = 'ADD_COUPON';
export const UPLOAD_PRODUCT_IMAGE = 'UPLOAD_PRODUCT_IMAGE';
export const FETCH_DISPUTE_ORDER_DETAIL = 'FETCH_DISPUTE_ORDER_DETAIL';
export const FETCH_ORDERS_LIST = 'FETCH_ORDERS_LIST';
export const FETCH_BOOKING_DETAIL = 'FETCH_BOOKING_DETAIL';
export const UPDATE_CONSIGNMENT_STATUS = 'UPDATE_CONSIGNMENT_STATUS';
export const UPDATE_RETURN_ORDER = 'UPDATE_RETURN_ORDER';
export const MAKE_REFUND = 'MAKE_REFUND';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';




export function fetchBookingDetail(params){
  const request = axios({
    method: 'GET',
    params: params,
    url: `${API_ROOT}/admin/order/details`
  });
  return {
    type: FETCH_BOOKING_DETAIL,
    payload: request
  };
}

export function fetchPayments(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/payments/list`
  });
  return {
    type: FETCH_PAYMENTS,
    payload: request
  };
}



export function fetchOrders(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getOrderListing`
  });
  return {
    type: FETCH_ORDERS,
    payload: request
  };
}

export function fetchOrder(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getOrderDetails`
  });
  return {
    type: FETCH_ORDER,
    payload: request
  };
}

export function updateConsignmentStatus(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/updateOrderStatus`
  });
  return {
    type: UPDATE_CONSIGNMENT_STATUS,
    payload: request
  };
}

export function fetchDisputes(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/disputes/list`
  });
  return {
    type: FETCH_DISPUTES,
    payload: request
  };
}

export function fetchCoupons(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/coupons/list`
  });
  return {
    type: FETCH_COUPONS,
    payload: request
  };
}

export function addCoupon(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/coupon/create`
  });
  return {
    type: ADD_COUPON,
    payload: request
  };
}

export function uploadProductImage(formValues, queryParams) {
  const request = axios({
    method: 'POST',
    params: queryParams,
    data: formValues,
    url: `${API_ROOT}/attachment/create`
  });
  return {
    type: UPLOAD_PRODUCT_IMAGE,
    payload: request
  };
}

export function fetchDisputeOrderDetail(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/dispute/getDisputeInfo`
  });
  return {
    type: FETCH_DISPUTE_ORDER_DETAIL,
    payload: request
  };
}

export function updateOrderReturnStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateReturnOrder`
  });
  return {
    type: UPDATE_RETURN_ORDER,
    payload: request
  };
}

export function makeRefund(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/makeRefund`
  });
  return {
    type: MAKE_REFUND,
    payload: request
  };
}

export function fetchTransactions(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getOrderTransactions`

  });
  return {
    type: FETCH_TRANSACTIONS,
    payload: request
  };
}

export function createShipment(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/createShipment`
  });
  return {
    type: CREATE_SHIPMENT,
    payload: request
  };
}

export function cancelWholeOrder(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/cancelWholeOrder`
  });
  return {
    payload: request
  };
}

export function cancelConsignment(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/cancelOrder`
  });
  return {
    payload: request
  };
}
