import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderDatePicker from '../../FormFields/renderDatePicker';
import { CSVLink, CSVDownload } from "react-csv";
import {fetchSalesByCouponCodeReport} from '../../../actions/reports';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import {currencyFormat, API_ROOT, CURRENCY} from '../../../constants';
import axios from 'axios';

import $ from 'jquery';


class SalesByCouponCodeReport extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.page){
			queryParams.page = 1;
		}
		var startDate = new Date();
		var sDate = startDate.setDate(startDate.getDate()-30);

		this.state = {
			startDate: new Date(sDate),
			endDate: new Date(),
			params: queryParams,
			pageUrl: '/admin/salesReports/salesByCouponCode/list',
			records: null,
			csvData: null,
			filters: [
				'startTime',
				'endTime',
				'couponCode'

			]
		};
		this.fetchCsvData = this.fetchCsvData.bind(this);
		this.csvLink = React.createRef();
    this.updateDate = this.updateDate.bind(this);
  }



	componentDidMount(){
		this.props.change('startTime', this.state.startDate);
		this.props.change('endTime', this.state.endDate);
    this.fetchSalesByCouponCodeReport(this.state.params);
		setTimeout(() => {
			$('.react-datepicker__input-container input').keydown(function(e) {
			   e.preventDefault();
			   return false;
			});
		}, 500);

  }

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	fetchSalesByCouponCodeReport(params){
		this.setState({fetchingRecords: true});
		params = Object.assign(params, {startTime: moment(this.state.startDate).format(), endTime: moment(this.state.endDate).format()});
    this.props.fetchSalesByCouponCodeReport(params).then((response) => {
			this.setState({records: response.value.data.responseData, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			var queryParams = queryString.parse(nextProps.location.search);
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchSalesByCouponCodeReport(params);
		}
	}






	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?page=1');
	}

	fetchCsvData(){
		this.setState({generatingCsv: true});
		let params = {startTime: moment(this.state.startDate).format(), endTime: moment(this.state.endDate).format(), isCsv: true};
    this.props.fetchSalesByCouponCodeReport(params).then((response) => {
			this.setState({csvData: response.value.data, generatingCsv: false});
			setTimeout((e) => {
				this.csvLink.current.link.click();
			},500);

		}).catch(function(error){
			this.setState({generatingCsv: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

  updateDate(e, field){
		this.setState({[field]: e});
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
		  <>
				<ol className="breadcrumb breadcrumb-v1 marginB10">
					<li className="breadcrumb-item"><Link to="/admin/salesReports/salesByCouponCode/list?page=1">Sales Reports</Link></li>
	        <li className="breadcrumb-item active">Sales by Coupon Code</li>
	      </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Sales by Coupon Code</h5>
          </div>
					<div className="col-md-8">
            <div className="jobs-act-btns">
							<a className="btn-filter mr-1   hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
							<button onClick = {() => this.fetchCsvData()} className={`${"btn-add ml-1  hvr-shadow"} ${this.state.generatingCsv?"btn-loader":""}`} type = 'button'><span className="icon-ic_plus"></span>Export CSV</button>
							<CSVLink
								headers={this.state.csvData && this.state.csvData.headers?this.state.csvData.headers:[]}
								data={this.state.csvData && this.state.csvData.data?this.state.csvData.data:[]}
								filename={'userCouponUsage '+moment(this.state.startDate).format('MM-DD-YYYY')+" - "+moment(this.state.endDate).format('MM-DD-YYYY')+'.csv'}
         				className='hidden'
         				ref={this.csvLink}
         				target='_blank'
							/>


            </div>
          </div>


        </div>
				<div className="collapse show" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="startTime"
										component={renderDatePicker}
										label="Start Date"
										maxDate={this.state.endDate}
										callback={(e) => this.updateDate(e, 'startDate')}
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="endTime"
										component={renderDatePicker}
										label="End Date"
										maxDate={new Date()}
										callback={(e) => this.updateDate(e, 'endDate')}
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="couponCode"
										component={renderField}
										label="Coupon Code"
									/>
								</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                {/* <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button> */}
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>Coupon ID</th>
                  <th>Coupon Code</th>
									<th>Usage</th>
									<th>Total Discount</th>
									<th>Name</th>
									<th>Mobile</th>
									<th>Email</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.data && records.data.length > 0 &&
							records.data.map((obj, index) => {
                return(
									<tr key={obj.index}>
										<td className="nowrap">{obj.coupon_id}</td>
										<td>{obj.couponCode}</td>
										<td className="nowrap">{obj.usage}</td>
										<td className="nowrap">{obj.totalDiscount}</td>
										<td className="nowrap">{obj.name}</td>
										<td className="nowrap">+{obj.countryCode} {obj.mobile}</td>
										<td className="nowrap">{obj.email}</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.data && this.state.records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

SalesByCouponCodeReport = reduxForm({
  form: 'SalesByCouponCodeReportFilter'
})(SalesByCouponCodeReport);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesByCouponCodeReport: (data) => {
      return dispatch(fetchSalesByCouponCodeReport(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(SalesByCouponCodeReport));
