import React, {Component} from 'react';
export default class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message?props.message:"Please wait..."
    }
  }
  render() {
    return(
      <div className="page-loader listing-loader-ui">
        <div className="preloader pls-blue">
          <img src = "/assets/img/loader.gif" alt="" />
          <p>{this.state.message}</p>
        </div>
      </div>
    );
  }
 }
