import React, { Component, Fragment } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset, FieldArray } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import renderTextarea from '../../FormFields/renderTextarea';
import renderCKEditor from '../../FormFields/renderCKEditor';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import {fetchAttributes, addAttribute, deleteAttribute, fetchCategory} from '../../../actions/categories';
import {fetchAttributeGroups} from '../../../actions/products';
import {ADD_ON_OPTIONS, VARIATION_OPTIONS, ATTRIBUTE_TYPE_OPTIONS} from '../../../constants';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../../../components/Loader';

function validate(values) {
	var errors = {};
	var hasErrors = false;

	if (!values.name || values.name.trim() == '') {
		errors.name = 'Required';
		hasErrors = true;
	}

  if (typeof values.isVariation == 'undefined' || values.isVariation === '') {
		errors.isVariation = 'Required';
		hasErrors = true;
	}

  /*if (typeof values.addOns == 'undefined'|| values.addOns === '') {
		errors.addOns = 'Required';
		hasErrors = true;
	}*/

  // if (typeof values.type == 'undefined' || values.type === '') {
	// 	errors.type = 'Required';
	// 	hasErrors = true;
	// }

	if (typeof values.attributeGroupId == 'undefined' || values.attributeGroupId === '') {
		errors.attributeGroupId = 'Required';
		hasErrors = true;
	}



	return hasErrors && errors;
}

class AttributesManage extends Component {

	constructor(props){

    super(props);
    var lang = localStorage.getItem('lang');
    if(!lang){
      lang = 'en';
    }
		var queryParams = queryString.parse(props.location.search);
		var parentId = 0;
		if(queryParams.parentId){
			parentId = queryParams.parentId;
		}
		this.state = {
			categoryId: props.match.params.categoryId?props.match.params.categoryId:null,
			formType: 'add',
      addOnOptions: ADD_ON_OPTIONS[lang],
      variationOptions: VARIATION_OPTIONS[lang],
      attributeTypeOptions: ATTRIBUTE_TYPE_OPTIONS[lang],
			categoryData: null,
			parentId: parentId,
			parentCategoryData: null,
			attributeGroups: []
		};



  }

	componentDidMount(){

		this.fetchAttributes(this.state.categoryId);
		this.fetchCategory(this.state.categoryId);
		this.fetchAttributeGroups();
		if(this.state.parentId > 0){
			this.fetchParentCategory(this.state.parentId);
		}
		this.props.initialize({categoryId: this.state.categoryId});
    if(this.state.categoryId){
      this.props.initialize({categoryId: this.state.categoryId});
    }
	}

	fetchCategory(categoryId){
		this.setState({fetchingRecords: true});
    this.props.fetchCategory({id: categoryId}).then((response) => {
      let categoryData = response.value.data.responseData.data;

      this.setState({fetchingRecords: false, categoryData: categoryData});
      //this.props.initialize(categoryData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchParentCategory(categoryId){
		this.setState({fetchingRecords: true});
    this.props.fetchCategory({id: categoryId}).then((response) => {
      let categoryData = response.value.data.responseData.data;

      this.setState({fetchingRecords: false, parentCategoryData: categoryData});
      //this.props.initialize(categoryData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){

	}

	submitForm(values){
    let apiPath = "/admin/addAttribute";
    let method = "POST";
    if(values.id){
      apiPath = "/admin/editAttribute";
      method = "PATCH";

    }

		values = Object.assign(values, {addOns: 0, type: 1});

		this.setState({submittingForm: true});
			this.props.addAttribute(values, apiPath, method).then((response) => {
				this.setState({submittingForm: false});
				toast.success(response.value.data.message);
				this.props.reset();
        this.props.initialize({categoryId: this.state.categoryId});
				 this.fetchAttributes(this.state.categoryId);
				//this.props.history.push('/admin/manage/services/list');
			}).catch((error)=>{
	      this.setState({submittingForm: false});
	      if(error.response) {
	        toast.error(error.response.data.message);
	      }else if (error.request) {
	        toast.error("Network error!");
	      }else {
	        toast.error(error.message);
	      }
			});
	}


  fetchAttributes(id){
		this.setState({fetchingRecords: true});
    this.props.fetchAttributes({categoryId:id}).then((response) => {
      this.setState({fetchingRecords: false});
      var attributes = response.value.data.responseData.data;
			this.setState({attributes});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchAttributeGroups(){
		this.setState({fetchingRecords: true});
    this.props.fetchAttributeGroups().then((response) => {
      this.setState({fetchingRecords: false});
      var attributeGroups = response.value.data.responseData.data;
			this.setState({attributeGroups});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	initializeEditForm(e, index){
		window.scrollTo(0, 0);
		e.preventDefault();
		var record = this.state.attributes[index];

		var formData = {
                      id: record.id,
											categoryId: record.category_id,
                      name: record.name,
											isVariation: record.isVariation,
                      //addOns: record.addOns,
                      type: record.type,
											attributeGroupId: record.attributeGroupId
										};
		this.props.initialize(formData);
		this.setState({formType: 'edit'});
	}

	resetAttributeForm(e){
		e.preventDefault();
		this.props.initialize({categoryId: this.state.categoryId});
		this.setState({formType: 'add'});
	}


	confirmAttributeDelete(e, id){
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteAttribute(id)
        },
        {
          label: 'No',
          //onClick: () => alert('Click No')
        }
      ]
    });
  }

	deleteAttribute(id){
    this.setState({apiRequestPending: true});
    this.props.deleteAttribute({id}).then((response) => {
        this.setState({apiRequestPending: false});
        toast.success(response.value.data.message);
        this.fetchAttributes(this.state.categoryId);
      }).catch((error)=>{
        this.setState({apiRequestPending: false});
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
          toast.error("Network error!");
        } else {
          toast.error(error.message);
        }
      });
  }

	getVariationOption(option){

    for(var i = 0; i < this.state.variationOptions.length; i++){
      if(this.state.variationOptions[i].id == option){
        return this.state.variationOptions[i].name;
      }
    }
	}

  getAddOnOption(option){
    for(var i = 0; i < this.state.addOnOptions.length; i++){
      if(this.state.addOnOptions[i].id == option){
        return this.state.addOnOptions[i].name;
      }
    }
	}

  getAttributeTypeOption(option){
    for(var i = 0; i < this.state.attributeTypeOptions.length; i++){
      if(this.state.attributeTypeOptions[i].id == option){
        return this.state.attributeTypeOptions[i].name;
      }
    }
  }


	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		return(
			<>
				<div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
				<ol className="breadcrumb breadcrumb-v1">
					<li className="breadcrumb-item"><Link to="/admin/product-management/categories/list?page=1&parentId=0">Categories</Link></li>
					{
						this.state.parentCategoryData && this.state.parentCategoryData.categoryName &&
						<li className="breadcrumb-item"><Link to={"/admin/product-management/categories/list?page=1&parentId="+this.state.parentCategoryData.id}>{this.state.parentCategoryData.categoryName}</Link></li>
					}
					<li className="breadcrumb-item active">Manage Attributes ({
						(this.state.categoryData && this.state.categoryData.categoryName)?this.state.categoryData.categoryName:''})</li>
				</ol>
				<h5 className="h5 mb-3">Manage Attributes ({(this.state.categoryData && this.state.categoryData.categoryName)?this.state.categoryData.categoryName:''})</h5>
				<div className="card">
					<div className="card-body card-padding">
						<form onSubmit={ handleSubmit(this.submitForm.bind(this)) } >
							<div className="row">
								<div className="col-lg-3">
									<Field
										name = "name"
										component={renderField}
										type="text"
										label="Attribute Name"
									/>
								</div>
                <div className="col-lg-3">
                  <Field
                    label="Is variation?"
                    name='isVariation'
                    optionLabel='name'
                    optionValue='id'
                    options={this.state.variationOptions}
                    component={renderReactSelect}
                    placeholder="Select"
                    multi={false}
                   />
								</div>
                {/* <div className="col-lg-3">
                  <Field
                    label="Is addon?"
                    name='addOns'
                    optionLabel='name'
                    optionValue='id'
                    options={this.state.addOnOptions}
                    component={renderReactSelect}
                    placeholder="Select"
                    multi={false}
                   />
								</div> */}

								{/* <div className="col-lg-3">
                  <Field
                    label="Attribute Type"
                    name='type'
                    optionLabel='name'
                    optionValue='id'
                    options={this.state.attributeTypeOptions}
                    component={renderReactSelect}
                    placeholder="Select"
                    multi={false}
                   />
								</div> */}
								<div className="col-lg-3">
                  <Field
                    label="Attribute Group"
                    name='attributeGroupId'
                    optionLabel='name'
                    optionValue='id'
                    options={this.state.attributeGroups}
                    component={renderReactSelect}
                    placeholder="Select"
                    multi={false}
                   />
								</div>
							</div>

							<div class="form-btn-group mt-4">
								{
									this.state.formType == 'edit' &&
									<button className="btn btn-secondary hvr-shadow" onClick = {(e) => this.resetAttributeForm(e)}>Cancel</button>
								}
								<button type="submit" className={this.state.submittingForm?"btn btn-primary hvr-shadow btn-loader":"btn btn-primary hvr-shadow"} disabled = {(this.state.submittingForm || this.state.imageUploading)?true:false}>{this.state.formType == "edit"?"Update Attribute":"Add Attribute"}</button>
							</div>

						</form>
					</div>
					<div className="custom-table mt-0" style = {{borderRadius: '0px'}}>
              <div className="table-responsive">
              {
                this.state.fetchingRecords == true && <Loader />
              }
              <table className="table mb-0" >
								<thead>
									<tr>
										<th>ID</th>
										<th>Name</th>
										<th>Attribute Group</th>
										<th>Is Variation?</th>
										{/* <th>Is Addon?</th> */}
                    {/* <th>Type</th> */}
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.attributes && this.state.attributes && this.state.attributes.length > 0 &&
										this.state.attributes.map((obj, index) => {
											return(
												<tr key={obj.id}>
													<td>{obj.id}</td>
													<td>{obj.name}</td>
													<td>{obj.attributeGroupName}</td>
													<td>{this.getVariationOption(obj.isVariation)}</td>
                          {/* <td>{this.getAddOnOption(obj.addOns)}</td> */}
                          {/* <td>{this.getAttributeTypeOption(obj.type)}</td> */}
													<td>
														<span class="dropdown threeDots">
													 <a href="javascript://" class="dropdown-toggle" data-toggle="dropdown">
														<span></span> <span></span> <span></span>
													 </a>
													<ul className="dropdown-menu" role="menu">
														<li><button className="dropdown-item" onClick = {(e) => this.initializeEditForm(e, index)} title="Edit">Edit</button></li>
														<li><button className="dropdown-item" title="Delete" onClick = {(e) => this.confirmAttributeDelete(e, obj.id)}>Delete</button></li>
													</ul>
											</span>
													</td>
												</tr>
											)
										})

									}
								</tbody>
							</table>
						</div>
					</div>
				</div>

			</>
    );
	}
}

AttributesManage = reduxForm({
  form: 'AttributesManage',
	validate
})(AttributesManage);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAttributes: (data) => {
      return dispatch(fetchAttributes(data));
    },
    addAttribute: (data, apiPath, method) => {
      return dispatch(addAttribute(data, apiPath, method));
    },

		fetchAttributes: (data) => {
			return dispatch(fetchAttributes(data));
		},
		deleteAttribute: (data) => {
			return dispatch(deleteAttribute(data));
		},
		fetchCategory: (data) => {
      return dispatch(fetchCategory(data));
    },
		fetchAttributeGroups: (data) => {
			return dispatch(fetchAttributeGroups(data));
		}

  }
}


export default withRouter(connect(null, mapDispatchToProps)(AttributesManage));
