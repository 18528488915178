import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'


const RFReactSelect = ({components, isClearable, isDisabled, parentCallback, parentDivClass, innerParentDivClass, input , optionLabel, optionValue, options, multi, className, label, labelClass, placeholder, meta: {initial, touched, error, invalid, warning } }) => {
  if(parentCallback){
    const parentCallbackFunc = parentCallback;
  }else{
    const parentCallbackFunc = '';
  }
  if(optionLabel){
    var labelField = optionLabel;
  }else{
    var labelField = 'label';
  }
  if(optionValue){
    var valueField = optionValue;
  }else{
    var valueField = 'value';
  }
  /**
   * For single select, Redux Form keeps the value as a string, while React Select
   * wants the value in the form { value: "grape", label: "Grape" }
   *
   * * For multi select, Redux Form keeps the value as array of strings, while React Select
   * wants the array of values in the form [{ value: "grape", label: "Grape" }]
   */
   if(optionLabel){
     var labelField = optionLabel;
   }else{
     var labelField = 'label';
   }
   if(optionValue){
     var valueField = optionValue;
   }else{
     var valueField = 'value';
   }
  const transformValue = (value, options, multi, name) => {
    console.log('hhhhhh', value, options);
    if (multi && typeof value === 'string') return [];

    const filteredOptions = options.filter(option => {



      return multi
        ? value.indexOf(option[valueField]) !== -1
        : option[valueField] === value
    });

    return multi ? filteredOptions : filteredOptions[0]
  }
  const { name, value, onBlur, onChange, onFocus } = input;


  const transformedValue = (value === "") ? null : transformValue(value, options, multi, name);




  /**
   * onBlur from Redux Form Field has to be called explicity.
   */
  const multiChangeHandler = (func) => {
    return function handleMultiHandler(values) {
      func(values.map(value => value[valueField]));
    };
  }



  const singleChangeHandler = (func) => {

    return function handleSingleChange(value) {
      if(parentCallback){
        parentCallback(value);
      }
      func(value ? value[valueField] : '')
    };
  }

  return (

    <div className = { parentDivClass?parentDivClass:'form-group'}>
      {label && label != "" && <label className={labelClass?labelClass:""}>{label?label:''}</label>}


          <Select
            getOptionLabel={opt => opt[labelField]}
            getOptionValue={opt => opt[valueField]}
            name={name}
            value={transformedValue}
            isMulti={multi}
            options={options}
            onChange={multi
              ? multiChangeHandler(onChange)
              : singleChangeHandler(onChange)
            }
            isDisabled={isDisabled?isDisabled:false}
            isClearable={isClearable?isClearable:false}
            //onBlur={() => onBlur(value)}
            onBlur={event => event.preventDefault()}
            onFocus={onFocus}
            className={className}
            placeholder={placeholder?placeholder:'Search...'}
            components = {components?components:null}
          />

        {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="error">{warning}</span>))}

    </div>
  )
}

RFReactSelect.defaultProps = {
  multi: false,
  className: ""
}

RFReactSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    //value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  className: PropTypes.string
}

export default RFReactSelect
