import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, FormSection, FieldArray } from 'redux-form';
import renderField from '../../FormFields/renderField';
import renderCKEditor from '../../FormFields/renderCKEditor';
import {fetchCategoryTree, fetchAttributes} from '../../../actions/categories';
import {fetchCurrencies} from '../../../actions/currencies';
import queryString from 'query-string';
import {fetchProduct, addProduct, fetchProductGallery} from '../../../actions/products';
import {uploadImage} from '../../../actions/common';
import {getCategoryLevelDashes} from '../../../constants';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';
import {fetchAllBrands} from '../../../actions/brands';


import { toast } from 'react-toastify';



class ProductsView extends Component {
	constructor(props){
    super(props);
    var params = queryString.parse(props.location.search);
    this.state = {
      dataLoaded: false,
      productId: props.match.params.productId?props.match.params.productId:null,
      arrayLocalImages: [],
      arrayGalleryImages: [],
      productData: null
    }


  }


  componentDidMount(){
    this.fetchProduct(this.state.productId);
    this.fetchProductGallery(this.state.productId);
  }

  fetchProductGallery(productId){
		this.setState({fetchingRecords: true});
    this.props.fetchProductGallery({id: productId}).then((response) => {
      let gallery = (response.value.data.responseData.data && response.value.data.responseData.data.images)?response.value.data.responseData.data.images:[];

      this.setState({fetchingRecords: false, arrayGalleryImages: gallery});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}





  fetchProduct(productId){
		this.setState({fetchingRecords: true});
    this.props.fetchProduct({id: productId}).then((response) => {
      var basePrice = [];
      let productData = response.value.data.responseData.data;
      let formData = {};
      let arrayLocalImages = [];
      if(productData){

        if(productData.featuredImage && productData.featuredImage){
          arrayLocalImages = productData.featuredImage;
        }
      }

      this.setState({fetchingRecords: false, arrayLocalImages, productData});
      //this.props.initialize(categoryData);
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}



  componentWillReceiveProps(nextProps){
  }









  render() {
    const { handleSubmit, pristine, reset, submitting, change } = this.props;
    const {productData} = this.state;
    return (
      <>
			<div className="back-ui"><a href="javascript:;" className="back-btn" onClick = {(e) => window.history.back()}>Back</a></div>
      <ol className="breadcrumb breadcrumb-v1">
        <li className="breadcrumb-item"><Link to="/admin/product-management/products/list">Products</Link></li>
        <li className="breadcrumb-item active">View</li>
      </ol>
        <h5 className="h5 mb-4">View Product</h5>
        <section className="formBg" style={{maxWidth:"850px"}}>
          <div className="row">
            <div className=" col-lg-7">
              <div className="form-group">
                <label><strong>Name</strong></label>
                <span className="data-span">{productData && productData.name}</span>
              </div>
              <div className="form-group">
                <label><strong>Category</strong></label>
                <span className="data-span">{productData && productData.parentCategory}</span>
              </div>
							<div className="form-group">
                <label><strong>Sub-category</strong></label>
                <span className="data-span">{productData && productData.categoryName}</span>
              </div>
              <div className="form-group">
                <label><strong>Brand</strong></label>
                <span className="data-span">{productData && productData.brandName}</span>
              </div>

                {
                  productData && productData.basePrice && productData.basePrice.length > 0 &&
                  productData.basePrice.map((obj, index) => {
                    return(
                      <div className = "row">
                        <div className = "col-md-6">
                          <div className="form-group">
                            <label><strong>Price</strong></label>
                            <span className="data-span">{obj.currencyCode}{parseInt(obj.value).toFixed(2)}</span>
                          </div>
                        </div>

                      </div>
                    )
                  })


                }






            </div>
            <div className=" col-lg-5">


              {
                this.state.arrayLocalImages && this.state.arrayLocalImages.length > 0 &&
                this.state.arrayLocalImages.map((obj, index) => {
                  return(
                    <div className="product-img" key={index}>
                      <figure className="img"><img src={obj.filePath} alt=""/></figure>
  									</div>
                  )
                })
              }
            </div>

          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label><strong>Description</strong></label>
                <span className="data-span" dangerouslySetInnerHTML = {{__html: productData ? productData.descriptionHtml:''}} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label><strong>Attributes</strong></label>
                {
                  productData && productData.attributeSet && productData.attributeSet.length > 0 &&
                  productData.attributeSet.map((obj, index) => {
                    return(
                      <div className = "row">
                        <div className = "col-md-3">
                          <div className="form-group">

                            <span className="data-span">{obj.name}</span>
                          </div>
                        </div>
                        <div className = "col-md-9">
                          <div className="form-group">

                            <span className="data-span">{obj.value}</span>
                          </div>
                        </div>

                      </div>
                    )
                  })


                }
              </div>

            </div>
          </div>
          <div className="row">
            <div className=" col-lg-12">
							<ul className="image-gallery">
								{
	                this.state.arrayGalleryImages && this.state.arrayGalleryImages.length > 0 &&
	                this.state.arrayGalleryImages.map((obj, index) => {
	                  return(
	                    <li key={index}>
	                      <figure className="img"><img src={obj.filePath} alt=""/></figure>
	  									</li>
	                  )
	                })
	              }
							</ul>
						</div>
          </div>
          <div className="form-btn-group">
            <button type="button" className="btn btn-secondary hvr-shadow" onClick = {(e) => window.history.back()}>Back</button>
          </div>
      </section>
    </>
    )
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductGallery: (data) => {
      return dispatch(fetchProductGallery(data));
    },
    fetchProduct: (data) => {
      return dispatch(fetchProduct(data));
    },

  }
}

ProductsView = connect(null, mapDispatchToProps)(ProductsView);



export default withRouter(ProductsView);
