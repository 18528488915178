import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, SubmissionError, formValueSelector } from 'redux-form';
import renderField from '../../FormFields/renderField';
import VideoPlayer from 'react-video-js-player';
import Confirm from 'react-confirm-bootstrap';
import { fetchProductVideo, updateProductVideo, deleteProductVideo } from '../../../actions/products';
import { uploadImage } from '../../../actions/common';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import ImageUploader from 'react-images-upload';


import { toast } from 'react-toastify';


class ProductsManageVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            productId: props.match.params.productId ? props.match.params.productId : null,
            arrayLocalImages: [],
            docsToBeUploadedCount: 0,
            docsUploadedCount: 0,
            videosToBeUploadedCount: 0,
            videosUploadedCount: 0,
            videoPath: null,
            videoPoster: null
        }
        this.onDrop = this.onDrop.bind(this);
        this.onVideoDrop = this.onVideoDrop.bind(this);
        this.myRef = React.createRef();
        this.myVideoRef = React.createRef();
    }

    submitForm(values, dispatch, props) {

        values = Object.assign(values, { id: parseInt(this.state.productId), video: { imageId: this.state.imageId, videoId: this.state.videoId } });

        if (!this.state.imageId) {
            toast.error("Please upload poster image for video!");
            return;
        } else if (!this.state.videoId) {
            toast.error("Please upload .mp4 video");
            return;
        }



        return this.props.updateProductVideo(values).then((response) => {
            toast.success(response.value.data.message);
            //this.props.history.push('/admin/product-management/products/list');
            window.history.back();
        }).catch(function (error) {
            if (error.response) {
                toast.error(error.response.data.message);
                if (error.response.data.errors) {
                    throw new SubmissionError(error.response.data.errors);
                }
            } else if (error.request) {
                toast.error("Network error!");
            } else {
                toast.error(error.message);
            }
        });
    }

    componentDidMount() {
        this.fetchProductVideo(this.state.productId);
    }



    fetchProductVideo(productId) {
        this.setState({ fetchingRecords: true });
        this.props.fetchProductVideo({ id: productId }).then((response) => {
            let videoData = response.value.data.responseData.data;
            let dataObj = {
                hasVideo: videoData && videoData.video && videoData.video.video ? true : false,
                imageId: videoData && videoData.video && videoData.video.image ? videoData.video.image.id : null,
                videoPoster: videoData && videoData.video && videoData.video.image ? videoData.video.image.filePath : null,
                videoId: videoData && videoData.video && videoData.video.video ? videoData.video.video.id : null,
                videoPath: videoData && videoData.video && videoData.video.video ? videoData.video.video.filePath : null,
                arrayLocalImages: videoData && videoData.video && videoData.video.image ? [videoData.video.image] : [],
            };
            this.setState(dataObj);
        }).catch(function (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            } else if (error.request) {
                toast.error("Network error!");
            } else {
                toast.error(error.message);
            }
        });
    }



    componentWillReceiveProps(nextProps) {
    }

    onDrop(pictures) {

        var objThis = this;

        for (var i = 0; i < pictures.length; i++) {
            var form_data = new FormData();
            var file_data = pictures[i];
            if (file_data && file_data.size > 10485760) { //10485760
                alert("Max file size allowed is 10MB");
                return;
            }

            this.setState({ imageUploading: true });
            form_data.append("uploadfile", file_data);
            setTimeout(() => {
                this.setState({ docsToBeUploadedCount: this.state.docsToBeUploadedCount + 1 });
            }, 100);

            this.props.uploadImage(form_data, { upload_type: "product_gallery_image" }).then((response) => {
                this.setState({ docsUploadedCount: this.state.docsUploadedCount + 1 });
                var payload = response.value.data.responseData;
                var arrayLocalImages = [];
                if (payload && payload.id) {
                    arrayLocalImages.push(payload);
                    this.setState({ arrayLocalImages: arrayLocalImages, videoPoster: payload.filePath, imageId: payload.id });

                }
            }).catch((error) => {
                this.setState({ docsUploadedCount: this.state.docsUploadedCount + 1 });
                if (error.response) {
                    toast.error(error.response.data.message);
                } else if (error.request) {
                    toast.error("Network error!");
                } else {
                    toast.error(error.message);
                }
            });

        }
        this.myRef.current.state.pictures = [];
        this.myRef.current.state.files = [];
    }

    onVideoDrop(videos) {


        for (var i = 0; i < videos.length; i++) {
            var form_data = new FormData();
            var file_data = videos[i];
            if (file_data && file_data.size > 10485760) { //10485760
                alert("Max file size allowed is 10MB");
                return;
            }

            this.setState({ videoUploading: true });
            form_data.append("uploadfile", file_data);
            setTimeout(() => {
                this.setState({ videosToBeUploadedCount: this.state.videosToBeUploadedCount + 1 });
            }, 100);

            this.props.uploadImage(form_data, { upload_type: "product_video" }).then((response) => {
                this.setState({ videosUploadedCount: this.state.videosUploadedCount + 1 });
                var payload = response.value.data.responseData;
                var arrayLocalVideo = [];
                if (payload && payload.id) {
                    arrayLocalVideo.push(payload);
                    this.setState({ videoPath: null });
                    setTimeout(() => {
                        this.setState({ arrayLocalVideo: arrayLocalVideo, videoPath: payload.filePath, videoId: payload.id });
                    }, 100)


                }
            }).catch((error) => {
                this.setState({ videosUploadedCount: this.state.videosUploadedCount + 1 });
                if (error.response) {
                    toast.error(error.response.data.message);
                } else if (error.request) {
                    toast.error("Network error!");
                } else {
                    toast.error(error.message);
                }
            });

        }
        this.myVideoRef.current.state.videos = [];
        this.myVideoRef.current.state.files = [];
    }



    onPlayerReady(player) {
        //console.log("Player is ready: ", player);
        this.player = player;
    }

    onVideoPlay(duration) {
        //console.log("Video played at: ", duration);
    }

    onVideoPause(duration) {
        //console.log("Video paused at: ", duration);
    }

    onVideoTimeUpdate(duration) {
        //console.log("Time updated: ", duration);
    }

    onVideoSeeking(duration) {
        //console.log("Video seeking: ", duration);
    }

    onVideoSeeked(from, to) {
        //console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd() {
        //console.log("Video ended");
    }

    deleteProductVideo(productId) {
        this.setState({ fetchingRecords: true });
        this.props.deleteProductVideo({ id: productId }).then((response) => {
            toast.success(response.value.data.message);
            window.history.back();
        }).catch(function (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            } else if (error.request) {
                toast.error("Network error!");
            } else {
                toast.error(error.message);
            }
        });
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, change } = this.props;
        return (
            <>
                <div className="back-ui"><a href="javascript:;" className="back-btn" onClick={(e) => window.history.back()}>Back</a></div>
                <ol className="breadcrumb breadcrumb-v1">
                    <li className="breadcrumb-item"><Link to="/admin/product-management/products/list">Products</Link></li>
                    <li className="breadcrumb-item active">Manage Video</li>
                </ol>
                <h5 className="h5 mb-4">Manage Video</h5>
                <section className="formBg" style={{ maxWidth: "850px" }}>
                    <form onSubmit={handleSubmit(this.submitForm.bind(this))} >
                        <div className="row">

                            <div className=" col-lg-6">
                                <ImageUploader
                                    className={(this.state.docsUploadedCount != this.state.docsToBeUploadedCount) ? "loading" : ""}
                                    accept=".jpg, .jpeg, .png, .gif"
                                    singleImage={true}
                                    label=""
                                    withIcon={false}
                                    buttonText='Upload video poster image (800x800)'
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
                                    maxFileSize={10485760 * 100}
                                    ref={this.myRef}
                                />
                            </div>
                            <div className=" col-lg-6">
                                <ul className="image-gallery">
                                    {
                                        this.state.arrayLocalImages && this.state.arrayLocalImages.length > 0 &&
                                        this.state.arrayLocalImages.map((obj, index) => {
                                            return (
                                                <li key={index}>
                                                    <figure className="img"><img src={obj.filePath} alt="" /></figure>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                        </div>
                        <div className="row">

                            <div className=" col-lg-6">
                                <ImageUploader
                                    className={(this.state.videosUploadedCount != this.state.videosToBeUploadedCount) ? "loading" : ""}
                                    accept=".mp4"
                                    singleImage={true}
                                    label=""
                                    withIcon={false}
                                    buttonText='Upload video (.mp4 file)'
                                    onChange={this.onVideoDrop}
                                    imgExtension={['.mp4']}
                                    maxFileSize={10485760 * 100}
                                    ref={this.myVideoRef}
                                />
                            </div>
                            {
                                this.state.videoPath &&
                                <div className=" col-lg-6">
                                    <VideoPlayer
                                        controls={true}
                                        src={this.state.videoPath}
                                        poster={this.state.videoPoster}
                                        width="350"
                                        height="350"
                                        onReady={this.onPlayerReady.bind(this)}
                                        onPlay={this.onVideoPlay.bind(this)}
                                        onPause={this.onVideoPause.bind(this)}
                                        onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                                        onSeeking={this.onVideoSeeking.bind(this)}
                                        onSeeked={this.onVideoSeeked.bind(this)}
                                        onEnd={this.onVideoEnd.bind(this)}
                                    />
                                </div>
                            }

                        </div>
                        <div className="form-btn-group">
                            <button onClick={(e) => window.history.back()} type="button" className="btn btn-secondary hvr-shadow">Cancel</button>
                            {
                                this.state.hasVideo  &&
                                
                                <Confirm className="confirmPopup"
                                    onConfirm={() => this.deleteProductVideo(this.state.productId)}
                                    body="Are you sure you want to delete this?"
                                    confirmText="Confirm Delete"
                                    title="Delete!"><button type="button" className={`${"btn btn-secondary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Delete</button>
                                </Confirm>
                                
                            }
                            
                            <button type="submit" className={`${"btn btn-primary hvr-shadow"} ${submitting ? " btn-loader " : "  "}`} disabled={submitting ? true : false} >Save</button>&nbsp;
                        </div>
                    </form>
                </section>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProductVideo: (data, apiPath, method) => {
            return dispatch(updateProductVideo(data, apiPath, method));
        },
        fetchProductVideo: (data) => {
            return dispatch(fetchProductVideo(data));
        },
        uploadImage: (data) => {
            return dispatch(uploadImage(data));
        },
        deleteProductVideo: (data) => {
            return dispatch(deleteProductVideo(data));
        }

    }
}
ProductsManageVideo = reduxForm({
    form: 'ProductsManageVideo',
    //validate,
    //enableReinitialize: true
})(ProductsManageVideo);
const selector = formValueSelector('ProductsManageVideo');
const mapStatesToProps = (state, ownProps) => {
    const imageIds = selector(state, 'imageIds');
    return {
        imageIds: imageIds
    }
}

ProductsManageVideo = connect(mapStatesToProps, mapDispatchToProps)(ProductsManageVideo);



export default withRouter(ProductsManageVideo);
