import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import {fetchUsers, updateUserStatus, fetchRoles} from '../../../actions/users';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import $ from 'jquery';

class UsersList extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.page){
			queryParams.page = 1;
		}

		if(!queryParams.sortBy){
			queryParams.sortBy = 'id';
		}
		if(!queryParams.sortOrder){
			queryParams.sortOrder = 'desc';
		}

    this.state = {
			params: queryParams,
			pageUrl: '/admin/user-management/users/list',
			records: null,
			roles: [],
			filters: [
				'name',
				'email',
				'mobile',
				'role'
			]
		};
  }

	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	componentDidMount(){
		this.props.initialize(this.state.params);
		this.fetchUsers(this.state.params);
		this.fetchRoles();
		this.updateFilterForm(this.state.params);
	}

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}

	fetchRoles(){
		this.setState({fetchingRecords: true});
    this.props.fetchRoles().then((response) => {
      let roles = response.value.data.responseData.data;
      this.setState({fetchingRecords: false, roles: roles});
    }).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	fetchUsers(params){
		this.setState({fetchingRecords: true});
    this.props.fetchUsers(params).then((response) => {
      this.setState({fetchingRecords: false});
      var records = response.value.data.responseData;
      this.setState({records});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){

		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchUsers(params);
		}
	}

	updateUserStatus(e, userId, status){
		this.setState({fetchingRecords: true});
    this.props.updateUserStatus({userId, status}).then((response) => {
      this.setState({fetchingRecords: false});
      this.fetchUsers(this.state.params);
			toast.success(response.value.data.message);
		}).catch(function(error){
			this.setState({fetchingRecords: false});
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?page=1');
	}

	sortResults(e, sortBy, sortOrder){
		if(e){
			e.preventDefault();
		}
		let params = this.state.params;
		params = Object.assign(params, {sortBy: sortBy, sortOrder: sortOrder});
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records, params} = {...this.state}
    return (
			<>

        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/user-management/users/list?page=1">Users</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Users</h5>
          </div>
          <div className="col-md-8">
            <div className="jobs-act-btns">
              <a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>
							<Link to="/admin/user-management/users/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Add User</Link>
            </div>
          </div>
        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
                </div>
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="email"
										component={renderField}
										label="Email Contains"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="mobile"
										component={renderField}
										label="Mobile"
									/>
								</div>
								<div className=" col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Role"
		                name='role'
		                optionLabel='role'
		                optionValue='id'
		                options={this.state.roles}
		                component={renderReactSelect}
		                placeholder="Select a role"
		                multi={false}
		               />
		          	</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
				<div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th><a href = "javascript:;" className = {params.sortBy == 'id'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'id', params.sortOrder == 'desc'?'asc':'desc')}>ID</a></th>
									<th><a href = "javascript:;" className = {params.sortBy == 'name'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'name', params.sortOrder == 'desc'?'asc':'desc')}>Name</a></th>
									<th><a href = "javascript:;" className = {params.sortBy == 'email'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'email', params.sortOrder == 'desc'?'asc':'desc')}>Email</a></th>
									<th>Mobile</th>
									<th>Role</th>
									<th><a href = "javascript:;" className = {params.sortBy == 'status'?params.sortOrder:''} onClick = {(e) => this.sortResults(e, 'status', params.sortOrder == 'desc'?'asc':'desc')}>Status</a></th>
									<th>Actions</th>
								</tr>
							</thead>
						<tbody>
						{
							records && records.data && records.data.length > 0 &&
							records.data.map((obj, index) => {
								return(
									<tr key={obj.id}>
										<td>{obj.id}</td>
										<td>{obj.name}</td>
										<td>{obj.email}</td>
										<td>{obj.mobile}</td>
										<td>{obj.role?obj.role:''}</td>
										<td>{obj.status == 1?<span className="green_status">Active</span>:<span className="red_status">Inactive</span>}</td>
										<td>
											<span className="dropdown threeDots">
                        <a href="javascript://" className="dropdown-toggle" data-toggle="dropdown">
                         	<span></span> <span></span> <span></span>
                        </a>
                        <ul className="dropdown-menu" role="menu">
													<li><Link to = {"/admin/user-management/users/list/edit/"+obj.id} className="dropdown-item" title="Edit" >Edit</Link></li>
													<li><button className="dropdown-item" title="" onClick = {(e) => this.updateUserStatus(e, obj.id, obj.status == 1?2:1)} >{obj.status == 1?"Deactivate":"Activate"}</button></li>
												</ul>
                      </span>
										</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.data && records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			}
			</>
		);
	}
}

UsersList = reduxForm({
  form: 'UsersListFilter'
})(UsersList);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (data) => {
      return dispatch(fetchUsers(data));
    },
		updateUserStatus: (data) => {
			return dispatch(updateUserStatus(data));
		},
		fetchRoles: (data) => {
      return dispatch(fetchRoles(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(UsersList));
