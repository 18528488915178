import axios from 'axios';

import { API_ROOT } from '../constants';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const MERCHANT_FETCH_PRODUCTS = 'MERCHANT_FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const UPDATE_PRODUCT_IMAGE_GALLERY = 'UPDATE_PRODUCT_IMAGE_GALLERY';
export const FETCH_PRODUCT_IMAGE_GALLERY = 'FETCH_PRODUCT_IMAGE_GALLERY';
export const UPDATE_PRODUCT_PUBLISH_STATUS = 'UPDATE_PRODUCT_PUBLISH_STATUS';
export const FETCH_PRODUCTS_FOR_COMPATIBILITY = 'FETCH_PRODUCTS_FOR_COMPATIBILITY';
export const ADD_PRODUCT_COMPATIBILITY = 'ADD_PRODUCT_COMPATIBILITY';
export const FETCH_COMPATIBLE_PRODUCTS = 'FETCH_COMPATIBLE_PRODUCTS';
export const UPDATE_PRODUCT_FEATURED_STATUS = 'UPDATE_PRODUCT_FEATURED_STATUS';
export const FETCH_ATTRIBUTE_GROUPS = 'FETCH_ATTRIBUTE_GROUPS';
export const FETCH_ATTRIBUTE_GROUP = 'FETCH_ATTRIBUTE_GROUP';
export const DELETE_ATTRIBUTE_GROUP = 'DELETE_ATTRIBUTE_GROUP';
export const ADD_ATTRIBUTE_GROUP = 'ADD_ATTRIBUTE_GROUP';
export const FETCH_PC_COMPONENTS = 'FETCH_PC_COMPONENTS';
export const MERCHANT_UPDATE_INVENTORY_PRICE_ON_SERVER = 'MERCHANT_UPDATE_INVENTORY_PRICE_ON_SERVER';


export function fetchProducts(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProducts`
  });
  return {
    type: FETCH_PRODUCTS,
    payload: request
  };
}

export function fetchProduct(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProductWithId`
  });
  return {
    type: FETCH_PRODUCT,
    payload: request
  };
}

export function deleteProduct(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteProduct`
  });
  return {
    type: DELETE_PRODUCT,
    payload: request
  };
}

export function deleteProductVideo(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteProductVideo`
  });
  return {
    payload: request
  };
}

export function addProduct(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addProducts";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_PRODUCT,
    payload: request
  };
}

export function updateProductGallery(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/addProductImageGallery`
  });
  return {
    type: UPDATE_PRODUCT_IMAGE_GALLERY,
    payload: request
  };
}

export function updateProductVideo(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/addProductVideo`
  });
  return {
    
    payload: request
  };
}

export function fetchProductGallery(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProductImageGallery`
  });
  return {
    type: FETCH_PRODUCT_IMAGE_GALLERY,
    payload: request
  };
}

export function fetchProductVideo(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProductVideo`
  });
  return {
    payload: request
  };
}

export function updateProductPublishStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateProductPublishedStatus`
  });
  return {
    type: UPDATE_PRODUCT_PUBLISH_STATUS,
    payload: request
  };
}

export function fetchProductsForCompatibility(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getProductsForCompatibility`
  });
  return {
    type: FETCH_PRODUCTS_FOR_COMPATIBILITY,
    payload: request
  };
}

export function addProductCompatibility(formValues) {
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/admin/addProductCompatibility`
  });
  return {
    type: ADD_PRODUCT_COMPATIBILITY,
    payload: request
  };
}

export function fetchCompatibleProducts(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getCompatibleProducts`
  });
  return {
    type: FETCH_COMPATIBLE_PRODUCTS,
    payload: request
  };
}

export function updateProductFeaturedStatus(formValues) {
  const request = axios({
    method: 'PATCH',
    data: formValues,
    url: `${API_ROOT}/admin/updateProductFeaturedStatus`
  });
  return {
    type: UPDATE_PRODUCT_FEATURED_STATUS,
    payload: request
  };
}

export function fetchAttributeGroups(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getAttributeGroups`
  });
  return {
    type: FETCH_ATTRIBUTE_GROUPS,
    payload: request
  };
}

export function fetchAttributeGroup(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getAttributeGroupWithId`
  });
  return {
    type: FETCH_ATTRIBUTE_GROUP,
    payload: request
  };
}

export function deleteAttributeGroup(formValues) {
  const request = axios({
    method: 'DELETE',
    data: formValues,
    url: `${API_ROOT}/admin/deleteAttributeGroup`
  });
  return {
    type: DELETE_ATTRIBUTE_GROUP,
    payload: request
  };
}

export function addAttributeGroup(formValues, apiPath, method) {
  if(!apiPath){
    apiPath = "/admin/addAttributeGroup";
  }
  if(!method){
    method = "POST";
  }
  const request = axios({
    method: method,
    data: formValues,
    url: `${API_ROOT}${apiPath}`
  });
  return {
    type: ADD_ATTRIBUTE_GROUP,
    payload: request
  };
}

export function fetchPcComponents(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/admin/getPcComponents`
  });
  return {
    type: FETCH_PC_COMPONENTS,
    payload: request
  };
}

export function merchantFetchProducts(formValues) {
  const request = axios({
    method: 'GET',
    params: formValues,
    url: `${API_ROOT}/merchant/getProducts`
  });
  return {
    type: MERCHANT_FETCH_PRODUCTS,
    payload: request
  };
}

export function merchantUpdateInventoryPriceOnServer(formValues){
  const request = axios({
    method: 'POST',
    data: formValues,
    url: `${API_ROOT}/merchant/addInventory`
  });
  return {
    type: MERCHANT_UPDATE_INVENTORY_PRICE_ON_SERVER,
    payload: request
  };
}
