import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import { fetchCouponCodes } from '../../../actions/coupons';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import Confirm from 'react-confirm-bootstrap';
import renderReactSelect from '../../FormFields/renderReactSelectForForm';
import $ from 'jquery';
import moment from 'moment';
import { COUPON_FOR, COUPON_TYPES, WEEK_DAYS, COUPON_DISCOUNT_TYPES } from '../../../constants';

class CouponCodesList extends Component {
	constructor(props) {
		super(props);
		var queryParams = queryString.parse(props.location.search);
		if (!queryParams.page) {
			queryParams.page = 1;
		}
		var lang = localStorage.getItem('lang');
		if (!lang) {
			lang = 'en';
		}
		var couponRuleId = props.match.params.couponRuleId ? props.match.params.couponRuleId : null;
		this.state = {
			couponRuleId: couponRuleId,
			params: queryParams,
			pageUrl: '/admin/coupons/list/cuponCodes/' + couponRuleId,
			records: null,
			couponDiscountTypes: COUPON_DISCOUNT_TYPES[lang],
			couponFor: COUPON_FOR[lang],
			weekDays: WEEK_DAYS[lang],
			filters: [
				'name'
			]
		};
	}

	getCouponTypeOption(option) {
		for (var i = 0; i < this.state.couponTypes.length; i++) {
			if (this.state.couponTypes[i].id == option) {
				return this.state.couponTypes[i].name;
			}
		}
	}
	getCouponDiscountTypes(option) {
		for (var i = 0; i < this.state.couponDiscountTypes.length; i++) {
			if (this.state.couponDiscountTypes[i].id == option) {
				return this.state.couponDiscountTypes[i].name;
			}
		}
	}
	getCouponForOption(option) {
		for (var i = 0; i < this.state.couponFor.length; i++) {
			if (this.state.couponFor[i].id == option) {
				return this.state.couponFor[i].name;
			}
		}
	}
	getWeekDaysOption(option) {
		for (var i = 0; i < this.state.weekDays.length; i++) {
			if (this.state.weekDays[i].id == option) {
				return this.state.weekDays[i].name;
			}
		}
	}



	componentDidMount() {
		this.fetchCouponCodes(this.state.params);
		this.updateFilterForm(this.state.params);

	}

	updateFilterForm(params) {
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index) => {
			if (params[obj]) {
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}



	fetchCouponCodes(params) {
		this.setState({ fetchingRecords: true });
		params = Object.assign(params, { ruleId: this.state.couponRuleId });

		this.props.fetchCouponCodes(params).then((response) => {
			this.setState({ records: response.value.data.responseData, fetchingRecords: false });
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search != nextProps.location.search) {
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchCouponCodes(params);
		}
	}

	deleteRecord(recordid) {
		this.props.deleteCoupon({ id: recordid }).then((response) => {
			toast.success(response.value.data.message);
			this.fetchCoupons(this.state.params);
		}).catch(function (error) {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}


	publishRecord(id, status, index) {
		var records = this.state.records;
		this.props.updateCouponPublishStatus({ couponId: id, status }).then((response) => {
			records.data[index].isActive = status;
			this.setState({ records: records });
			toast.success(response.value.data.message);

		}).catch((error) => {
			if (error.response) {
				toast.error(error.response.data.message);
			} else if (error.request) {
				toast.error("Network error!");
			} else {
				toast.error(error.message);
			}

		});
	}



	submitFilterForm(values, dispatch, props) {
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({ params: params });
		this.props.history.push(this.props.location.pathname + "?" + queryString.stringify(params));
	}


	clearFilters(e) {
		this.props.initialize({});
		$(".fg-toggled").each(function () {
			$(this).removeClass('fg-toggled');
		})
		$("th.orderable").each(function () {
			$(this).removeClass('desc').removeClass('asc');
		});
		var objThis = this;
		if (e && e.target) {
			//this.props.change('usertype', '');
		}
		this.props.history.push(this.state.pageUrl + '?page=1');
	}



	render() {
		const { handleSubmit, pristine, submitting } = this.props;
		const { records } = { ...this.state }
		return (
			<>
				<ol className="breadcrumb breadcrumb-v1 marginB0">
					<li className="breadcrumb-item"><Link to="/admin/coupons/list?page=1">Promo Codes</Link></li>
					<li className="breadcrumb-item"><Link to={"/admin/coupons/list/couponCodes/" + this.state.couponRuleId + "?page=1"}>Coupons</Link></li>
					<li className="breadcrumb-item active">List</li>
				</ol>
				<div className="row align-items-end">
					<div className="col-md-4">
						<h5 className="mb-3">Coupons</h5>
					</div>
					<div className="col-md-8">
						<div className="jobs-act-btns">
							{/*<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
                <span className="icon-filter mr-1"></span>
                Filters
              </a>*/}
							{/* <Link to="/admin/coupons/list/create" className="btn-add ml-1  hvr-shadow"><span className="icon-ic_plus"></span>Create Coupon</Link> */}
						</div>
					</div>
				</div>
				{/*<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="name"
										component={renderField}
										label="Name Contains"
									/>
                </div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Category"
		                name='categoryId'
		                optionLabel='categoryName'
		                optionValue='id'
		                options={this.state.categoryTree}
		                component={renderReactSelect}
		                placeholder="Select"
		                multi={false}
										isClearable={true}

		              />
								</div>
								<div className="col-xl-2 col-lg-6 cust-col">
									<Field
		                label="Brand"
		                name='brandId'
		                optionLabel='name'
		                optionValue='id'
		                options={this.state.brands}
		                component={renderReactSelect}
		                placeholder="Select"
		                multi={false}
										isClearable={true}
		              />
								</div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>*/}
				<div className="custom-table mt-0">
					<div className="table-responsive">
						{
							this.state.fetchingRecords == true && <Loader />
						}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
									<th>Coupon Code</th>
									<th>Discount</th>
									<th>Discount Type</th>
									<th>Max. Discount</th>
									<th>Min. Order</th>
									<th>Uses per coupon</th>
									<th>Uses per user</th>
									<th>Life</th>
									<th>Used</th>
									{/* <th>Total Discount</th> */}

								</tr>
							</thead>
							<tbody>
								{
									records && records.data && records.data.length > 0 &&
									records.data.map((obj, index) => {
										return (
											<tr key={obj._id}>
												<td>{obj.id}</td>
												<td>{obj.couponCode}</td>
												<td>{obj.discount}</td>
												<td className="nowrap">{this.getCouponDiscountTypes(obj.discountType)} discount on {this.getCouponForOption(obj.couponFor)}</td>
												<td>{obj.couponMaxDiscount}</td>
												<td>{obj.minimumSubTotal}</td>
												<td>{obj.couponUsesPerCoupon}</td>
												<td>{obj.couponUsesPerUser}</td>
												<td className="nowrap">{
													obj.couponApplicableDays == 2
														?
														<>
															Applicable on every<br />
															{
																obj.dayOfWeek.map((obj1, index1) => {
																	return (
																		<span key={index1}> {index1 == 0 ? "" : ", "}<strong>{this.getWeekDaysOption(obj1)}</strong> </span>
																	)
																})
															}
															<br />between specified Start and End dates<br />and Start and End time
														</>
														:
														<>Any Time between specified <br />Start and End dates and Start and End time</>
												}</td>
												<td>{obj.used}</td>

											</tr>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
				{
					records && records.data && this.state.records.data.length > 0 &&
					<div className="custom-table-pagination">
						<RenderPagination
							currentPage={records.page}
							totalRecords={records.totalRecords}
							pageSize={records.perPage}
							params={this.state.params}
						/>
					</div>
				}
			</>

		);
	}
}

CouponCodesList = reduxForm({
	form: 'CouponCodesListFilter'
})(CouponCodesList);

const mapDispatchToProps = (dispatch) => {
	return {
		fetchCouponCodes: (data) => {
			return dispatch(fetchCouponCodes(data));
		}
	}
}


export default withRouter(connect(null, mapDispatchToProps)(CouponCodesList));
