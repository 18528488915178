import React, { Component } from 'react';
import { Redirect, Link, Route, withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError, reset } from 'redux-form';
import queryString from 'query-string';
import renderField from '../../FormFields/renderField';
import RenderPagination from '../../FormFields/renderPagination';
import renderSelect from '../../FormFields/renderSelect';
import {fetchTransactions} from '../../../actions/orders';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import Loader from '../../../components/Loader';
import moment from 'moment';
import {currencyFormat} from '../../../constants';

import $ from 'jquery';

class TransactionsList extends Component {
	constructor(props){
    super(props);
		var queryParams = queryString.parse(props.location.search);
		if(!queryParams.page){
			queryParams.page = 1;
		}
		let userId = 0;
		if(queryParams.userId){
			userId = parseInt(queryParams.userId);
		}
		this.state = {
			params: queryParams,
			pageUrl: '/admin/transactions/list',
			records: null,
			brands: [],
			categoryTree: [],
			userId: userId,
			filters: [
				'orderId',
				'userId'
			]
		};
  }



	componentDidMount(){
    this.fetchTransactions(this.state.params);
  }

	updateFilterForm(params){
		//initialize form
		var formValues = {};
		this.state.filters.forEach((obj, index)=> {
			if(params[obj]){
				formValues[obj] = params[obj];
			}
		});

		this.props.initialize(formValues);
	}


	fetchTransactions(params){
		this.setState({fetchingRecords: true});
    this.props.fetchTransactions(params).then((response) => {
			this.setState({records: response.value.data.responseData, fetchingRecords: false});
		}).catch(function(error){
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network error!");
      } else {
        toast.error(error.message);
      }
		});
	}

	componentWillReceiveProps(nextProps){
		if(this.props.location.search != nextProps.location.search){
			this.props.history.push(nextProps.location.search);
			var params = queryString.parse(nextProps.location.search);
			this.updateFilterForm(params);
			this.fetchTransactions(params);
		}
	}






	submitFilterForm(values, dispatch, props){
		var params = values;
		//params = Object.assign(params, values);
		params.page = 1;
		this.setState({params: params});
		this.props.history.push(this.props.location.pathname+"?"+queryString.stringify(params));
	}



	clearFilters(e){
    this.props.initialize({});
    $(".fg-toggled").each(function(){
      $(this).removeClass('fg-toggled');
    })
    $("th.orderable").each(function(){
      $(this).removeClass('desc').removeClass('asc');
		});
    var objThis = this;
    if(e && e.target){
      //this.props.change('usertype', '');
		}
    this.props.history.push(this.state.pageUrl+'?page=1');
	}

	render() {
		const {handleSubmit, pristine, submitting} = this.props;
		const {records} = {...this.state}
    return (
		  <>
        <ol className="breadcrumb breadcrumb-v1 marginB0">
        	<li className="breadcrumb-item"><Link to="/admin/orders/list?page=1">Transactions</Link></li>
          <li className="breadcrumb-item active">List</li>
        </ol>
				<div className="row align-items-end">
          <div className="col-md-4">
            <h5 className="mb-3">Transactions</h5>
          </div>
					{
						this.state.userId <= 0 &&
						<div className="col-md-8">
	            <div className="jobs-act-btns">
								<a className="btn-filter mr-1 collapsed  hvr-shadow" href="javascript://" data-toggle="collapse" data-target="#filter">
	                <span className="icon-filter mr-1"></span>
	                Filters
	              </a>
	            </div>
	          </div>
					}

        </div>
				<div className="collapse" id="filter" >
          <div className="filter-dropdown">
            <form onSubmit={handleSubmit(this.submitFilterForm.bind(this))}>
              <div className="row">
                <div className="col-xl-2 col-lg-6 cust-col">
									<Field
										name="orderId"
										component={renderField}
										label="Transaction Id"
									/>
                </div>
							</div>
              <div className="form-btn-group mt-3 text-right">
                <button type="button" onClick = {(e) => this.clearFilters(e)}  className="btn btn-clear hvr-shadow mr-1">Clear</button>
                <button type = "submit" className="btn btn-primary hvr-shadow ml-1">Submit</button>
							</div>
						</form>
          </div>
        </div>
        <div className="custom-table mt-0">
					<div className="table-responsive">
					{
						this.state.fetchingRecords == true && <Loader />
					}
						<table className="table mb-0" >
							<thead>
								<tr>
									<th>ID</th>
                  <th>Txn</th>
                  <th>Total Amt.</th>
									<th>Order ID</th>
                  <th>Wallet payment</th>
									<th>Gateway payment</th>
									<th>Payment for</th>
									<th>Payment method</th>
									<th>Payment status</th>
									<th>Date</th>
									<th>Customer</th>
									<th>Phone</th>

								</tr>
							</thead>
						<tbody>
						{
							records && records.data && records.data.length > 0 &&
							records.data.map((obj, index) => {
                return(
									<tr key={obj._id}>
										<td>{obj.id}</td>
										<td>{obj.transactionId}</td>
										<td className="nowrap">{/*obj.orderData.currencyCode*/} <strong>{currencyFormat(parseFloat(obj.totalAmount).toFixed(2))}</strong></td>
										<td><a href = {"/admin/orders/list/detail/"+obj.order_id} target = "_blank">{obj.order_id}</a></td>
										<td className="nowrap">{/*obj.orderData.currencyCode*/} <strong>{currencyFormat(parseFloat(obj.walletPayment).toFixed(2))}</strong></td>
										<td className="nowrap">{/*obj.orderData.currencyCode*/} <strong>{currencyFormat(parseFloat(obj.gatewayPayment).toFixed(2))}</strong></td>
										<td className="nowrap">{obj.paymentFor == 1?"Placing Order":obj.paymentFor == 2?"Refund Generated":"Order Cancellation"}</td>
										<td className="nowrap">{obj.paymentMethod == 1?"Payment Gateway":obj.paymentFor == 2?"Wallet":obj.paymentFor == 3?"Gateway & Wallet":obj.paymentFor == 4?"Cash on delivery":"COD & Wallet"}</td>
										<td className="nowrap">{obj.paymentStatus == 1?<span className="green_status">Approved</span>:obj.paymentFor == 2?<span className="red_status">Declined</span>:<span className="orange_status">Pending</span>}</td>
										<td className="nowrap">{moment(obj.createdAt).format('DD MMM, Y')}</td>
										<td className="nowrap">{obj.name}</td>
										<td className="nowrap">+{obj.countryCode} {obj.mobile}</td>
									</tr>
								)
							})
						}
						</tbody>
					</table>
				</div>
			</div>
			{
				records && records.data && this.state.records.data.length > 0 &&
				<div className="custom-table-pagination">
					<RenderPagination
						currentPage={records.page}
						totalRecords={records.totalRecords}
						pageSize={records.perPage}
						params={this.state.params}
					/>
				</div>
			}
			</>

    );
	}
}

TransactionsList = reduxForm({
  form: 'TransactionsListFilter'
})(TransactionsList);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactions: (data) => {
      return dispatch(fetchTransactions(data));
    }
  }
}


export default withRouter(connect(null, mapDispatchToProps)(TransactionsList));
